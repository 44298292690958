import { IEntity } from '@IntrinsicSoftware/sim-ecs';
import { ToggleCommand, useActive } from '../active/main';
import { CompleteCommand } from '../completable/Completable';
import { BaseSirenComposable } from '../types';

export default function useBaseSiren(entity: IEntity): BaseSirenComposable {
  return {
    active: useActive(entity),
    id: entity.id,
    toggle: () => {
      const commands = entity.getComponent(ToggleCommand);
      if (commands) {
        commands.entries.push(new ToggleCommand());
      }
    },
    complete: () => {
      const commands = entity.getComponent(CompleteCommand);
      if (commands) {
        commands.entries.push(new CompleteCommand());
      }
    }
  };
}
