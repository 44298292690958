import {
  createSystem,
  IEntity,
  queryComponents,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { ShallowRef, shallowRef } from 'vue';
import { ComponentException } from '../types';

export class Description {
  constructor(
    public title = '',
    public description = '',
    public subtitle = ''
  ) {}
}

export interface DescriptionViewModel {
  description: string;
  title: string;
  subtitle: string;
}

export class DescriptionSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<DescriptionViewModel> = shallowRef<DescriptionViewModel>(
      {
        description: '',
        title: '',
        subtitle: ''
      }
    )
  ) {}
}

export const useDescription = (entity: IEntity) => {
  const description = entity.getComponent(Description);
  if (!description) {
    throw new ComponentException(entity.id, 'Description component not found');
  }
  const ref = shallowRef<DescriptionViewModel>({
    title: description.title,
    description: description.description,
    subtitle: description.subtitle
  });
  return ref;
};

export const DescriptionViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      description: Write(Description),
      viewModel: Write(DescriptionSynchronizationComponent)
    })
  })
    .withName('DescriptionViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { description, viewModel } of query.iter()) {
        viewModel.shallowReference.value = {
          description: description.description,
          title: description.title,
          subtitle: description.subtitle
        };
      }
    })
    .build();
};
