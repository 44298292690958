import {
  Node,
  Branch,
  SlipperyStairNode,
  FALLBACK_DIALOG,
  RadioBranch
} from '@/ecs/dialog/component';
import { RadioBroadcastPayload } from '../../../../models/BuildableDialogItem';

export function build() {
  const HIDDEN = false;
  const goalMessage = {
    dialog: '',
    edges: [],
    goalNode: true
  };

  const goalMessageNode = new Node(
    goalMessage.dialog,
    goalMessage.edges,
    goalMessage.goalNode,
    'GiantVoice'
  );

  const correctGiantVoiceBroadcast: RadioBroadcastPayload = {
    message:
      'LOCKDOWN   LOCKDOWN   LOCKDOWN   BASE   is   in   LOCKDOWN   Please   remain   in   LOCKDOWN   MODE   until   given   further   guidance',
    repeats: 3,
    frequency: '5 Minutes'
  };

  const firstOptions: Branch[] = [
    new Branch(FALLBACK_DIALOG, 0, goalMessageNode, false, HIDDEN),
    new RadioBranch(
      JSON.stringify(correctGiantVoiceBroadcast),
      100,
      goalMessageNode,
      false,
      HIDDEN
    ),
    new Branch(' ', 0, goalMessageNode, false),
    new Branch('ALL', 0, goalMessageNode, false),
    new Branch('has', 0, goalMessageNode, false),
    new Branch('until', 0, goalMessageNode, false),
    new Branch('WARNING', 0, goalMessageNode, false),
    new Branch('been', 0, goalMessageNode, false),
    new Branch('LOCKDOWN', 0, goalMessageNode, false),
    new Branch('BASE', 0, goalMessageNode, false),
    new Branch('CLEAR', 0, goalMessageNode, false),
    new Branch('is', 0, goalMessageNode, false),
    new Branch('Please', 0, goalMessageNode, false),
    new Branch('guidance', 0, goalMessageNode, false),
    new Branch('in', 0, goalMessageNode, false),
    new Branch('released', 0, goalMessageNode, false),
    new Branch('remain', 0, goalMessageNode, false),
    new Branch('given', 0, goalMessageNode, false),
    new Branch('MODE', 0, goalMessageNode, false),
    new Branch('further', 0, goalMessageNode, false)
  ];

  const root = {
    dialog: '',
    edges: firstOptions,
    goalNode: false,
    cost: 0
  };
  return new SlipperyStairNode(
    root.dialog,
    root.edges,
    root.goalNode,
    'GiantVoice',
    root.cost
  );
}
