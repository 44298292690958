import firebase from 'firebase';
import { UserRole } from './UserRoles';
import { plainToClass } from 'class-transformer';
import { Score } from '../services/FirestoreService';

interface IUser {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  organizationId: string;
  classId: string;
  courseId: string;
}

class User {
  public uid: string;
  public email!: string;
  public firstName!: string;
  public lastName!: string;
  public organizationId!: string;
  public courseId!: string;
  public classId!: string;
  public role!: UserRole;
  public score: Score;

  constructor() {
    this.uid = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.organizationId = '';
    this.courseId = '';
    this.classId = '';
    this.role = UserRole.GUEST;
    this.score = Score.fromFirestore({});
  }

  static fromAuthClient(user: firebase.User): User {
    return plainToClass(User, {
      uid: user.uid,
      email: user.email || '',
      organizationId: '',
      classId: '',
      courseId: '',
      firstName: '',
      lastName: '',
      role: UserRole.GUEST
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(user: any): User {
    return plainToClass(User, user, { exposeDefaultValues: true });
  }
}

export class UnregisteredUser {
  public uid!: string;
  public email!: string;
  public firstName!: string;
  public lastName!: string;
  public organizationId!: string;
  public courseId!: string;
  public classId!: string;
  public role!: UserRole;

  static fromAuthClient(user: firebase.User): UnregisteredUser {
    return plainToClass(UnregisteredUser, {
      uid: user.uid,
      email: user.email || '',
      organizationId: '',
      classId: '',
      courseId: '',
      firstName: '',
      lastName: '',
      role: UserRole.GUEST
    });
  }
  static fromJson(user: unknown): UnregisteredUser | User {
    return plainToClass(UnregisteredUser, user, { exposeDefaultValues: true });
  }

  constructor(uid: string, email: string) {
    this.uid = uid;
    this.email = email;
  }
}

export { User, IUser };
