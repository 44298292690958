import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goalMessage = {
    dialog: 'Goodbye',
    edges: [],
    goalNode: true
  };

  const goalMessageNode = new Node(
    goalMessage.dialog,
    goalMessage.edges,
    goalMessage.goalNode
  );

  const fourthOptions: Branch[] = [
    new Branch('Yes, Sir, Command Post Out', 100, goalMessageNode, false),
    new Branch('Copy Sir, Command Post Out', 75, goalMessageNode, false),
    new Branch('Will Do, Out', 50, goalMessageNode, false),
    new Branch('Gotcha', 0, goalMessageNode, false)
  ];

  const thirdMessage = {
    dialog: 'Understood, please let me know if that changes',
    edges: fourthOptions,
    goalNode: false
  };

  const thirdMessageNode = new Node(
    thirdMessage.dialog,
    thirdMessage.edges,
    thirdMessage.goalNode
  );

  const thirdOptions: Branch[] = [
    new Branch(
      'No Sir, there is no information on casualties at this time.',
      100,
      thirdMessageNode,
      false
    ),
    new Branch('Nothing reported yet, Sir.', 75, thirdMessageNode, false),
    new Branch('Nada at the momenet', 50, thirdMessageNode, false),
    new Branch('I don’t know, Sir', 0, thirdMessageNode, false)
  ];

  const secondMessage = {
    dialog: 'Are there any casualties reported?',
    edges: thirdOptions,
    goalNode: false
  };

  const secondMessageNode = new Node(
    secondMessage.dialog,
    secondMessage.edges,
    secondMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch(
      'Sir, two masked gunmen have barricaded themselves in Green Beans Coffee Shop, and are holding the three employees hostage. All other personnel have been evacuated. No other information is available at this time.',
      100,
      secondMessageNode,
      false
    ),
    new Branch(
      'Report of Active Shooter in the BX; there are three hostages.',
      75,
      secondMessageNode,
      false
    ),
    new Branch(
      'Masked gunmen have taken over the Coffee Shop, with innocents trapped inside!',
      50,
      secondMessageNode,
      false
    ),
    new Branch('Active Shooter at the BX!', 0, secondMessageNode, false)
  ];

  const firstMessage = {
    dialog: 'Ok, what’s the situation',
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch(
      'Sir, this is Command Post with an Active Shooter Situation',
      100,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, Active Shooter Situation developing at the BX',
      75,
      firstMessageNode,
      false
    ),
    new Branch(
      'Hostage taken at the BX, Active Shooters!',
      50,
      firstMessageNode,
      false
    ),
    new Branch(
      'Bad guys took over the Coffee Shop, Sir',
      0,
      firstMessageNode,
      false
    )
  ];

  const root = {
    dialog: 'This is Colonel Lombardi',
    edges: firstOptions,
    goalNode: false
  };
  return new Node(root.dialog, root.edges, root.goalNode);
}
