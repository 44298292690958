enum UserRoleDisplay {
  GUEST = 'Guest',
  ADMIN = 'Admin',
  INSTRUCTOR = 'Instructor',
  STUDENT = 'Student'
}

enum UserRole {
  GUEST = 'guest',
  ADMIN = 'admin',
  STUDENT = 'student',
  INSTRUCTOR = 'instructor'
}

export { UserRole, UserRoleDisplay };
