import { ISyncPointPrefab, ISystem } from '@IntrinsicSoftware/sim-ecs';
import {
  ActiveToggleCommandSystem,
  ActiveViewModelSystem,
  ClearActiveToggleSystem
} from '../active/main';

import {
  OutboxComparisonSystem,
  OutboxViewModelSystem
} from '../outbox/OutboxComparisonSystem';
import { ActivePhoneCallViewModelSystem } from '../phone/ActivePhoneCall';
import { CollectCompletablePointSystem } from '../point/main';
import {
  FormNPCPhoneCallResponse,
  ActivePhoneCallInMessageSystem
} from '../systems/ActivePhoneCallSystem';
import { PointTimerExpiredSystem } from '../systems/PointTimerExpired';
import { TimerSystem, TimerViewModelSystem } from '../timer/main';
import {
  CompletableViewModelSystem,
  ChecklistViewModelSystem,
  CompletableSystem,
  AutoStartTimedCompletable,
  AutoStopTimedCompletable,
  SetCompletableActiveStateSystem,
  CompleteEvaluationSystem,
  CompleteCommandSystem,
  ClearCompleteCommandSystem
} from '../completable/Completable';
import {
  ConversationViewModelSystem,
  SubjectMapSystem,
  DialogMapSystem,
  ProcessOutboxSystem
} from '../conversation/Conversation';
import { ConversationSingleton } from '../conversation/ConversationSingleton';
import { DescriptionViewModelSystem } from '../description/main';
import { LinearAiDialogSystem } from '../dialog/system';
import { InboxViewModelSystem } from '../inbox/system';
import { TapperCompletionSystem } from '../brains/TapperBrain';
import { BrainViewModelSystem } from '../brains/Brain';

export default async function build(ticker: ISystem<{ interval: number }>) {
  const conversationSingleton = new ConversationSingleton();
  const viewModelSystems = [
    CompletableViewModelSystem(),
    ChecklistViewModelSystem(),
    TimerViewModelSystem(),
    ActivePhoneCallViewModelSystem(),
    DescriptionViewModelSystem(),
    ActiveViewModelSystem(),
    InboxViewModelSystem(),
    OutboxViewModelSystem(),
    ConversationViewModelSystem(),
    BrainViewModelSystem()
  ];

  const executionSchedule: ISyncPointPrefab = {
    stages: [
      [
        SubjectMapSystem(conversationSingleton),
        DialogMapSystem(conversationSingleton),
        LinearAiDialogSystem(conversationSingleton),
        ProcessOutboxSystem(conversationSingleton),
        TapperCompletionSystem(conversationSingleton),
        await FormNPCPhoneCallResponse(),
        await ActivePhoneCallInMessageSystem(),
        await OutboxComparisonSystem()
      ],
      [TimerSystem(), PointTimerExpiredSystem()],
      [
        CollectCompletablePointSystem(),
        CompletableSystem(),
        AutoStartTimedCompletable(),
        AutoStopTimedCompletable(),
        SetCompletableActiveStateSystem(),
        CompleteEvaluationSystem()
      ],
      [CompleteCommandSystem(), ActiveToggleCommandSystem()],
      [ClearCompleteCommandSystem(), ClearActiveToggleSystem()],
      [...viewModelSystems],
      [ticker]
    ]
  };

  return executionSchedule;
}
