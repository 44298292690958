export interface SignUpFormFields {
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface LoginFormFields {
  email: string;
  password: string;
}

export const PASSWORD_DOES_NOT_MATCH =
  'Password and password confirmation must match';
