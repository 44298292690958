import { Active, ToggleCommand } from '../active/main';
import { Brain } from '../brains/Brain';
import { Category } from '../category/component';
import { CompleteCommand } from '../completable/Completable';
import { Contact } from '../contact/Contact';
import { Conversation } from '../conversation/Conversation';
import { Description } from '../description/main';
import { DialogFlow, MessageNode } from '../dialogFlow/DialogFlow';
import { Disable } from '../disable/component';
import { Inbox, InboxSynchronizationComponent } from '../inbox/component';
import { Outbox, OutboxSynchronizationComponent } from '../outbox/Outbox';
import {
  ActivePhoneCall,
  ProgressDialogueCommand
} from '../phone/ActivePhoneCall';
import { Worth } from '../point/main';
import { Series } from '../series/main';
import Timer from '../timer/main';

export default [
  Description,
  Worth,
  ActivePhoneCall,
  DialogFlow,
  MessageNode,
  Timer,
  Active,
  Disable,
  CompleteCommand,
  ToggleCommand,
  Series,
  Category,
  ProgressDialogueCommand,
  InboxSynchronizationComponent,
  OutboxSynchronizationComponent,
  Outbox,
  Inbox,
  Contact,
  Conversation,
  Brain
];
