import {
  createSystem,
  queryComponents,
  ReadEntity,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { ProgressDialogueCommand } from '../phone/ActivePhoneCall';
import { DialogFlow } from '../dialogFlow/DialogFlow';
import { CompleteCommand } from '../completable/Completable';

export const FormNPCPhoneCallResponse = async () => {
  const { ActivePhoneCall } = await import('../phone/ActivePhoneCall');
  return createSystem({
    query: queryComponents({
      activePhoneCall: Write(ActivePhoneCall),
      dialogFlow: Write(DialogFlow),

      entity: ReadEntity(),
      completeCommand: Write(CompleteCommand)
    })
  })
    .withName('FormPhoneCallResponse')
    .withRunFunction(({ query }) => {
      for (const {
        completeCommand,
        activePhoneCall,
        dialogFlow,
        entity
      } of query.iter()) {
        const nextMessage = activePhoneCall.received.nextMessage;
        const hasOptions = activePhoneCall.sent.options.size > 0;
        if (nextMessage) {
          const isCorrect =
            dialogFlow.dialogTree.correctOptionId ===
            activePhoneCall.received.id;

          if (isCorrect) {
            activePhoneCall.sent = nextMessage;
            dialogFlow.dialogTree = activePhoneCall.sent;
          }

          activePhoneCall.sent = nextMessage;
          activePhoneCall.received = {
            id: '',
            text: '',
            nextMessage: undefined
          };
        } else {
          if (!hasOptions) {
            completeCommand.entries.push(new CompleteCommand());
            entity.removeComponent(ActivePhoneCall);
          }
        }
      }
    })
    .build();
};

export const ActivePhoneCallInMessageSystem = async () => {
  const { ActivePhoneCall } = await import('../phone/ActivePhoneCall');
  return createSystem({
    activePhoneCallQuery: queryComponents({
      activePhoneCall: Write(ActivePhoneCall),
      progressDialogue: Write(ProgressDialogueCommand)
    })
  })
    .withRunFunction(({ activePhoneCallQuery }) => {
      for (const {
        activePhoneCall,
        progressDialogue
      } of activePhoneCallQuery.iter()) {
        progressDialogue.entries.forEach((entry) => {
          const option = activePhoneCall.sent.options.get(entry.optionId);
          if (option) {
            activePhoneCall.history.push({
              speaker: 'PLAYER',
              dialog: option.text,
              options: new Map(),
              correctOptionId: ''
            });
            if (option.nextMessage) {
              activePhoneCall.received = option;
              activePhoneCall.sent = option.nextMessage;
              activePhoneCall.history.push(activePhoneCall.sent);
            } else {
              activePhoneCall.sent.options = new Map();
            }
          }
        });
      }
    })
    .build();
};

export const ClearProgressDialogueCommandSystem = () => {
  return createSystem({
    query: queryComponents({
      progressDialogue: Write(ProgressDialogueCommand)
    })
  })
    .withName('ClearProgressDialogueCommandSystem')
    .withRunFunction(({ query }) => {
      for (const { progressDialogue } of query.iter()) {
        progressDialogue.entries = [];
      }
    })
    .build();
};
