import { plainToClass } from 'class-transformer';
import 'firebase/firestore';
import { TimestampTransform } from './decorator/TimestampTransform';
import { Qualifier } from './Qualifier';

export default class Message {
  author: string;
  authorId: string;
  @TimestampTransform
  issueDate: Date;
  message: string;
  classId: string;
  qualifier: Qualifier;

  constructor() {
    this.author = '';
    this.authorId = '';
    this.message = '';
    this.classId = '';
    this.qualifier = Qualifier.NONE;
    this.issueDate = new Date();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(payload: any): Message {
    return plainToClass(Message, payload, {
      exposeDefaultValues: true
    });
  }
}
