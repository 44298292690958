import {
  Node,
  Branch,
  SlipperyStairNode,
  FALLBACK_DIALOG,
  RadioBranch
} from '@/ecs/dialog/component';
import { RadioBroadcastPayload } from '../../../../models/BuildableDialogItem';

export function build() {
  const HIDDEN = false;
  const goalMessage = {
    dialog: '',
    edges: [],
    goalNode: true
  };

  const goalMessageNode = new Node(
    goalMessage.dialog,
    goalMessage.edges,
    goalMessage.goalNode,
    'Radio'
  );

  const correctRadioBroadcast: RadioBroadcastPayload = {
    message:
      'All aircraft in the local area, this is Raymond 30 with an active shooter in progress',
    repeats: 2,
    frequency: 'Flightline'
  };

  const firstOptions: Branch[] = [
    new Branch(FALLBACK_DIALOG, 0, goalMessageNode, false, HIDDEN),
    new RadioBranch(
      JSON.stringify(correctRadioBroadcast),
      100,
      goalMessageNode,
      false,
      HIDDEN
    ),
    new Branch('All aircraft', 0, goalMessageNode, false),
    new Branch('in the local area,', 0, goalMessageNode, false),
    new Branch('this is Raymond 30', 0, goalMessageNode, false),
    new Branch('with an', 0, goalMessageNode, false),
    new Branch('active shooter', 0, goalMessageNode, false),
    new Branch('in progress', 0, goalMessageNode, false)
  ];

  const root = {
    dialog: '',
    edges: firstOptions,
    goalNode: false
  };
  return new SlipperyStairNode(root.dialog, root.edges, root.goalNode, 'Radio');
}
