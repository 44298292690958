const Routes = {
  HOME: '/',
  ADMIN: '/admin',
  STUDENT: '/student',
  INSTRUCTOR: '/instructor',
  LOGIN: '/login',
  SIGNUP: '/signUp',
  VERIFICATION: '/verification',
  PASSWORD_RESET: '/passwordReset',
  PROFILE_CREATION: '/profileCreation',
  DEBUG: '/debug',
  SCENARIO: '/scenario/:scenarioId',
  TRAINING_HISTORY: '/trainingHistory',
  ECS: '/ecs',
  AUTHORIZATION: '/authorization'
};

export enum RouteNames {
  HOME = 'home',
  ADMIN = 'admin',
  STUDENT = 'student',
  INSTRUCTOR = 'instructor',
  LOGIN = 'login',
  SIGNUP = 'signUp',
  VERIFICATION = 'verification',
  PASSWORD_RESET = 'passwordReset',
  PROFILE_CREATION = 'profileCreation',
  DEBUG = 'debug',
  TRAINING_HISTORY = 'training.history',
  SCENARIO = 'scenario(\\d+)',
  //  + '(\\d+)'
  INVENTORY_NONE = 'scenario.inventory',
  INVENTORY_CHECKLIST = 'scenario.inventory.checklist',
  INVENTORY_PHONE = 'scenario.inventory.phone',
  INVENTORY_PHONE_ACTIVE = 'scenario.inventory.phone.active',
  INVENTORY_BASE_SIREN = 'scenario.inventory.baseSiren',
  INVENTORY_GIANT_VOICE = 'scenario.inventory.giantVoice',
  INVENTORY_RADIO = 'scenario.inventory.radio',
  INVENTORY_RADIO_ACTIVE = 'scenario.inventory.radio.active',
  INVENTORY_HUSHLINE = 'scenario.inventory.hushline',
  ECS = 'ecs',
  AUTHORIZATION = 'authorization'
}

export default Routes;

export type Route = string;
