import { classToPlain, plainToClass } from 'class-transformer';
import { DateTransform } from './decorator/DateTransform';
import { FirestoreServiceDocument } from './FirestoreServiceDocument';

export class Class {
  id?: string;
  courseId!: string;
  name!: string;
  @DateTransform
  startDate!: Date;
  @DateTransform
  endDate!: Date;
  studentIds!: string[];
  instructorIds!: string[];
  inviteCode!: string;
  isActive: boolean;

  constructor() {
    this.id = '';
    this.courseId = '';
    this.name = '';
    this.startDate = new Date();
    this.endDate = new Date();
    this.studentIds = [];
    this.instructorIds = [];
    this.inviteCode = '';
    this.isActive = true;
  }

  // eslint-disable-next-line
  static fromJson(payload?: any): Class {
    return plainToClass(Class, payload, {
      exposeDefaultValues: true
    });
  }

  static fromFirestoreServiceResult(snapshot: FirestoreServiceDocument): Class {
    const classData = this.fromJson({
      id: snapshot.id,
      ...snapshot.data
    });
    return classData;
  }
  static toJson(_class: Class) {
    return classToPlain(_class);
  }
}
