import { IEntity } from '@IntrinsicSoftware/sim-ecs';
import { ShallowRef, shallowRef } from 'vue';
import { ComponentException } from '../types';

export class Category {
  constructor(public column = '', public title = '') {}
}

export interface CategoryViewModel {
  column: string;
  title: string;
}

export class CategorySynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<Category> = shallowRef<Category>({
      column: '',
      title: ''
    })
  ) {}
}

export const serializeCategory = (category: Category): CategoryViewModel => {
  return { column: category.column, title: category.title };
};

export const useCategory = (entity: IEntity) => {
  const category = entity.getComponent(Category);
  if (!category) {
    throw new ComponentException(entity.id, 'Category component not found');
  }
  const ref = shallowRef<CategoryViewModel>(serializeCategory(category));
  return ref;
};
