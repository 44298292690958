
import { computed, defineComponent, PropType } from 'vue';
import Icon, { IconMap } from './Icon';

export default defineComponent({
  props: {
    icon: {
      type: Number as PropType<Icon>,
      required: true
    }
  },
  setup(props) {
    const src = computed(() => {
      return IconMap[props.icon];
    });
    return {
      src
    };
  }
});
