
import { defineComponent, onMounted, ref } from 'vue';
import SignUpComponent from '@/components/login/SignUpComponent.vue';
import {
  PASSWORD_DOES_NOT_MATCH,
  SignUpFormFields
} from '@/components/login/FormFields';
import { AuthClient } from '@/services/auth';
import { useRouter } from 'vue-router';
import { RouteNames } from '../router/Routes';
import { useGlobalStore } from '../store/globalStore';

export default defineComponent({
  components: { SignUpComponent },
  setup() {
    const error = ref();
    const isLoading = ref(false);
    const router = useRouter();
    const store = useGlobalStore();

    onMounted(() => {
      store.pageViewEvent(RouteNames.SIGNUP);
    });

    function onSignup({ email, password, passwordConfirm }: SignUpFormFields) {
      if (password !== passwordConfirm) {
        error.value = PASSWORD_DOES_NOT_MATCH;
        return;
      }
      startSignup({ email, password, passwordConfirm });
    }

    async function startSignup(SignUpFormFields: SignUpFormFields) {
      isLoading.value = true;
      error.value = '';
      try {
        // Create new user with email and password through the firebase SDK
        const user =
          await AuthClient.getInstance().createUserWithEmailAndPassword(
            SignUpFormFields.email,
            SignUpFormFields.password
          );
        if (user) {
          await AuthClient.getInstance().sendEmailVerification();
          router.push({
            name: RouteNames.VERIFICATION
          });
        }
      } catch (err) {
        if (err instanceof Error) {
          error.value = err.message;
        } else {
          error.value = 'There was an error signing up. Please try again.';
        }
      }
      isLoading.value = false;
    }

    return {
      error,
      isLoading,
      onSignup
    };
  }
});
