import firebase from 'firebase/app';
import { ComputedRef, Ref, ShallowRef } from 'vue';
import { ActiveViewmodel } from './active/main';
import {
  ChecklistComposable,
  ChecklistViewModel,
  checklistViewModelToJson
} from './completable/Completable';
import { ChecklistCount, ModuleComposable } from './module/useModule';
import { OnCollectibleCategorizedPointCreated } from './module/Module';
import { DescriptionViewModel } from './description/main';

export interface SelectableChecklist {
  composable: ChecklistComposable;
  selected: Ref<boolean>;
  setSelected: (value: boolean) => void;
}

export interface Scenario {
  cleanup: () => void;
  onCollectiblePointCreated: (
    callback: (event: OnCollectibleCategorizedPointCreated) => void
  ) => void;
  scenarioState: ScenarioComposable;
  id: string;
}

export class ChecklistSerializedThing {
  id: string;
  complete: boolean;
  compromised: boolean;
  constructor(id: string, complete: boolean, compromised: boolean) {
    this.id = id;
    this.complete = complete;
    this.compromised = compromised;
  }
}

export class ModuleSerializedThing {
  id: string;
  description: DescriptionViewModel;
  checklists: ChecklistViewModel[];
  constructor(moduleComposable: ModuleComposable) {
    this.id = moduleComposable.id;
    this.description = moduleComposable.description.value;
    this.checklists = moduleComposable.checklists.map(
      (selectableChecklist) => selectableChecklist.composable.checklist.value
    );
  }
  toJson() {
    const result = {
      id: this.id,
      description: this.description,
      checklists: this.checklists.map((checklist) =>
        checklistViewModelToJson(checklist)
      )
    };

    return result;
  }
}

export type Millisecond = number;
export type PointAward = number;

export class ScenarioDeserializedThing {
  constructor(
    public id: string,
    public authorId: string,
    public completedAt: firebase.firestore.Timestamp,
    public history: ScenarioSerializedThing
  ) {}
}

export class ScenarioSerializedThing {
  constructor(
    public modules: ModuleSerializedThing[] = [],
    public timeSpent: Millisecond,
    public pointsEarned: PointAward,
    public checklistCount: ChecklistCount,
    public totalPointsPossible: PointAward
  ) {}
  static Default() {
    return new ScenarioSerializedThing(
      [],
      0,
      0,
      {
        compromisedCount: 0,
        total: 0
      },
      0
    );
  }
  toFirestore() {
    return {};
  }
  toJson() {
    return {
      timeSpent: this.timeSpent,
      pointsEarned: this.pointsEarned,
      checklistCount: this.checklistCount,
      totalPointsPossible: this.totalPointsPossible,
      modules: this.modules.map((module) => module.toJson())
    };
  }
  toString() {
    return JSON.stringify(this.toJson(), null, 2);
  }
}

export interface ScenarioComposable {
  activeModule: ShallowRef<ModuleComposable>;
  complete: ComputedRef<boolean>;
  totalChecklistCount: Ref<ChecklistCount>;
  history: ShallowRef<ScenarioSerializedThing>;
  totalPointsPossible: PointAward;
  pointsEarned: Ref<PointAward>;
  addPoint: (point: PointAward) => void;
  elapsedTime: Ref<{
    minutes: string;
    seconds: string;
    secondsRaw: number;
    minutesRaw: number;
  }>;
  onComplete: (fn: () => void) => void;
}

export interface BaseSirenComposable {
  active: ShallowRef<ActiveViewmodel>;
  toggle: () => void;
  complete: () => void;
  id: string;
}

export class ComponentException extends Error {
  constructor(entityId: string, description: string) {
    super(`Entity: ${entityId}. ${description}`);
  }
}
