import {
  createSystem,
  IEntity,
  queryComponents,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { shallowRef, ShallowRef } from 'vue';
import { MessageNode, Option } from '../dialogFlow/DialogFlow';
import { Disable } from '../disable/component';
import { ComponentException } from '../types';

export const initialDialogTree: MessageNode = {
  speaker: '',
  dialog: '',
  options: new Map(),
  correctOptionId: ''
};

const initialOption: Option = {
  id: '',
  text: '',
  nextMessage: initialDialogTree
};

export class ProgressDialogueCommand {
  constructor(public optionId: string = '') {}
  entries: ProgressDialogueCommand[] = [];
}

export class ActivePhoneCall {
  constructor(
    public sent: MessageNode = initialDialogTree,
    public received: Option = initialOption,
    public history: MessageNode[] = []
  ) {}
}

export interface ActivePhoneCallComposable {
  activePhoneCallViewModel: ShallowRef<ActivePhoneCallViewModel>;
  progressDialogue: (optionId: string) => void;
  enabled: boolean;
}

export interface ActivePhoneCallViewModel {
  sent: MessageNode;
  received: Option;
  history: MessageNode[];
}

export class ActivePhoneCallSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<ActivePhoneCallViewModel> = shallowRef<ActivePhoneCallViewModel>(
      {
        sent: initialDialogTree,
        received: initialOption,
        history: []
      }
    )
  ) {}
}

export const serializeActivePhoneCall = (
  activePhoneCall: ActivePhoneCall
): ActivePhoneCallViewModel => {
  return {
    sent: activePhoneCall.sent,
    received: activePhoneCall.received,
    history: activePhoneCall.history
  };
};

export function useActivePhoneCall(entity: IEntity): ActivePhoneCallComposable {
  const activePhoneCall = entity.getComponent(ActivePhoneCall);
  if (!activePhoneCall) {
    throw new ComponentException(
      entity.id,
      'ActivePhoneCall component not found'
    );
  }
  const ref = shallowRef<ActivePhoneCallViewModel>(
    serializeActivePhoneCall(activePhoneCall)
  );
  const activePhoneCallViewModel = new ActivePhoneCallSynchronizationComponent(
    ref
  );
  const didFindDisabledComponent = entity.getComponent(Disable);
  const enabled = !didFindDisabledComponent;

  entity.addComponent(activePhoneCallViewModel);
  return {
    activePhoneCallViewModel: ref,
    progressDialogue: (optionId: string) => {
      const commands = entity.getComponent(ProgressDialogueCommand);
      if (commands) {
        commands.entries.push(new ProgressDialogueCommand(optionId));
      }
    },
    enabled
  };
}

export class ActivePhoneCallOptionSelected {
  constructor(public optionId: string) {}
}

export const ActivePhoneCallViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      activePhoneCall: Write(ActivePhoneCall),
      viewModel: Write(ActivePhoneCallSynchronizationComponent)
    })
  })
    .withName('ActivePhoneCallViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { activePhoneCall, viewModel } of query.iter()) {
        viewModel.shallowReference.value = {
          sent: activePhoneCall.sent,
          received: activePhoneCall.received,
          history: activePhoneCall.history
        };
      }
    })
    .build();
};
