import {
  IEntity,
  createSystem,
  queryComponents,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { ShallowRef, shallowRef } from 'vue';
import { MessageBroadcast } from '../../models/BuildableDialogItem';
import { Completable, CompleteCommand } from '../completable/Completable';
import {
  Conversation,
  ConversationType,
  Dialog
} from '../conversation/Conversation';
import { ConversationSingleton } from '../conversation/ConversationSingleton';
import { Branch, ControlType, HistoryItem } from '../dialog/component';
import { Inbox } from '../inbox/component';
import { Outbox } from '../outbox/Outbox';
import { EchoBrain as EchoBrainFunctions } from './EchoBrain';
import { LinearDialogBrain as LinearDialogBrainFunctions } from './LinearDialogBrain';
import { TapperBrain as TapperBrainFunctions } from './TapperBrain';
import { PlayerBrainFunctions } from './PlayerBrain';
import { ComponentException } from '../types';

export interface Context {
  inbox: Inbox;
  outbox: Outbox;
  conversations: ConversationSingleton;
  conversation: Conversation;
  isGoalNode: boolean;
  accumulatedWeightAverage: number;
  completable: Completable;
  completeCommand: CompleteCommand;
  dialog: Dialog;
  incomingMessage: MessageBroadcast<string> | undefined;
}

export interface DelegateFunctions {
  sense(): Context;
  evaluate(): Context;
  act(): void;
  exit(): void;
}

export function noop() {
  return;
}

export enum BrainDelegateType {
  LINEAR = 'Linear',
  TAPPER = 'Tapper',
  PLAYER = 'Player',
  ECHO = 'Echo'
}

export function getDialogFromConversation(context: Context) {
  const dialog = context.conversations.conversationIdDialogMap.get(
    context.conversation.conversationId
  );
  if (!dialog) {
    throw 'No Dialog Present';
  }
  return dialog;
}

const BrainDelegate: Record<
  BrainDelegateType,
  (context: Context, brain: Brain) => DelegateFunctions
> = {
  [BrainDelegateType.LINEAR]: (context: Context, brain: Brain) => {
    return LinearDialogBrainFunctions(context, brain);
  },
  [BrainDelegateType.TAPPER]: (context: Context, brain: Brain) => {
    return TapperBrainFunctions(context, brain);
  },
  [BrainDelegateType.PLAYER]: (context: Context, brain: Brain) => {
    return PlayerBrainFunctions(context, brain);
  },
  [BrainDelegateType.ECHO]: (context: Context, brain: Brain) => {
    return EchoBrainFunctions(context, brain);
  }
};

export class Brain {
  sense(context: Context): Context {
    return BrainDelegate[this.delegate](context, this).sense();
  }
  evaluate(context: Context): Context {
    return BrainDelegate[this.delegate](context, this).evaluate();
  }
  act(context: Context): void {
    return BrainDelegate[this.delegate](context, this).act();
  }
  exit(context: Context): void {
    return BrainDelegate[this.delegate](context, this).exit();
  }
  public dialogTreeReference: ConversationType = ConversationType.NONE;
  public delegate: BrainDelegateType = BrainDelegateType.LINEAR;
  constructor(
    public thinking = false,
    public options: Branch[] = [],
    public history: HistoryItem[] = [],
    public delayTime = 0,
    public acceptableWeightAverage = 75,
    public controlType: ControlType = 'OptionSelect'
  ) {}
}

export interface BrainViewModel {
  thinking: boolean;
  options: Branch[];
  history: HistoryItem[];
  controlType: ControlType;
}

export class BrainSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<BrainViewModel> = shallowRef({
      thinking: false,
      options: [],
      history: [],
      controlType: <ControlType>'OptionSelect'
    })
  ) {}
}

export interface BrainComposable {
  brain: ShallowRef<BrainViewModel>;
}

export function useBrain(entity: IEntity): BrainComposable {
  const brain = entity.getComponent(Brain);
  if (!brain) {
    throw new ComponentException(entity.id, 'Entity has no Brain component');
  }
  const brainSynchronizationComponent = entity.getComponent(
    BrainSynchronizationComponent
  );
  if (brainSynchronizationComponent) {
    return {
      brain: brainSynchronizationComponent.shallowReference
    };
  }
  const ref = shallowRef<BrainViewModel>({
    options: brain.options,
    thinking: brain.thinking,
    history: brain.history,
    controlType: brain.controlType
  });
  const synchronizationComponent = new BrainSynchronizationComponent(ref);
  entity.addComponent(synchronizationComponent);
  return { brain: ref };
}

export const BrainViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      brain: Write(Brain),
      viewModel: Write(BrainSynchronizationComponent)
    })
  })
    .withName('BrainViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { brain, viewModel } of query.iter()) {
        viewModel.shallowReference.value = {
          options: brain.options,
          thinking: brain.thinking,
          history: brain.history,
          controlType: brain.controlType
        };
      }
    })
    .build();
};
