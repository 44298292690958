import Routes, { Route } from './Routes';
import { User } from '@/models/User';
import { UserRole } from '@/models/UserRoles';
import { RouteLocationNormalized, Router } from 'vue-router';
import { useGlobalStore } from '@/store/globalStore';
import pinia from '../store/store';

const routeForRole = new Map<UserRole, Route>([
  [UserRole.ADMIN, Routes.ADMIN],
  [UserRole.INSTRUCTOR, Routes.INSTRUCTOR],
  [UserRole.STUDENT, Routes.STUDENT]
]);

class RoleRouteHandler {
  static initialize(router: Router): () => void {
    return router.beforeEach(async (to) => {
      const store = useGlobalStore(pinia);
      const user = store.getCurrentUser;
      const isVerified = store.getIsEmailVerified;

      if (!user) {
        return;
      }

      if (
        user &&
        user.role === UserRole.GUEST &&
        isVerified &&
        to.path !== Routes.PROFILE_CREATION
      ) {
        return Routes.PROFILE_CREATION;
      }

      if (!isVerified && to.path !== Routes.VERIFICATION) {
        return Routes.VERIFICATION;
      }

      if (to.path === Routes.HOME) {
        // return {
        //   name: RouteNames.SCENARIO,
        //   // name: RouteNames.INVENTORY_PHONE_ACTIVE,
        //   params: { scenarioId: 1 }
        // };
        await store.fetchClass(user.organizationId, user.classId);
        const currentClass = store.getCurrentClass;
        if (user.role === UserRole.STUDENT && !currentClass?.isActive) {
          store.setAuthorizationDetails(
            'Your account is not enrolled in an active class. Please contact your administrator for assistance.'
          );
          return Routes.AUTHORIZATION;
        }
        return routeForRole.get(user.role);
      }
      switch (to.path) {
        case Routes.ADMIN:
          return handleAdminPageRouter(to, user);
        case Routes.INSTRUCTOR:
          return handleInstructorPageRouter(to, user);
        case Routes.PROFILE_CREATION:
          return handleProfileCreationRouter(to, user);
        default:
          return;
      }
    });

    function handleAdminPageRouter(to: RouteLocationNormalized, user: User) {
      if (user.role === UserRole.ADMIN) {
        return;
      } else {
        return { name: 'home' };
      }
    }

    function handleInstructorPageRouter(
      to: RouteLocationNormalized,
      user: User
    ) {
      if (user.role === UserRole.INSTRUCTOR) {
        return;
      } else {
        return { name: 'home' };
      }
    }

    function handleProfileCreationRouter(
      to: RouteLocationNormalized,
      user: User
    ) {
      if (user.role === UserRole.GUEST) {
        return;
      } else {
        return { name: 'home' };
      }
    }
  }
}

export default RoleRouteHandler;
