import firebase from 'firebase/app';
import 'firebase/auth';
import { RouteLocationNormalized, Router } from 'vue-router';
import Routes from './Routes';
import { useGlobalStore } from '@/store/globalStore';
import pinia from '../store/store';

export class AuthenticationRouteHandler {
  private static store = useGlobalStore(pinia);

  static initialize(router: Router): () => void {
    const handler = AuthenticationRouteHandler.onRouterEntry.bind(this);
    return router.beforeEach(handler);
  }

  /*
    Auth state changed handler. This gets triggered when there is a change to the currently logged in firebase user
    Login, logout, signup etc.
  */
  static async onStateChange(
    router: Router,
    user: firebase.User | null
  ): Promise<void> {
    // Logout
    if (!user) {
      await this.store.logout();
      router.push({
        path: Routes.LOGIN
      });
      return;
    } else {
      await this.store.login(user);
    }
    const isVerified = this.store.getIsEmailVerified;
    const toVerification =
      router.currentRoute.value.path === Routes.VERIFICATION;

    if (!isVerified && !toVerification) {
      router.push({
        path: Routes.VERIFICATION
      });
      return;
    }

    // Redirect to home if user is logged in and trying to access login page
    if (user && router.currentRoute.value.path === Routes.LOGIN) {
      router.replace({
        path: Routes.HOME
      });
    }
  }

  /*
    Router middleware. Guard each route depending on the route configuration in router.ts.
    Ensure the currently signed in user has the correct role to see the route.
  */
  static onRouterEntry(to: RouteLocationNormalized) {
    const isLoggedIn = this.store.getIsLoggedIn;
    const isVerified = this.store.getIsEmailVerified;
    const toVerification = to.path === Routes.VERIFICATION;
    const toLogin = to.path === Routes.LOGIN;
    const toSignup = to.path === Routes.SIGNUP;
    const toPasswordReset = to.path === Routes.PASSWORD_RESET;

    if (!isLoggedIn && !toLogin && !toSignup && !toPasswordReset) {
      return { path: Routes.LOGIN, redirect: to.path };
    }

    if (!isLoggedIn && (toLogin || toSignup || toPasswordReset)) {
      return;
    }

    if (isLoggedIn && !isVerified && !toVerification) {
      return { path: Routes.VERIFICATION };
    }

    if (isLoggedIn && (toLogin || toSignup)) {
      return { path: Routes.HOME };
    }
  }
}
