import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goal = {
    dialog: 'Thanks, out.',
    edges: [],
    goalNode: true
  };

  const goalNode = new Node(goal.dialog, goal.edges, goal.goalNode);

  const secondOptions: Branch[] = [
    new Branch(
      'Sir, a Tornado was observed North of Keesler AFB. Valid Time: Now - Until Further Notice.',
      100,
      goalNode,
      false
    ),
    new Branch(
      'Command Post observed a tornado 3 NM North of Keesler AFB. Valid Time: Now - Until Canceled.',
      75,
      goalNode,
      false
    ),
    new Branch(
      'Sir, a Tornado watch is in effect for Keesler AFB. Valid Time: Now - Until Canceled.',
      50,
      goalNode,
      false
    ),
    new Branch(
      'Tornado observed 3 NM South and bearing down on Keesler AFB. Valid Time: Now, Take cover!',
      0,
      goalNode,
      false
    )
  ];

  const firstMessage = {
    dialog: 'Copy, go ahead.',
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch(
      'Sir, this is Command Post with a Severe WX Warning.',
      100,
      firstMessageNode,
      false
    ),
    new Branch(
      'This is Command Post with a Tornado Warning.',
      75,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, this is Command Post with a Severe WX Watch.',
      50,
      firstMessageNode,
      false
    ),
    new Branch(
      'There is a Tornado bearing down on the base, Sir.',
      0,
      firstMessageNode,
      false
    )
  ];

  return new Node('This is General Sharpe.', firstOptions, false);
}
