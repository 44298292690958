import {
  createSystem,
  queryComponents,
  Read,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import Timer from '../timer/main';
import { Completable, CompletionQuality } from '../completable/Completable';

export const PointTimerExpiredSystem = () => {
  return createSystem({
    query: queryComponents({
      timer: Read(Timer),
      completable: Write(Completable)
    })
  })
    .withName('PointTimerExpiredSystem')
    .withRunFunction(({ query }) => {
      for (const { completable, timer } of query.iter()) {
        if (timer.expired) {
          completable.completionQuality.state = CompletionQuality.TimeExpired;
        }
      }
    })
    .build();
};
