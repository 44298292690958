import {
  createSystem,
  IEntity,
  queryComponents,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { shallowRef, ShallowRef } from 'vue';
import { ComponentException } from '../types';

// ECMAScript max date
/*
The actual range of times supported by ECMAScript Date objects is slightly smaller:
exactly –100,000,000 days to 100,000,000 days measured relative to midnight at the beginning of 01 January, 1970 UTC.
This gives a range of 8,640,000,000,000,000 milliseconds to either side of 01 January, 1970 UTC.
*/
export const DATE_MAX = 8640000000000000;

export interface TimerViewModel {
  limit: number;
  startTime: number;
  expired: boolean;
}

export default class Timer {
  constructor(
    public limit = 0,
    public startTime: number = new Date().getTime(),
    public expired: boolean = false
  ) {}
}

export class TimerSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<TimerViewModel> = shallowRef<TimerViewModel>(
      {
        limit: 0,
        startTime: new Date().getTime(),
        expired: false
      }
    )
  ) {}
}

export function useTimer(entity: IEntity) {
  const timer = entity.getComponent(Timer);
  if (!timer) {
    throw new ComponentException(
      entity.id,
      'Entity is not a timer. Missing Timer component'
    );
  }
  const ref = shallowRef<TimerViewModel>({
    limit: timer.limit,
    startTime: timer.startTime,
    expired: timer.expired
  });
  const timerViewModel = new TimerSynchronizationComponent(ref);
  entity.addComponent(timerViewModel);
  return ref;
}

export const TimerSystem = () => {
  return createSystem({
    query: queryComponents({
      timer: Write(Timer)
    })
  })
    .withName('TimerSystem')
    .withRunFunction(({ query }) => {
      const now = new Date().getTime();
      for (const { timer } of query.iter()) {
        const started = now >= timer.startTime;
        if (started) {
          timer.expired = now - timer.startTime >= timer.limit;
        }
      }
    })
    .build();
};

export const TimerViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      timer: Write(Timer),
      viewModel: Write(TimerSynchronizationComponent)
    })
  })
    .withName('TimerViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { timer, viewModel } of query.iter()) {
        viewModel.shallowReference.value = {
          limit: timer.limit,
          startTime: timer.startTime,
          expired: timer.expired
        };
      }
    })
    .build();
};
