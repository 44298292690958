import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { buildConfig } from '../config';
import { Class } from '@/models/Class';
import { Course } from '@/models/Course';
import { Invite } from '@/models/Invite';
import { Organization } from '@/models/Organization';
import { User } from '@/models/User';
import { UserRole } from '../models/UserRoles';
import Message from '../models/Message';
import { ACHIEVEMENTS, Achievement, AchievementId } from '@/models/Achievement';
import { ScenarioSerializedThing } from '../ecs/types';
import { ModuleCategory } from '../ecs/database';

const config = buildConfig(process.env);

export class Score {
  constructor(
    public weather = 0,
    public emergency = 0,
    public filler = 0,
    public security = 0,
    public aircraft = 0
  ) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromFirestore(data: any): Score {
    const weather = data.weather ?? 0;
    const emergency = data.emergency ?? 0;
    const filler = data.filler ?? 0;
    const security = data.security ?? 0;
    const aircraft = data.aircraft ?? 0;
    return new Score(weather, emergency, filler, security, aircraft);
  }
  public sum() {
    return (
      this.weather +
      this.emergency +
      this.filler +
      this.security +
      this.aircraft
    );
  }
}

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

export const firestoreDatabase = firebase.firestore();
export const firebaseFunctions = firebase.functions();

if (config.firebase.firestore.local) {
  firestoreDatabase.useEmulator(
    config.firebase.firestore.host,
    config.firebase.firestore.port
  );

  firebaseFunctions.useEmulator(
    config.firebase.functions.host,
    config.firebase.functions.port
  );
}

let instance: FirestoreService;

export type FirestoreErrorCode =
  | 'cancelled'
  | 'invalid-argument'
  | 'deadline-exceeded'
  | 'not-found'
  | 'already-exists'
  | 'permission-denied'
  | 'resource-exhausted'
  | 'failed-precondition'
  | 'aborted'
  | 'out-of-range'
  | 'unimplemented'
  | 'internal'
  | 'unavailable'
  | 'data-loss'
  | 'unauthenticated';

export interface FirestoreServiceError {
  code: FirestoreErrorCode;
  message: string;
  name: string;
  stack?: string;
}

export interface FirestoreServiceDocument {
  data: {
    [field: string]: unknown;
  };
  id: string;
}

export class FirestoreServiceResult<T> {
  constructor(data: T) {
    this.data = data;
  }
  data: T;
}

export default class FirestoreService {
  database: firebase.firestore.Firestore;
  constructor() {
    this.database = firestoreDatabase;
  }

  static getInstance(): FirestoreService {
    if (!instance) {
      instance = new FirestoreService();
    }
    return instance;
  }

  // Class
  async fetchClasses(organizationId: string): Promise<Class[]> {
    try {
      const query = (await this.database
        .collection('organization')
        .doc(organizationId)
        .collection('class')
        .get()) as firebase.firestore.QuerySnapshot<Class>;
      const classes: Class[] = [];
      query.forEach((doc) => {
        classes.push(
          Class.fromJson({
            ...doc.data(),
            id: doc.id
          })
        );
      });
      return classes;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async fetchClass(organizationId: string, classId: string): Promise<Class> {
    try {
      const query = (await this.database
        .collection('organization')
        .doc(organizationId)
        .collection('class')
        .doc(classId)
        .get()) as firebase.firestore.DocumentSnapshot<Class>;
      if (!query.exists) {
        return Promise.reject('Class not found');
      }
      const classData = query.data();
      if (!classData) {
        return Promise.reject('Class not found');
      }
      const _class: Class = Class.fromJson(query.data());
      return _class;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async createClass(
    organizationId: string,
    classPayload: Class
  ): Promise<Class> {
    try {
      const query = await this.database
        .collection('organization')
        .doc(organizationId)
        .collection('class')
        .add(classPayload);
      const _class = Class.fromJson({
        ...classPayload,
        id: query.id
      });
      return _class;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateClass(
    organizationId: string,
    classId: string,
    classData: Class
  ): Promise<Class | FirestoreServiceError> {
    const updatedClass = Class.fromJson(classData);
    try {
      await this.database
        .collection('organization')
        .doc(organizationId)
        .collection('class')
        .doc(classId)
        .update(Class.toJson(updatedClass));
      const _class = Class.fromJson({
        id: classId,
        ...classData
      });
      return _class;
    } catch (error) {
      return Promise.reject(
        'You do not have permission to access this resource'
      );
    }
  }
  async deleteClass(
    organizationId: string,
    classId: string
  ): Promise<void | FirestoreServiceError> {
    try {
      await this.database
        .collection('organization')
        .doc(organizationId)
        .collection('class')
        .doc(classId)
        .delete();
    } catch (error) {
      return Promise.reject(
        'You do not have permission to access this resource'
      );
    }
  }

  // Courses
  async fetchCourses(organizationId: string): Promise<Course[]> {
    try {
      const query = (await this.database
        .collection('organization')
        .doc(organizationId)
        .collection('course')
        .get()) as firebase.firestore.QuerySnapshot<Course>;
      const courses: Course[] = [];
      query.forEach((doc) => {
        const _course: Course = {
          id: doc.id,
          courseName: doc.data().courseName,
          classIds: doc.data().classIds,
          instructorIds: doc.data().instructorIds,
          inviteCode: doc.data().inviteCode
        };
        courses.push(_course);
      });
      return courses;
    } catch (error) {
      Promise.reject(error);
      return [];
    }
  }
  async fetchCourse(organizationId: string, courseId: string): Promise<Course> {
    try {
      const query = await this.database
        .collection('organization')
        .doc(organizationId)
        .collection('course')
        .doc(courseId)
        .get();
      if (!query.exists) {
        return Promise.reject('Course not found');
      }
      const courseData = query.data();
      if (!courseData) {
        return Promise.reject('Course not found');
      }
      const _course: Course = {
        id: query.id,
        courseName: courseData.courseName,
        classIds: courseData.classIds,
        instructorIds: courseData.instructorIds,
        inviteCode: courseData.inviteCode
      };
      return _course;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async createCourse(
    organizationId: string,
    course: Course
  ): Promise<Course | FirestoreServiceError> {
    try {
      const query = await this.database
        .collection('organization')
        .doc(organizationId)
        .collection('course')
        .add(course);
      const _course: Course = {
        id: query.id,
        courseName: course.courseName,
        classIds: course.classIds,
        instructorIds: course.instructorIds,
        inviteCode: course.inviteCode
      };
      return _course;
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'You do not have permission to access this resource',
        name: 'FirestoreError'
      };
    }
  }
  async updateCourse(organizationId: string, course: Course): Promise<Course> {
    try {
      await this.database
        .collection('organization')
        .doc(organizationId)
        .collection('course')
        .doc(course.id)
        .set({
          courseName: course.courseName,
          classIds: course.classIds,
          instructorIds: course.instructorIds
        });
      const _course: Course = {
        id: course.id,
        courseName: course.courseName,
        classIds: course.classIds,
        instructorIds: course.instructorIds,
        inviteCode: course.inviteCode
      };
      return _course;
    } catch (error) {
      return Promise.reject(
        'You do not have permission to access this resource'
      );
    }
  }

  // User
  async fetchUsers(organizationId: string): Promise<User[]> {
    try {
      const query = (await this.database
        .collection('user')
        .where('organizationId', '==', organizationId)
        .get()) as firebase.firestore.QuerySnapshot<User>;
      const users: User[] = [];
      query.forEach((doc) => {
        const _user: User = User.fromJson(doc.data());
        users.push(_user);
      });
      return users;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async fetchUser(userId: string): Promise<User> {
    try {
      const query = await this.database.collection('user').doc(userId).get();
      if (!query.exists) {
        return Promise.reject('User not found');
      }
      const userData = query.data();
      if (!userData) {
        return Promise.reject('User not found');
      }
      const user: User = User.fromJson(userData);

      return user;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createUser(user: User): Promise<User> {
    try {
      await this.database.collection('user').doc(user.uid).set(user);
      return user;
    } catch (error) {
      return Promise.reject(
        'You do not have permission to access this resource'
      );
    }
  }
  async updateUser(user: User): Promise<User> {
    try {
      await this.database.collection('user').doc(user.uid).update(user);
      return user;
    } catch (error) {
      return Promise.reject(
        'You do not have permission to access this resource'
      );
    }
  }

  // Message
  async createMessage(message: Message): Promise<Message> {
    await this.database.collection('messages').add({ ...message });
    return message;
  }
  async fetchSortedMessages(classId: string): Promise<Message[]> {
    const query = await this.database
      .collection('messages')
      .where('classId', '==', classId)
      .get();
    const messages = query.docs
      .map((doc) => Message.fromJson(doc.data()))
      .sort((a, b) => b.issueDate.getTime() - a.issueDate.getTime());
    return messages;
  }

  // Invites
  async createInvite(invite: Invite, role: UserRole): Promise<Invite> {
    try {
      await this.database
        .collection('invite')
        .doc(role)
        .collection('entry')
        .doc(invite.id)
        .set(invite);
      const _invite: Invite = {
        id: invite.id,
        organizationId: invite.organizationId,
        courseId: invite.courseId,
        classId: invite.classId,
        expired: invite.expired
      };
      return _invite;
    } catch (error) {
      return Promise.reject(
        'You do not have permission to access this resource'
      );
    }
  }
  async fetchInvite(inviteId: string, role: string): Promise<Invite> {
    try {
      const query = await this.database
        .collection('invite')
        .doc(role)
        .collection('entry')
        .doc(inviteId)
        .get();
      const inviteData = query.data();
      if (!inviteData) {
        return Promise.reject('Invite not found');
      }
      const _invite: Invite = {
        id: query.id,
        organizationId: inviteData.organizationId,
        courseId: inviteData.courseId,
        classId: inviteData.classId,
        expired: inviteData.expired
      };
      return _invite;
    } catch (error) {
      return Promise.reject(
        'You do not have permission to access this resource'
      );
    }
  }
  async updateInvite(invite: Invite, role: string): Promise<Invite> {
    try {
      await this.database
        .collection('invite')
        .doc(role)
        .collection('entry')
        .doc(invite.id)
        .update({
          expired: invite.expired,
          organizationId: invite.organizationId
        });
      return invite;
    } catch (error) {
      return Promise.reject(
        'You do not have permission to access this resource'
      );
    }
  }

  // Organizations
  async fetchOrganizations(): Promise<Organization[] | FirestoreServiceError> {
    try {
      const query = await this.database.collection('organization').get();
      const organizations: Organization[] = [];
      await query.forEach((doc) => {
        const organizationData = doc.data();
        const _organization: Organization = {
          id: doc.id,
          organizationName: organizationData.organizationName
        };
        organizations.push(_organization);
      });
      return organizations;
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'You do not have permission to access this resource',
        name: 'FirestoreError'
      };
    }
  }
  async fetchOrganization(
    organizationId: string
  ): Promise<Organization | FirestoreServiceError> {
    try {
      const query = await this.database
        .collection('organization')
        .doc(organizationId)
        .get();
      if (!query.exists) {
        return {
          code: 'not-found',
          message: 'Organization not found',
          name: 'Organization Not Found'
        } as FirestoreServiceError;
      }
      const organizationData = query.data();
      if (!organizationData) {
        return {
          code: 'not-found',
          message: 'Organization not found',
          name: 'Organization Not Found'
        } as FirestoreServiceError;
      }
      const organization: Organization = {
        id: query.id,
        organizationName: organizationData.organizationName
      };
      return organization;
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'You do not have permission to access this resource',
        name: 'FirestoreError'
      };
    }
  }
  async createOrganization(organization: {
    organizationName: string;
  }): Promise<Organization | FirestoreServiceError> {
    try {
      const query = await this.database
        .collection('organization')
        .add(organization);
      const _organization: Organization = {
        id: query.id,
        organizationName: organization.organizationName
      };
      return _organization;
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'You do not have permission to access this resource',
        name: 'FirestoreError'
      };
    }
  }

  // Firestore Batch Transactions
  async createClassFullyQualified(
    classData: Class,
    organizationId: string,
    courseId: string
  ): Promise<Class | FirestoreServiceError> {
    try {
      const batch = this.database.batch();
      const courseRef = this.database
        .collection('organization')
        .doc(organizationId)
        .collection('course')
        .doc(courseId);
      const courseQuery = await courseRef.get();
      if (!courseQuery.exists) {
        return {
          code: 'not-found',
          message: 'Course not found',
          name: 'Course Not Found'
        } as FirestoreServiceError;
      }
      const courseData = courseQuery.data();
      if (!courseData) {
        return {
          code: 'not-found',
          message: 'Course not found',
          name: 'Course Not Found'
        } as FirestoreServiceError;
      }
      const classRef = this.database
        .collection('organization')
        .doc(organizationId)
        .collection('class')
        .doc();
      const classId = classRef.id;
      const inviteRef = this.database
        .collection('invite')
        .doc('student')
        .collection('entry')
        .doc(classData.inviteCode);
      const inviteId = classData.inviteCode;
      const invite: Invite = {
        id: inviteId,
        organizationId,
        courseId,
        classId,
        expired: false
      };
      const _class = Class.fromJson(classData);
      _class.id = classId;
      _class.inviteCode = inviteId;
      batch.set(classRef, Class.toJson(_class));
      batch.set(inviteRef, invite);
      batch.update(courseRef, {
        classIds: firebase.firestore.FieldValue.arrayUnion(classId)
      });
      await batch.commit();
      return _class;
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'You do not have permission to access this resource',
        name: 'FirestoreError'
      };
    }
  }
  async createUserFullyQualified(
    userData: User,
    inviteId: string
  ): Promise<User | FirestoreServiceError> {
    try {
      const batch = this.database.batch();
      const userRole = userData.role;
      let classRef: firebase.firestore.DocumentReference | undefined =
        undefined;
      let courseRef: firebase.firestore.DocumentReference | undefined =
        undefined;

      if (!userRole) {
        return {
          code: 'not-found',
          message: 'User role not found',
          name: 'User Role Not Found'
        } as FirestoreServiceError;
      }
      const inviteRef = this.database
        .collection('invite')
        .doc(userRole)
        .collection('entry')
        .doc(inviteId);
      const inviteQuery = await inviteRef.get();
      if (!inviteQuery.exists) {
        return {
          code: 'not-found',
          message: 'Invite not found',
          name: 'Invite Not Found'
        } as FirestoreServiceError;
      }
      const inviteData = inviteQuery.data();
      if (!inviteData) {
        return {
          code: 'not-found',
          message: 'Invite not found',
          name: 'Invite Not Found'
        } as FirestoreServiceError;
      }
      const organizationRef = this.database
        .collection('organization')
        .doc(inviteData.organizationId);
      const organizationQuery = await organizationRef.get();
      if (!organizationQuery.exists) {
        return {
          code: 'not-found',
          message: 'Organization not found',
          name: 'Organization Not Found'
        } as FirestoreServiceError;
      }
      const organizationData = organizationQuery.data();
      if (!organizationData) {
        return {
          code: 'not-found',
          message: 'Organization not found',
          name: 'Organization Not Found'
        } as FirestoreServiceError;
      }
      if (inviteData.courseId) {
        courseRef = this.database
          .collection('organization')
          .doc(inviteData.organizationId)
          .collection('course')
          .doc(inviteData.courseId);
        const courseQuery = await courseRef.get();
        if (!courseQuery.exists) {
          return {
            code: 'not-found',
            message: 'Course not found',
            name: 'Course Not Found'
          } as FirestoreServiceError;
        }
        const courseData = courseQuery.data();
        if (!courseData) {
          return {
            code: 'not-found',
            message: 'Course not found',
            name: 'Course Not Found'
          } as FirestoreServiceError;
        }
      }
      if (inviteData.classId) {
        classRef = this.database
          .collection('organization')
          .doc(inviteData.organizationId)
          .collection('class')
          .doc(inviteData.classId);
        const classQuery = await classRef.get();
        if (!classQuery.exists) {
          return {
            code: 'not-found',
            message: 'Class not found',
            name: 'Class Not Found'
          } as FirestoreServiceError;
        }
        const classData = classQuery.data();
        if (!classData) {
          return {
            code: 'not-found',
            message: 'Class not found',
            name: 'Class Not Found'
          } as FirestoreServiceError;
        }
      }
      const userRef = this.database.collection('user').doc(userData.uid);
      userData = {
        ...userData,
        organizationId: inviteData.organizationId,
        courseId: inviteData.courseId || '',
        classId: inviteData.classId || ''
      };
      batch.set(userRef, userData);
      if (userData.role === UserRole.STUDENT && classRef) {
        batch.update(classRef, {
          studentIds: firebase.firestore.FieldValue.arrayUnion(userData.uid)
        });
      }
      if (userData.role === UserRole.INSTRUCTOR && courseRef) {
        batch.update(courseRef, {
          instructorIds: firebase.firestore.FieldValue.arrayUnion(userData.uid)
        });
      }
      const permissionRef = this.database
        .collection('permission')
        .doc(userRole)
        .collection('entry')
        .doc(userData.uid);
      batch.set(permissionRef, {});
      if (userData.role === UserRole.ADMIN) {
        batch.update(inviteRef, {
          expired: true
        });
      }
      await batch.commit();
      return userData;
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'You do not have permission to access this resource',
        name: 'FirestoreError'
      };
    }
  }

  async addPoints(
    userId: string,
    points: number,
    category: ModuleCategory
  ): Promise<void> {
    const userPointsRef = this.database
      .collection('challenge')
      .doc('points')
      .collection('entry')
      .doc(userId);

    const NOOP = 0;
    let weatherPoints = NOOP;
    let emergencyPoints = NOOP;
    let fillerPoints = NOOP;
    let securityPoints = NOOP;
    let aircraftPoints = NOOP;
    if (category === ModuleCategory.WEATHER) {
      weatherPoints = points;
    } else if (category === ModuleCategory.EMERGENCY) {
      emergencyPoints = points;
    } else if (category === ModuleCategory.FILLER) {
      fillerPoints = points;
    } else if (category === ModuleCategory.SECURITY) {
      securityPoints = points;
    } else if (category === ModuleCategory.AIRCRAFT) {
      aircraftPoints = points;
    }

    const doc = await userPointsRef.get();
    if (!doc.exists) {
      await userPointsRef.set(<Score>{
        weather: 0,
        emergency: 0,
        filler: 0,
        security: 0,
        aircraft: 0
      });
    }
    const batch = this.database.batch();
    batch.update(userPointsRef, {
      weather: firebase.firestore.FieldValue.increment(weatherPoints),
      emergency: firebase.firestore.FieldValue.increment(emergencyPoints),
      filler: firebase.firestore.FieldValue.increment(fillerPoints),
      security: firebase.firestore.FieldValue.increment(securityPoints),
      aircraft: firebase.firestore.FieldValue.increment(aircraftPoints)
    });
    batch.set(userPointsRef.collection('ledger').doc(), {
      weather: weatherPoints,
      emergency: emergencyPoints,
      filler: fillerPoints,
      security: securityPoints,
      aircraft: aircraftPoints
    });
    await batch.commit();
  }

  async userScore(userId: string): Promise<Score> {
    const userPointsRef = this.database
      .collection('challenge')
      .doc('points')
      .collection('entry')
      .doc(userId);
    const totalPoints = await userPointsRef.get();
    if (totalPoints.exists) {
      const data = totalPoints.data();
      // If there is an old points field this document is not migrated
      if (data?.points) {
        await userPointsRef.update({
          weather: data.points,
          emergency: 0,
          filler: 0,
          points: firebase.firestore.FieldValue.delete()
        });
        const totalPoints = await userPointsRef.get();
        return Score.fromFirestore(totalPoints.data());
      }
      // This document is migrated
      else {
        return Score.fromFirestore(data);
      }
    }
    return Score.fromFirestore({});
  }

  async generateScenarioHistory(
    scenarioSerialization: ScenarioSerializedThing
  ) {
    const user = firebase.auth().currentUser;
    const scenarioHistoryCollection =
      this.database.collection('scenarioHistory');
    const historyObject = {
      authorId: user?.uid,
      completedAt: firebase.firestore.FieldValue.serverTimestamp(),
      history: scenarioSerialization.toJson()
    };
    await scenarioHistoryCollection.add(historyObject);
  }

  async getScenarioHistory() {
    const user = firebase.auth().currentUser;
    const query = await this.database
      .collection('scenarioHistory')
      .where('authorId', '==', user?.uid)
      .orderBy('completedAt', 'desc')
      .get();
    return query.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
  }

  async addAchievement(
    userId: string,
    achievementId: AchievementId
  ): Promise<void> {
    const userAchievementLedgerRef = this.database
      .collection('challenge')
      .doc('achievements')
      .collection('entry')
      .doc(userId)
      .collection('ledger')
      .doc();

    const userAchievementRef = this.database
      .collection('challenge')
      .doc('achievements')
      .collection('entry')
      .doc(userId)
      .collection('entry')
      .doc(achievementId);

    const achievement = await userAchievementRef.get();

    if (!achievement.exists) {
      await userAchievementRef.set({
        id: achievementId,
        dateAchieved: firebase.firestore.FieldValue.serverTimestamp(),
        label: ACHIEVEMENTS[achievementId].label
      });
    }

    await userAchievementLedgerRef.set({
      id: achievementId,
      dateAchieved: firebase.firestore.FieldValue.serverTimestamp(),
      label: ACHIEVEMENTS[achievementId].label
    });
  }

  async fetchAchievements(userId: string): Promise<Achievement[]> {
    try {
      const query = (await this.database
        .collection('challenge')
        .doc('achievements')
        .collection('entry')
        .doc(userId)
        .collection('entry')
        .get()) as firebase.firestore.QuerySnapshot<Achievement>;
      const achievements = query.docs.map((doc) => {
        const achievement = Achievement.fromJson(doc.data());
        achievement.icon = ACHIEVEMENTS[doc.data().id as AchievementId].icon;
        return achievement;
      });
      return achievements;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
