import {
  createSystem,
  queryComponents,
  Read,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { Inbox, InboxSynchronizationComponent } from './component';

export const InboxViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      inboxComponent: Read(Inbox),
      viewModel: Write(InboxSynchronizationComponent)
    })
  })
    .withName('InboxViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { inboxComponent, viewModel } of query.iter()) {
        viewModel.shallowReference.value = {
          stream: inboxComponent.stream
        };
      }
    })
    .build();
};
