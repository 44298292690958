import { defineStore } from 'pinia';
import { computed, shallowRef } from 'vue';
import {
  Scenario,
  ScenarioDeserializedThing,
  ScenarioSerializedThing
} from '../ecs/types';
import { getScenarioById, ScenarioIds } from '../ecs/database';
import { PhoneLineComposable } from '../ecs/phone/PhoneLine';
import { RadioComposable } from '../ecs/radio/RadioComposable';
import { Api } from '../api/Api';
import { ApiRetry } from '../api/plugins/ApiRetry';
import FirestoreService from '../services/FirestoreService';
import { plainToClass } from 'class-transformer';

const api = Api.getInstance(new FirestoreService(), new ApiRetry());

export const useScenarioStore = defineStore('scenario', () => {
  const scenario = shallowRef<Scenario | null>(null);
  const activePhoneLine = shallowRef<PhoneLineComposable | null>(null);
  const activeRadioLine = shallowRef<RadioComposable | null>(null);

  const getChecklists = computed(() => {
    if (!scenario.value) {
      return;
    }
    return scenario.value.scenarioState.activeModule.value.checklists;
  });

  const getRadioLines = computed(() => {
    if (!scenario.value) {
      return;
    }
    return scenario.value.scenarioState.activeModule.value.radioLines;
  });

  const getPhoneLines = computed(() => {
    if (!scenario.value) {
      return;
    }
    return scenario.value.scenarioState.activeModule.value.phoneLines;
  });

  const getBaseSiren = computed(() => {
    if (!scenario.value) {
      return;
    }
    return scenario.value.scenarioState.activeModule.value.baseSiren;
  });

  const getGiantVoice = computed(() => {
    if (!scenario.value) {
      return;
    }
    return scenario.value.scenarioState.activeModule.value.giantVoice;
  });

  const getIncomingPhoneCall = computed(() => {
    const result =
      scenario.value?.scenarioState.activeModule.value.phoneLines.find(
        (phoneLine) =>
          phoneLine.inbox.inbox.value.stream.length > 0 &&
          phoneLine.inbox.inbox.value.stream[0].messageType === 'INVITE'
      );
    return result;
  });

  const getIncomingRadioTransmission = computed(() => {
    return scenario.value?.scenarioState.activeModule.value.radioLines.find(
      (radioLine) =>
        radioLine.inbox.inbox.value.stream.length > 0 &&
        radioLine.inbox.inbox.value.stream[0].messageType === 'INVITE'
    );
  });
  // Actions
  const getScenario = async (scenarioId: ScenarioIds) => {
    const scenarioFromMap = await getScenarioById(scenarioId);
    scenario.value = scenarioFromMap;
  };

  const setActivePhoneLine = (phoneLine: PhoneLineComposable) => {
    activePhoneLine.value = phoneLine;
  };

  const setActiveRadioLine = (radioLine: RadioComposable) => {
    activeRadioLine.value = radioLine;
  };

  const generateHistory = (scenarioSerialization: ScenarioSerializedThing) => {
    return api.generateScenarioHistory(scenarioSerialization);
  };

  const getHistory = async (): Promise<ScenarioDeserializedThing[]> => {
    const data =
      (await api.getScenarioHistory()) as ScenarioDeserializedThing[];

    const result = data.map((val) =>
      plainToClass(ScenarioDeserializedThing, val)
    );
    return result;
  };

  return {
    scenario,
    activePhoneLine,
    activeRadioLine,
    getChecklists,
    getRadioLines,
    getPhoneLines,
    getBaseSiren,
    getGiantVoice,
    getIncomingPhoneCall,
    getIncomingRadioTransmission,
    getScenario,
    setActivePhoneLine,
    setActiveRadioLine,
    generateHistory,
    getHistory
  };
});
