import { ref, Ref } from 'vue';
import Icon from '../../components/icon/Icon';
import { RouteNames } from '../../router/Routes';

export type InventoryItemOption = {
  name: string;
  icon: Icon;
  routeName: RouteNames;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inventoryComponent: any;
  selected: Ref<boolean>;
  disabled: Ref<boolean>;
  alert: Ref<boolean>;
  select: () => void;
};

export interface LoadoutOptionDefinition {
  name: string;
  icon: Icon;
  routeName: RouteNames;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inventoryComponent: any;
  alert?: Ref<boolean>;
  disabled?: Ref<boolean>;
  onSelect: () => void;
}

export interface Loadout {
  options: InventoryItemOption[];
  selectedItem: Ref<InventoryItemOption | null>;
  selectOption: (option: InventoryItemOption) => void;
}
export const useLoadout = (options: LoadoutOptionDefinition[]): Loadout => {
  const loadoutOptions: InventoryItemOption[] = options.map((option) => {
    const inventoryItemOption: InventoryItemOption = {
      name: option.name,
      icon: option.icon,
      routeName: option.routeName,
      inventoryComponent: option.inventoryComponent,
      selected: ref(false),
      alert: option.alert || ref(false),
      disabled: option.disabled || ref(false),
      select: () => {
        option.onSelect();
      }
    };
    return inventoryItemOption;
  });
  const selectOption = (option: InventoryItemOption) => {
    if (option.disabled.value) {
      return;
    }
    loadoutOptions.forEach((loadoutOption) => {
      if (option === loadoutOption) {
        loadoutOption.select();
        loadoutOption.selected.value = true;
      } else {
        loadoutOption.selected.value = false;
      }
    });
  };
  return {
    options: loadoutOptions,
    selectOption,
    selectedItem: ref(null)
  };
};
