import { IEntity } from '@IntrinsicSoftware/sim-ecs';
import { shallowRef, ShallowRef } from 'vue';
import { ComponentException } from '../types';

export class Contact {
  constructor(public targetEntityId: string) {}
}
export interface ContactViewModel {
  targetEntityId: string;
}

export class ContactSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<ContactViewModel> = shallowRef({
      targetEntityId: ''
    })
  ) {}
}

export interface ContactComposable {
  contact: ShallowRef<ContactViewModel>;
}

export const useContact = (entity: IEntity): ContactComposable => {
  const contactComponent = entity.getComponent(Contact);
  if (!contactComponent) {
    throw new ComponentException(entity.id, 'Contact component not found');
  }

  const constactSynchronizationComponent = entity.getComponent(
    ContactSynchronizationComponent
  );
  if (constactSynchronizationComponent) {
    return {
      contact: constactSynchronizationComponent.shallowReference
    };
  }

  const ref = shallowRef<ContactViewModel>({
    targetEntityId: contactComponent.targetEntityId
  });
  const synchronizationComponent = new ContactSynchronizationComponent(ref);
  entity.addComponent(synchronizationComponent);
  return { contact: ref };
};
