import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const fourthMessage = {
    dialog: '',
    edges: [],
    goalNode: true
  };

  const fourthMessageNode = new Node(
    fourthMessage.dialog,
    fourthMessage.edges,
    fourthMessage.goalNode
  );

  const fourthOptions: Branch[] = [
    new Branch('Copy, out.', 100, fourthMessageNode, false),
    new Branch('Right initals, thanks, out.', 100, fourthMessageNode, false),
    new Branch('Bye.', 50, fourthMessageNode, false),
    new Branch('So long.', 50, fourthMessageNode, false)
  ];

  const thirdMessage = {
    dialog: 'My initals are JCJ.',
    edges: fourthOptions,
    goalNode: false
  };

  const thirdMessageNode = new Node(
    thirdMessage.dialog,
    thirdMessage.edges,
    thirdMessage.goalNode
  );

  const thirdOptions: Branch[] = [
    new Branch('May I have your initials?', 100, thirdMessageNode, false),
    new Branch("Alright, I'll keep ya updated.", 75, thirdMessageNode, false),
    new Branch('Yes, gotcha.', 75, thirdMessageNode, false),
    new Branch('This happens a lot, right?', 0, thirdMessageNode, false)
  ];

  const secondMessage = {
    dialog: 'Uhh hu, alright, gotcha.',
    edges: thirdOptions,
    goalNode: false
  };

  const secondMessageNode = new Node(
    secondMessage.dialog,
    secondMessage.edges,
    secondMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch(
      'A Tornado was observed 3 NM North of Keesler AFB. Valid Time: Now - Until Further Notice.',
      100,
      secondMessageNode,
      false
    ),
    new Branch(
      'Command Post observed a tornado North of Keesler AFB. Valid Time: Now - Until Canceled.',
      75,
      secondMessageNode,
      false
    ),
    new Branch(
      'Sir, a Tornado watch is in effect for Keesler AFB. Valid Time: Now - Until Canceled.',
      50,
      secondMessageNode,
      false
    ),
    new Branch(
      'Tornado observed 3 NM South and bearing down on Keesler AFB. Valid Time: Now, Take cover!',
      0,
      secondMessageNode,
      false
    )
  ];

  const firstMessage = {
    dialog: "Give it to me, and you don't need to call me Sir.",
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch(
      'Sir, Hello, this is Command Post with a Severe WX Warning.',
      100,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, This is Command Post with a Tornado Warning.',
      75,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, This is Command Post with a Severe WX Watch.',
      50,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, take Cover; there is a Tornado bearing down on the base; pass the word.',
      0,
      firstMessageNode,
      false
    )
  ];
  return new Node(
    'Civil Engineering Squadron, Mr. Jensen speaking.',
    firstOptions,
    false
  );
}
