import { classToPlain, plainToClass } from 'class-transformer';
import Icon from '../components/icon/Icon';
import { TimestampTransform } from './decorator/TimestampTransform';

export const ACHIEVEMENTS = {
  'deep-dive': {
    id: 'deep-dive',
    icon: Icon.BADGE_DEEP_DIVE,
    label: 'Deep Dive'
  },
  'laser-precision': {
    id: 'laser-precision',
    icon: Icon.BADGE_LASER_PRECISION,
    label: 'Laser Precision'
  },
  'lift-off': {
    id: 'lift-off',
    icon: Icon.BADGE_LIFT_OFF,
    label: 'Lift Off'
  },
  'five-day-streak': {
    id: 'five-day-streak',
    icon: Icon.BADGE_FIVE_DAY_STREAK,
    label: 'Five Day Streak'
  },
  speedy: {
    id: 'speedy',
    icon: Icon.BADGE_SPEEDY,
    label: 'Speedy'
  }
};

export type AchievementId = keyof typeof ACHIEVEMENTS;

export class Achievement {
  id: string;
  icon: Icon;
  @TimestampTransform
  dateAchieved: Date;
  label: string;

  constructor() {
    this.id = '';
    this.icon = Icon.NONE;
    this.dateAchieved = new Date();
    this.label = '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(payload: any): Achievement {
    return plainToClass(Achievement, payload, {
      exposeDefaultValues: true
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromFirestoreServiceResult(snapshot: any): Achievement {
    return this.fromJson({
      id: snapshot.id,
      ...snapshot.data
    });
  }

  static toJson(achievement: Achievement) {
    classToPlain(achievement);
  }
}
