import { plainToClass } from 'class-transformer';
import { FirestoreServiceDocument } from './FirestoreServiceDocument';

export class Course {
  id!: string;
  courseName!: string;
  inviteCode!: string;
  classIds: string[] = [];
  instructorIds: string[] = [];

  constructor() {
    this.id = '';
    this.courseName = '';
    this.inviteCode = '';
    this.classIds = [];
    this.instructorIds = [];
  }

  // eslint-disable-next-line
  static fromJson(payload?: any): Course {
    return plainToClass(Course, payload, {
      exposeDefaultValues: true
    });
  }

  static fromFirestoreServiceResult(
    snapshot: FirestoreServiceDocument
  ): Course {
    const courseData = this.fromJson({
      id: snapshot.id,
      ...snapshot.data
    });
    const isCourse = courseData instanceof Course;
    if (!isCourse) {
      return new Course();
    }
    return courseData as Course;
  }
}
