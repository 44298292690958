// TODO: implement firestore error messages
export class ApiError {
  message: string;
  name: string;
  constructor(message: string, name: string) {
    this.message = message;
    this.name = name;
  }
}

export class ApiRetry {
  private readonly retry = 3;
  public async retryOnError<ResponseType>(
    fn: () => Promise<ResponseType>
  ): Promise<ResponseType | ApiError> {
    let retryCount = 0;
    do {
      try {
        const result = await fn();
        return result;
      } catch (error) {
        if (error instanceof Error) {
          return new ApiError(error.message, error.name);
        }
        return new ApiError(error as string, 'Unknown');
      }
    } while (++retryCount < this.retry);
  }
}
