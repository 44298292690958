
import { defineComponent, onMounted, ref } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Icon from '@/components/icon/Icon';
import TextInputComponent from '../TextInputComponent.vue';

export default defineComponent({
  components: {
    ButtonComponent,
    TextInputComponent
  },
  props: {
    error: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const email = ref('');
    const password = ref('');
    const passwordConfirm = ref('');
    const confirmPasswordInput = ref<HTMLElement | null>(null);
    const passwordInput = ref<HTMLElement | null>(null);

    onMounted(() => {
      if (confirmPasswordInput.value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        confirmPasswordInput.value.$el.addEventListener('paste', (e) => {
          e.preventDefault();
        });
      }
      if (passwordInput.value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        passwordInput.value.$el.addEventListener('paste', (e) => {
          e.preventDefault();
        });
      }
    });

    function signUp() {
      emit('signup', {
        email: email.value,
        password: password.value,
        passwordConfirm: passwordConfirm.value
      });
    }

    return {
      email,
      Icon,
      password,
      passwordConfirm,
      signUp,
      confirmPasswordInput,
      passwordInput
    };
  }
});
