import { plainToClass } from 'class-transformer';
import { FirestoreServiceDocument } from './FirestoreServiceDocument';

export class Invite {
  id?: string;
  organizationId!: string;
  courseId!: string;
  classId!: string;
  expired: boolean;

  constructor() {
    this.id = '';
    this.organizationId = '';
    this.courseId = '';
    this.classId = '';
    this.expired = true;
  }

  // eslint-disable-next-line
  static fromJson(payload?: any): Invite {
    return plainToClass(Invite, payload, {
      exposeDefaultValues: true
    });
  }

  static fromFirestoreServiceResult(
    snapshot: FirestoreServiceDocument
  ): Invite {
    const inviteData = this.fromJson({
      id: snapshot.id,
      ...snapshot.data
    });
    return inviteData;
  }
}
