import { ref, Ref, ShallowRef } from 'vue';
import { DescriptionViewModel, useDescription } from '../description/main';
import { CategoryViewModel, useCategory } from '../category/component';
import { IEntity } from '@IntrinsicSoftware/sim-ecs';
import { Outbox } from '../outbox/Outbox';
import {
  MessageBroadcast,
  PhoneLineBroadcastPayload
} from '../../models/BuildableDialogItem';
import { InboxComposable, useInbox } from '../inbox/component';
import { Conversation } from '../conversation/Conversation';
import { ContactComposable, useContact } from '../contact/Contact';
import { Disable } from '../disable/component';
import { BrainComposable, useBrain } from '../brains/Brain';
import { WorldProxy } from '../module/Module';

export interface PhoneLineComposable {
  brain: BrainComposable;
  description: ShallowRef<DescriptionViewModel>;
  category: ShallowRef<CategoryViewModel>;
  inbox: InboxComposable;
  contact: ContactComposable;
  entityId: string;
  enabled: boolean;
  active: Ref<boolean>;
  toggleActiveState: (timeout: number) => void;
  sendMessage: (broadcast: MessageBroadcast<string>) => void;
  call: () => void;
  hangup: () => void;
  answer: () => void;
}

export function usePhoneLine(
  entity: IEntity,
  worldProxy: WorldProxy
): PhoneLineComposable {
  const didFindDisabledComponent = entity.getComponent(Disable);
  const enabled = !didFindDisabledComponent;
  const contactComposable = useContact(entity);
  const targetEntity = worldProxy.getEntity(
    contactComposable.contact.value.targetEntityId
  );
  const active = ref(false);

  return {
    brain: useBrain(entity),
    description: useDescription(entity),
    category: useCategory(entity),
    inbox: useInbox(entity),
    contact: contactComposable,
    entityId: entity.id,
    enabled,
    active,
    toggleActiveState: (timeout) => {
      active.value = !active.value;
      if (timeout) {
        setTimeout(() => {
          active.value = !active.value;
        }, timeout);
      }
    },
    sendMessage: (broadcast: MessageBroadcast<string>) => {
      const component = entity.getComponent(Outbox);
      if (component) {
        component.stream.push(broadcast);
      }
    },
    call: function () {
      const conversation = entity.getComponent(Conversation);
      if (!conversation) {
        targetEntity?.addComponent(
          new Conversation({ type: 'entity' }, entity.id)
        );
        entity.addComponent(
          new Conversation(
            { type: 'entity' },
            contactComposable.contact.value.targetEntityId
          )
        );
        const payload: PhoneLineBroadcastPayload = {
          message: '#EstablishConnection'
        };
        const broadcastInvite: MessageBroadcast<string> = {
          payload: JSON.stringify(payload),
          messageType: 'INVITE'
        };
        this.sendMessage(broadcastInvite);
      }
    },
    hangup: function () {
      const broadcast: MessageBroadcast<string> = {
        messageType: 'BYE',
        payload: ''
      };
      this.sendMessage(broadcast);
      this.inbox.clear();
    },
    answer: function () {
      const ok: MessageBroadcast<string> = {
        payload: 'okay',
        messageType: '200 OK'
      };
      this.sendMessage(ok);
    }
  };
}
