import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goal = {
    dialog: 'Thx, Out',
    edges: [],
    goalNode: true
  };

  const goalNode = new Node(goal.dialog, goal.edges, goal.goalNode);

  const thirdOptions: Branch[] = [
    new Branch(
      'All other personnel have been evacuated. The cordon is set at 1000 meters, and BPD SWAT is en route. No other information is available at this time.',
      100,
      goalNode,
      false
    ),
    new Branch(
      'All other personnel have been evacuated. No other information is available at this time.',
      75,
      goalNode,
      false
    ),
    new Branch(
      'All extra people have been cleared from the building; I have nothing else.',
      50,
      goalNode,
      false
    ),
    new Branch(
      "Take Cover, Sir; I’ll let you know when it's safe.",
      0,
      goalNode,
      false
    )
  ];

  const secondMessage = {
    dialog: 'Understood, Continue.',
    edges: thirdOptions,
    goalNode: false
  };

  const secondMessageNode = new Node(
    secondMessage.dialog,
    secondMessage.edges,
    secondMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch(
      'Sir, two masked gunmen have barricaded themselves in Green Beans Coffee Shop, and are holding the three employees hostage.',
      100,
      secondMessageNode,
      false
    ),
    new Branch(
      'Command Post received a report of an Active Shooter in Green Beans Coffee Shop; there are three hostages.',
      75,
      secondMessageNode,
      false
    ),
    new Branch(
      'Active Shooter at Green Beans Coffee Shop.',
      50,
      secondMessageNode,
      false
    ),
    new Branch(
      'Masked gunmen have holed up at the Coffee Shop, with hostages!',
      0,
      secondMessageNode,
      false
    )
  ];

  const firstMessage = {
    dialog: 'Copy, go ahead.',
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch(
      'Sir, this is Command Post with an Active Shooter Situation',
      100,
      firstMessageNode,
      false
    ),
    new Branch(
      'This is Command Post with an Active Shooter Situation',
      75,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, this is Command Post reporting a shooting',
      50,
      firstMessageNode,
      false
    ),
    new Branch('There is a gunman at the BX, Sir', 0, firstMessageNode, false)
  ];
  return new Node('This is General Sharpe.', firstOptions, false);
}
