import { Entity, IEntity } from '@IntrinsicSoftware/sim-ecs';
import { build as builder } from '@/ecs/module/Module';
import { definition, GIANT_VOICE } from './definition';
import { useModule } from '../../module/useModule';
import { useChecklist } from '../../completable/Completable';
import { SelectableChecklist } from '../../types';
import { ref } from 'vue';
import { WGCC } from '../../phone/fogPrefab';
import { PhoneLineComposable } from '../../phone/PhoneLine';
import Timer from '../../timer/main';
import { ModuleCategory } from '../../database';

const entityIds = {
  module: '#SignificantWX',
  checklists: ['1001', '1004', '1005', '1006', '1007', '1008'],
  questChecklist: '1011',
  secondaryObjectiveChecklist: '#SecondaryObjectives',
  baseCommander: WGCC,
  baseSiren: '1009',
  giantVoice: GIANT_VOICE,
  radios: ['1045', '1046', '1047']
};

export async function build(category: ModuleCategory) {
  return builder(definition, entityIds, category);
}

function callLine(lines: PhoneLineComposable[], target: string) {
  lines
    .filter((e) => e.entityId === target)
    .forEach((line) => {
      line.call();
    });
}

const createSelectableChecklist = (
  checkList: IEntity,
  setSelectedIndex = 0
) => {
  const checklistComposable = useChecklist(checkList);
  const selectableChecklist: SelectableChecklist = {
    composable: checklistComposable,
    selected: ref(false),
    setSelected: (value = true) => {
      checklistComposable.children[setSelectedIndex].setComplete(value);
    }
  };
  return selectableChecklist;
};

const setTimer = (timedEntity: IEntity, seconds: number) => {
  const timer = new Timer(seconds * 1000, new Date().getTime(), false);
  timedEntity.addComponent(timer);
};

export function buildComposable(e: Awaited<ReturnType<typeof build>>) {
  const result = useModule(
    e.entity as Entity,
    e.worldProxy,
    createSelectableChecklist(e.questChecklist),
    createSelectableChecklist(e.secondaryObjectiveChecklist),
    e.checklists,
    e.baseSiren,
    e.giantVoice,
    e.fogPhoneLines,
    e.radios
  );
  result.onAcknowledge(() => {
    callLine(result.radioLines, '1046');
    callLine([result.giantVoice], GIANT_VOICE);
    const selectQrcEntity = e.worldProxy.getEntity('1012');
    setTimer(selectQrcEntity, 20);
  });
  return result;
}
