import {
  Node,
  Branch,
  SlipperyStairNode,
  FALLBACK_DIALOG,
  RadioBranch
} from '@/ecs/dialog/component';
import { RadioBroadcastPayload } from '../../../../models/BuildableDialogItem';

export function build() {
  const HIDDEN = false;
  const goalMessage = {
    dialog: '',
    edges: [],
    goalNode: true
  };

  const goalMessageNode = new Node(
    goalMessage.dialog,
    goalMessage.edges,
    goalMessage.goalNode,
    'Radio'
  );

  const correctRadioBroadcast: RadioBroadcastPayload = {
    message:
      'All aircraft in the local area this is Raymond 30 with a tornado warning contact tower or ATC for further instructions',
    repeats: 2,
    frequency: 'Flightline'
  };

  const firstOptions: Branch[] = [
    new Branch(FALLBACK_DIALOG, 0, goalMessageNode, false, HIDDEN),
    new RadioBranch(
      JSON.stringify(correctRadioBroadcast),
      100,
      goalMessageNode,
      false,
      HIDDEN
    ),
    new Branch('for further instructions', 0, goalMessageNode, false),
    new Branch('contact tower or ATC', 0, goalMessageNode, false),
    new Branch('for more information', 0, goalMessageNode, false),
    new Branch('a tornado warning', 0, goalMessageNode, false),
    new Branch('All aircraft in the local area', 0, goalMessageNode, false),
    new Branch('this is Raymond 30 with', 0, goalMessageNode, false),
    new Branch('..that is all.', 0, goalMessageNode, false)
  ];

  const root = {
    dialog: '',
    edges: firstOptions,
    goalNode: false
  };
  return new SlipperyStairNode(root.dialog, root.edges, root.goalNode, 'Radio');
}
