import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goal = {
    dialog: '',
    edges: [],
    goalNode: true
  };

  const goalNode = new Node(goal.dialog, goal.edges, goal.goalNode);

  const fourthOptions: Branch[] = [
    new Branch("Yes Ma'am, have a good day", 100, goalNode, false)
  ];

  const thirdMessage = {
    dialog:
      'Ok, ok, but if those boys keep waking up my Dukie, I am going to have to call you back!',
    edges: fourthOptions,
    goalNode: false
  };

  const thirdMessageNode = new Node(
    thirdMessage.dialog,
    thirdMessage.edges,
    thirdMessage.goalNode
  );

  const thirdOptions: Branch[] = [
    new Branch(
      "Yes Ma'am, you can reach Public Affairs at 555-3254, just tell them what you told me and they will help you out.",
      100,
      thirdMessageNode,
      false
    )
  ];

  const secondMessage = {
    dialog:
      "Well, my grandson is in the Air Force, and he flies, and he doesn't go anywhere without his little walkie-talkie, so could you just call him on that and tell him to fly over Judy's house? Because she deserves it.",
    edges: thirdOptions,
    goalNode: false
  };

  const secondMessageNode = new Node(
    secondMessage.dialog,
    secondMessage.edges,
    secondMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch(
      "I am sorry to hear that Ma'am, let me give you the phone number to Public Affairs and they can get this all sorted out for you.",
      100,
      secondMessageNode,
      false
    )
  ];

  const firstMessage = {
    dialog:
      'Yes, Hello, this is Irma Jenkins, my little Dukie is trying to sleep but your jets keep flying right over my house and waking him up!',
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  return new Node(
    '',
    [
      new Branch('Command Post, Amn Haas speaking', 100, firstMessageNode, true)
    ],
    false
  );
}
