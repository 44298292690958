import { Transform } from 'class-transformer';

const stringToDate = Transform(({ value }) => new Date(value), {
  toClassOnly: true
});
const dateToString = Transform(({ value }) => value.toISOString(), {
  toPlainOnly: true
});

export const DateTransform: PropertyDecorator = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  target: Object,
  propertyKey: string | symbol
) => {
  dateToString(target, propertyKey);
  stringToDate(target, propertyKey);
};
