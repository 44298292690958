import {
  createSystem,
  queryComponents,
  Read,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { Completable, CompletionQuality } from '../completable/Completable';
import { CompleteCommand } from '@/ecs/completable/Completable';
import { Outbox, OutboxSynchronizationComponent } from './Outbox';

export const OutboxComparisonSystem = async () => {
  const { Outbox } = await import('./Outbox');
  return createSystem({
    query: queryComponents({
      completable: Write(Completable),
      completeCommand: Write(CompleteCommand),
      outbox: Write(Outbox)
    })
  })
    .withName('OutboxComparisonSystem')
    .withRunFunction(({ query }) => {
      for (const { completable, completeCommand, outbox } of query.iter()) {
        if (outbox.stream.length) {
          const expectedMessage = outbox.expectedMessage;
          const { payload, messageType } = outbox.stream[0];
          if (
            messageType === 'Media Session' &&
            payload !== JSON.stringify(expectedMessage)
          ) {
            completable.completionQuality.state =
              CompletionQuality.IncorrectResponse;
          }
          outbox.stream = [];
          completeCommand.entries.push(new CompleteCommand());
        }
      }
    })
    .build();
};

export const OutboxViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      outboxComponent: Read(Outbox),
      viewModel: Write(OutboxSynchronizationComponent)
    })
  })
    .withName('OutboxViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { outboxComponent, viewModel } of query.iter()) {
        viewModel.shallowReference.value = {
          stream: outboxComponent.stream,
          expectedMessage: outboxComponent.expectedMessage
        };
      }
    })
    .build();
};
