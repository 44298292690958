import {
  Brain,
  Context,
  getDialogFromConversation,
  noop as noop
} from './Brain';
import { MessageBroadcast } from '../../models/BuildableDialogItem';
import { ConversationState } from '../conversation/Conversation';

export function LinearDialogBrain(context: Context, brain: Brain) {
  const sense = () => {
    context.incomingMessage = context.inbox.stream.pop();
    return context;
  };
  const evaluate = () => {
    if (!context.incomingMessage) {
      return context;
    }
    context.conversation.state = ConversationState.ACTIVE;
    const dialog = getDialogFromConversation(context);
    dialog.evaluate(context.incomingMessage);
    context.dialog = dialog;
    return context;
  };
  const act = () => {
    brain.thinking = true;
    if (context.incomingMessage?.messageType === 'INVITE') {
      context.inbox.stream.length = 0;
      context.outbox.stream.push({ messageType: '200 OK', payload: 'ok' });
    } else {
      setTimeout(() => {
        context.conversation.state = ConversationState.WAITING;
        const message: MessageBroadcast<string> = {
          payload: context.dialog.getRoot().dialog,
          messageType: 'Media Session'
        };
        context.outbox.stream.push(message);
        brain.history = context.dialog.getHistory();
        brain.options = context.dialog.getRoot().edges;
        brain.controlType = context.dialog.getRoot().type;
        brain.thinking = false;
      }, context.dialog.getRoot().cost);
    }
  };
  return { sense, evaluate, act, exit: noop };
}
