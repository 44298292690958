import firebase from 'firebase/app';
import 'firebase/analytics';
import { UserRole } from '../models/UserRoles';
import { buildConfig } from '../config';

let analytics: firebase.analytics.Analytics | null = null;

const config = buildConfig(process.env);
if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

// Temp hack to avoid test issues with instantiating firebase analytics
try {
  analytics = firebase.analytics();
} catch (error) {
  error;
}

export class FirebaseAnalyticsService {
  private analytics: firebase.analytics.Analytics;

  constructor() {
    this.analytics = analytics as firebase.analytics.Analytics;
  }

  public logPageViewEvent(payload: { userId: string; page_path: string }) {
    this.analytics.logEvent('page_view', {
      user_id: payload.userId,
      page_path: payload.page_path
    });
  }

  public logLoginEvent(payload: { userId: string }) {
    this.analytics.logEvent('login', {
      user_id: payload.userId
    });
  }

  public logRegisterUserEvent(payload: {
    userId: string;
    role: UserRole;
    inviteCode: string;
    type: 'attempt' | 'success' | 'failure';
  }) {
    this.analytics.logEvent('register_user', {
      user_id: payload.userId,
      role: payload.role,
      invite_code: payload.inviteCode,
      type: payload.type
    });
  }

  public logCreateClassEvent(payload: {
    classId: string;
    organizationId: string;
    courseId: string;
    userId: string;
  }) {
    this.analytics.logEvent('create_class', {
      class_id: payload.classId,
      organization_id: payload.organizationId,
      course_id: payload.courseId,
      user_id: payload.userId
    });
  }

  public logExpandInstructorFeedbackEvent(payload: { userId: string }) {
    this.analytics.logEvent('expand_instructor_feedback', {
      user_id: payload.userId
    });
  }

  public logViewAchievementEvent(payload: {
    userId: string;
    achievementId: string;
  }) {
    this.analytics.logEvent('view_achievement', {
      user_id: payload.userId,
      achievement_id: payload.achievementId
    });
  }
}
