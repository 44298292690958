import { Entity, IEntity } from '@IntrinsicSoftware/sim-ecs';
import { build as builder } from '@/ecs/module/Module';
import { definition, FLIGHTLINE_RADIO_ID, GIANT_VOICE } from './definition';
import { useModule } from '../../module/useModule';
import { useChecklist } from '../../completable/Completable';
import { SelectableChecklist } from '../../types';
import { ref } from 'vue';
import { PhoneLineComposable } from '../../phone/PhoneLine';
import { WGCC } from '../../phone/fogPrefab';
import { ModuleCategory } from '../../database';

const entityIds = {
  module: '#RadioCheck',
  checklists: [],
  questChecklist: '1011',
  secondaryObjectiveChecklist: '#SecondaryObjectives',
  baseCommander: WGCC,
  baseSiren: '1009',
  giantVoice: GIANT_VOICE,
  radios: ['1045', '1046', '1047', FLIGHTLINE_RADIO_ID, '#FlightlineTarget']
};

export async function build(category: ModuleCategory) {
  return builder(definition, entityIds, category);
}

function call(radioLines: PhoneLineComposable[]) {
  const target = '#FlightlineTarget';
  radioLines
    .filter((e) => e.entityId === target)
    .forEach((line) => {
      line.call();
    });
}

const createSelectableChecklist = (checkList: IEntity) => {
  const checklistComposable = useChecklist(checkList);
  const selectableChecklist: SelectableChecklist = {
    composable: checklistComposable,
    selected: ref(false),
    setSelected: (value = true) => {
      selectableChecklist.selected.value = value;
    }
  };
  return selectableChecklist;
};

export function buildComposable(e: Awaited<ReturnType<typeof build>>) {
  const result = useModule(
    e.entity as Entity,
    e.worldProxy,
    createSelectableChecklist(e.questChecklist),
    createSelectableChecklist(e.secondaryObjectiveChecklist),
    e.checklists,
    e.baseSiren,
    e.giantVoice,
    e.fogPhoneLines,
    e.radios
  );
  call(result.radioLines);
  result.acknowledge();
  result.secondaryObjectiveChecklist.setSelected(false);

  return result;
}
