import { Entity, Reference } from '@IntrinsicSoftware/sim-ecs';
import { EReferenceType } from '@IntrinsicSoftware/sim-ecs/dist/serde/referencing.spec';

export const serializeObjectReplacer = function (
  key: string,
  value: unknown
): string | unknown {
  return value instanceof Entity
    ? new Reference(EReferenceType.Entity, value.id).toString()
    : value;
};
