import FirestoreService, { Score } from '@/services/FirestoreService';
import { Achievement, AchievementId } from '../models/Achievement';
import { Class } from '../models/Class';
import { Course } from '../models/Course';
import { Invite } from '../models/Invite';
import Message from '../models/Message';
import { Organization } from '../models/Organization';
import { User } from '../models/User';
import { UserRole } from '../models/UserRoles';
import { ApiError, ApiRetry } from './plugins/ApiRetry';
import { ScenarioSerializedThing } from '../ecs/types';
import { ModuleCategory } from '../ecs/database';

export class Api {
  private static instance: Api;
  private firestoreService: FirestoreService;
  private apiRetry: ApiRetry;

  constructor(firestoreService: FirestoreService, apiRetry: ApiRetry) {
    this.firestoreService = firestoreService;
    this.apiRetry = apiRetry;
  }

  public static getInstance(
    firestoreService?: FirestoreService,
    apiRetry?: ApiRetry
  ): Api {
    if (!Api.instance) {
      if (!firestoreService) {
        throw new Error('FirestoreService is not defined');
      }
      if (!apiRetry) {
        throw new Error('ApiRetry is not defined');
      }
      Api.instance = new Api(firestoreService, apiRetry);
    }
    return Api.instance;
  }

  // Class
  public async fetchClasses(
    organizationId: string
  ): Promise<Class[] | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.fetchClasses(organizationId);
    });
  }
  public async fetchClass(
    organizationId: string,
    classId: string
  ): Promise<Class | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.fetchClass(organizationId, classId);
    });
  }
  public async updateClass(
    organizationId: string,
    classId: string,
    updatedClass: Class
  ): Promise<Class | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.updateClass(
        organizationId,
        classId,
        updatedClass
      );
    });
  }
  public async createClass(
    organizationId: string,
    classObject: Class
  ): Promise<Class | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.createClass(organizationId, classObject);
    });
  }
  public async deleteClass(
    organizationId: string,
    classId: string
  ): Promise<void | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.deleteClass(organizationId, classId);
    });
  }

  // Course
  public async fetchCourses(
    organizationId: string
  ): Promise<Course[] | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.fetchCourses(organizationId);
    });
  }
  public async fetchCourse(
    organizationId: string,
    courseId: string
  ): Promise<Course | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.fetchCourse(organizationId, courseId);
    });
  }
  public async updateCourse(
    organizationId: string,
    updatedCourse: Course
  ): Promise<Course | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.updateCourse(organizationId, updatedCourse);
    });
  }

  // Invite
  async createInvite(
    invite: Invite,
    role: UserRole
  ): Promise<Invite | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.createInvite(invite, role);
    });
  }
  async fetchInvite(
    inviteId: string,
    role: string
  ): Promise<Invite | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.fetchInvite(inviteId, role);
    });
  }
  async updateInvite(invite: Invite, role: string): Promise<Invite | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.updateInvite(invite, role);
    });
  }

  // User
  async fetchUsers(organizationId: string): Promise<User[] | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.fetchUsers(organizationId);
    });
  }
  async fetchUser(userId: string): Promise<User | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.fetchUser(userId);
    });
  }
  async createUser(user: User): Promise<User | ApiError> {
    return await this.apiRetry.retryOnError(async () => {
      const createUserQuery = await this.firestoreService.createUser(user);
      if (createUserQuery instanceof ApiError) {
        throw createUserQuery;
      }
      return createUserQuery;
    });
  }
  async updateUser(user: User): Promise<User | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.updateUser(user);
    });
  }

  // Message
  async createMessage(message: Message): Promise<Message | ApiError> {
    return this.apiRetry.retryOnError(() =>
      this.firestoreService.createMessage(message)
    );
  }
  async fetchSortedMessages(classId: string): Promise<Message[] | ApiError> {
    return this.apiRetry.retryOnError(() =>
      this.firestoreService.fetchSortedMessages(classId)
    );
  }

  // Fully Qualified Calls
  async createClassFullyQualified(
    classData: Class,
    organizationId: string,
    courseId: string
  ): Promise<Class | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.createClassFullyQualified(
        classData,
        organizationId,
        courseId
      );
    });
  }
  async createUserFullyQualified(
    userData: User,
    inviteId: string
  ): Promise<User | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.createUserFullyQualified(userData, inviteId);
    });
  }

  public async generateScenarioHistory(
    scenarioSerialization: ScenarioSerializedThing
  ) {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.generateScenarioHistory(
        scenarioSerialization
      );
    });
  }

  public async getScenarioHistory() {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.getScenarioHistory();
    });
  }

  public async addPoints(
    userId: string,
    points: number,
    category: ModuleCategory
  ): Promise<void | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.addPoints(userId, points, category);
    });
  }

  public async userScore(userId: string): Promise<Score | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.userScore(userId);
    });
  }

  public async fetchOrganization(
    organizationId: string
  ): Promise<Organization | ApiError> {
    return await this.apiRetry.retryOnError(() => {
      return this.firestoreService.fetchOrganization(organizationId);
    });
  }

  public async addAchievement(userId: string, achievementId: AchievementId) {
    return this.apiRetry.retryOnError(() => {
      return this.firestoreService.addAchievement(userId, achievementId);
    });
  }

  public async fetchAchievements(
    userId: string
  ): Promise<Achievement[] | ApiError> {
    return this.apiRetry.retryOnError(() => {
      return this.firestoreService.fetchAchievements(userId);
    });
  }
}
