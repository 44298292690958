import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goalMessage = {
    dialog: 'Copy, we will move to lockdown ops, Out',
    edges: [],
    goalNode: true
  };

  const goalMessageNode = new Node(
    goalMessage.dialog,
    goalMessage.edges,
    goalMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch(
      'Sir, two masked gunmen have barricaded themselves in Green Beans Coffee Shop, and are holding the three employees hostage. No other information is available at this time.',
      100,
      goalMessageNode,
      false
    ),
    new Branch(
      'Command Post received a report of an Active Shooter in Green Beans Coffee Shop; there are three hostages.',
      75,
      goalMessageNode,
      false
    ),
    new Branch(
      'Active Shooter at Green Beans Coffee Shop.',
      50,
      goalMessageNode,
      false
    ),
    new Branch(
      'Masked gunmen have holed up at the Coffee Shop, with hostages!',
      0,
      goalMessageNode,
      false
    )
  ];

  const firstMessage = {
    dialog: 'Go ahead',
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch(
      'Sir, this is Command Post with an Active Shooter Situation.',
      100,
      firstMessageNode,
      false
    ),
    new Branch(
      'This is Command Post with an Active Shooter Situation.',
      75,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, this is Command Post reporting a shooting.',
      50,
      firstMessageNode,
      false
    ),
    new Branch('There is a gunman at the BX, Sir.', 0, firstMessageNode, false)
  ];

  const root = {
    dialog: 'This is Capt Hoang',
    edges: firstOptions,
    goalNode: false
  };
  return new Node(root.dialog, root.edges, root.goalNode);
}
