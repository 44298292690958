export class MessageNode {
  constructor(
    public speaker: string = '',
    public dialog: string = '',
    public options: Map<string, Option> = new Map(),
    public correctOptionId: string = ''
  ) {}
}

export type Option = { id: string; text: string; nextMessage?: MessageNode };

const initialDialogTree: MessageNode = {
  speaker: '',
  dialog: '',
  options: new Map(),
  correctOptionId: ''
};

export class DialogFlow {
  constructor(
    public url: string,
    public context = {},
    public dialogTree: MessageNode = initialDialogTree
  ) {}
}
