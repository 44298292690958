import { ShallowRef, shallowRef } from 'vue';
import { MessageBroadcast } from '../../models/BuildableDialogItem';
import { IEntity } from '@IntrinsicSoftware/sim-ecs';
import { ComponentException } from '../types';

export class Inbox {
  constructor(public stream: MessageBroadcast<string>[] = []) {}
}

export interface InboxViewModel {
  stream: MessageBroadcast<string>[];
}

export class InboxSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<InboxViewModel> = shallowRef({
      stream: []
    })
  ) {}
}

export interface InboxComposable {
  inbox: ShallowRef<InboxViewModel>;
  clear: () => void;
}

export const useInbox = (entity: IEntity): InboxComposable => {
  const inboxComponent = entity.getComponent(Inbox);
  if (!inboxComponent) {
    throw new ComponentException(entity.id, 'Inbox component not found');
  }

  const inboxSynchronizationComponent = entity.getComponent(
    InboxSynchronizationComponent
  );
  if (inboxSynchronizationComponent) {
    return {
      inbox: inboxSynchronizationComponent.shallowReference,
      clear: () => {
        inboxComponent.stream.length = 0;
      }
    };
  }

  const ref = shallowRef<InboxViewModel>({ stream: inboxComponent.stream });
  const synchronizationComponent = new InboxSynchronizationComponent(ref);
  entity.addComponent(synchronizationComponent);
  return {
    inbox: ref,
    clear: () => {
      inboxComponent.stream.length = 0;
    }
  };
};
