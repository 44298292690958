import { Subject } from 'rxjs';
import { Inbox } from '../inbox/component';
import { Node } from '../dialog/component';
import { build as buildWgcc } from '../modules/weather/dialog/wingCommanderDialog';
import { build as buildBDOC } from '../modules/activeShooter/bdocDialog';
import {
  ConversationId,
  Dialog,
  EstablishedConversation,
  Conversation,
  ConversationType
} from './Conversation';
import { build as buildMoc } from '../modules/weather/dialog/mocDialog';
import { build as buildTa } from '../modules/weather/dialog/taDialog';
import { build as buildCe } from '../modules/weather/dialog/ceDialog';
import { build as buildOgCc } from '../modules/weather/dialog/ogCcDialog';
import { build as buildMsgCc } from '../modules/weather/dialog/msgCcDialog';
import { build as buildPrimaryCrashNet } from '../modules/weather/dialog/primaryCrashNetIncomingDialog';
import { build as buildMsgCcActiveShooter } from '../modules/activeShooter/dialog/msgCcDialog';
import { build as buildTowerHotline } from '../modules/activeShooter/dialog/towerHotlineDialog';
import { build as buildWgCcActiveShooter } from '../modules/activeShooter/dialog/wgCcDialog';
import { build as buildUHFActiveShooter } from '../modules/activeShooter/dialog/uhfDialog';
import { build as buildUHFSignificantWeather } from '../modules/weather/dialog/uhfDialog';
import { build as buildNoiseComplaint } from '../modules/noiseComplaint/dialog';
import { build as buildRadioCheck } from '../modules/radioCheck/dialog';
import { build as buildMdgCc } from '../modules/activeShooter/dialog/mdgCcDialog';
import { build as buildAlertFacility } from '../modules/activeShooter/dialog/alertFacilityDialog';
import { build as buildGiantVoiceActiveShooter } from '../modules/activeShooter/dialog/giantVoiceDialog';
import { build as buildGiantVoiceSignificantWeather } from '../modules/weather/dialog/giantVoiceDialog';
import { MessageBroadcast } from '../../models/BuildableDialogItem';

export class ConversationSingleton {
  conversationIdDialogMap = new Map<ConversationId, Dialog>();
  targetIdSubjectMap = new Map<string, Subject<MessageBroadcast<string>>>();
  entityConversationsMap = new Map<string, EstablishedConversation[]>();
  entityTargetMap = new Map<string, string>();

  getSubjectOrDefault(target: string) {
    const defaultSubject =
      this.targetIdSubjectMap.get(target) ??
      new Subject<MessageBroadcast<string>>();
    return defaultSubject;
  }

  private generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  createTargetSubject(target: string) {
    this.targetIdSubjectMap.set(target, this.getSubjectOrDefault(target));
  }

  subscribeToTargetSubject(target: string, inbox: Inbox) {
    this.getSubjectOrDefault(target).subscribe((broadcast) => {
      inbox.stream.push(broadcast);
    });
  }

  createConversationMapping(
    senderId: string,
    senderConversation: Conversation
  ) {
    const conversationId = this.generateGuid();
    this.entityConversationsMap.set(senderId, [
      {
        targetId: senderConversation.target,
        conversationId: conversationId
      }
    ]);
    this.entityConversationsMap.set(senderConversation.target, [
      {
        targetId: senderId,
        conversationId: conversationId
      }
    ]);
    return conversationId;
  }

  createDialogTreeMapping(
    conversationId: string,
    dialogTreeReference: ConversationType
  ) {
    switch (dialogTreeReference) {
      case ConversationType.WG_CC:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildWgcc())
        );
        break;
      case ConversationType.MOC:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildMoc())
        );
        break;
      case ConversationType.TA:
        this.conversationIdDialogMap.set(conversationId, new Dialog(buildTa()));
        break;
      case ConversationType.CE:
        this.conversationIdDialogMap.set(conversationId, new Dialog(buildCe()));
        break;
      case ConversationType.OG_CC:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildOgCc())
        );
        break;
      case ConversationType.MSG_CC:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildMsgCc())
        );
        break;
      case ConversationType.PRIMARY_CRASH_NET:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildPrimaryCrashNet())
        );
        break;
      case ConversationType.BDOC:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildBDOC())
        );
        break;
      case ConversationType.TOWER_HOTLINE:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildTowerHotline())
        );
        break;
      case ConversationType.UHF_ACTIVE_SHOOTER:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildUHFActiveShooter())
        );
        break;
      case ConversationType.UHF_SIGNIFICANT_WEATHER:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildUHFSignificantWeather())
        );
        break;
      case ConversationType.NOISE_COMPLAINT:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildNoiseComplaint())
        );
        break;
      case ConversationType.RADIO_CHECK:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildRadioCheck())
        );
        break;
      case ConversationType.WG_CC_ACTIVE_SHOOTER:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildWgCcActiveShooter())
        );
        break;
      case ConversationType.MSG_CC_ACTIVE_SHOOTER:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildMsgCcActiveShooter())
        );
        break;
      case ConversationType.MGD_CC:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildMdgCc())
        );
        break;
      case ConversationType.ALERT_FACILITY:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildAlertFacility())
        );
        break;
      case ConversationType.GV_ACTIVE_SHOOTER:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildGiantVoiceActiveShooter())
        );
        break;
      case ConversationType.GV_SIGNIFICANT_WEATHER:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(buildGiantVoiceSignificantWeather())
        );
        break;
      case ConversationType.NONE:
        this.conversationIdDialogMap.set(
          conversationId,
          new Dialog(new Node('No one is home'))
        );
        break;
    }
  }

  create(conversation: Conversation) {
    switch (conversation.transport.type) {
      case 'entity':
        this.createTargetSubject(conversation.target);
        break;
      default:
        break;
    }
  }

  update(senderId: string, message: MessageBroadcast<string>) {
    const sender = this.targetIdSubjectMap.get(senderId);
    if (sender) {
      sender.next(message);
    }
    return message;
  }
}
