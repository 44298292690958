import { Module } from './module/Module';
import { ModuleComposable } from './module/useModule';
import {
  build as buildSevereWx,
  buildComposable as buildSevereWxComposable
} from './modules/weather/moduleOne';
import {
  build as buildActiveShooter,
  buildComposable as buildActiveShooterComposable
} from './modules/activeShooter/activeShooter';
import {
  build as buildFillerOne,
  buildComposable as buildFillerOneComposable
} from './modules/fillerOne/fillerOne';
import {
  build as buildNoiseComplaint,
  buildComposable as buildNoiseComplaintComposable
} from './modules/noiseComplaint/noiseComplaint';
import {
  build as buildRadioCheck,
  buildComposable as buildRadioCheckComposable
} from './modules/radioCheck/radioCheck';
import { useScenario } from './scenario/useScenario';
import { Scenario } from './types';

// module categories
export enum ModuleCategory {
  WEATHER = 'Weather',
  EMERGENCY = 'Emergency',
  FILLER = 'Filler',
  SECURITY = 'Security',
  AIRCRAFT = 'Aircraft',
  UNKNOWN = 'Unknown'
}

export enum ScenarioIds {
  SCENARIO_ONE = '1',
  SCENARIO_TWO = '2',
  SCENARIO_ONE_RANDOM = '3',
  SCENARIO_TWO_RANDOM = '4'
}

export enum ModuleIds {
  SIGNIFICANT_WX = 'Significant Weather',
  ACTIVE_SHOOTER = 'Active Shooter',
  FILLER_ONE = 'Filler One',
  NOISE_COMPLAINT = 'Noise Complaint',
  RADIO_CHECK = 'Radio Check'
}

const fillerModules = [
  ModuleIds.FILLER_ONE,
  ModuleIds.NOISE_COMPLAINT,
  ModuleIds.RADIO_CHECK
];

// module category map
export const moduleCategoryMap: Map<ModuleIds, ModuleCategory> = new Map([
  [ModuleIds.SIGNIFICANT_WX, ModuleCategory.WEATHER],
  [ModuleIds.ACTIVE_SHOOTER, ModuleCategory.EMERGENCY],
  [ModuleIds.FILLER_ONE, ModuleCategory.EMERGENCY],
  [ModuleIds.NOISE_COMPLAINT, ModuleCategory.SECURITY],
  [ModuleIds.RADIO_CHECK, ModuleCategory.AIRCRAFT]
]);

export function getScenarioById(scenarioId: ScenarioIds) {
  return scenarioBuilder(scenarioId);
}

export interface WorldBuilder {
  moduleBuilder: (category: ModuleCategory) => Promise<Module>;
  composer: (e: Module) => ModuleComposable;
  category: ModuleCategory;
}

const scenarioBuilder = async (scenarioId: ScenarioIds): Promise<Scenario> => {
  const worlds = await fetchWorlds(scenarioId);
  worlds.forEach(function startWorld({ module }) {
    module.start();
  });
  const modules = worlds.map(({ module, composer }) => {
    return composer(module).onComplete(module.cleanup);
  });
  const cleanup = () => {
    worlds.forEach(({ module }) => module.cleanup());
  };
  const scenarioComposable = useScenario(modules);
  const scenario: Scenario = {
    scenarioState: scenarioComposable,
    cleanup,
    onCollectiblePointCreated: (event) => {
      worlds.forEach(function notifyWorld({ module }) {
        module.onCollectiblePointCreated(event);
      });
    },
    id: scenarioId
  };
  return scenario;
};

export const fetchWorlds = async (scenarioId: ScenarioIds) => {
  const scenarioModules = scenarioDatabaseMap.get(scenarioId);
  if (!scenarioModules) {
    throw '404 Modules not found';
  }
  return Promise.all(
    scenarioModules.map(async (moduleId) => {
      const worldBuilder = modules[moduleId];
      return {
        module: await worldBuilder.moduleBuilder(worldBuilder.category),
        composer: worldBuilder.composer
      };
    })
  );
};

export const modules: Record<ModuleIds, WorldBuilder> = {
  'Significant Weather': {
    moduleBuilder: buildSevereWx,
    composer: buildSevereWxComposable,
    category:
      moduleCategoryMap.get(ModuleIds.SIGNIFICANT_WX) ?? ModuleCategory.UNKNOWN
  },
  'Active Shooter': {
    moduleBuilder: buildActiveShooter,
    composer: buildActiveShooterComposable,
    category:
      moduleCategoryMap.get(ModuleIds.ACTIVE_SHOOTER) ?? ModuleCategory.UNKNOWN
  },
  'Filler One': {
    moduleBuilder: buildFillerOne,
    composer: buildFillerOneComposable,
    category:
      moduleCategoryMap.get(ModuleIds.FILLER_ONE) ?? ModuleCategory.UNKNOWN
  },
  'Noise Complaint': {
    moduleBuilder: buildNoiseComplaint,
    composer: buildNoiseComplaintComposable,
    category:
      moduleCategoryMap.get(ModuleIds.NOISE_COMPLAINT) ?? ModuleCategory.UNKNOWN
  },
  'Radio Check': {
    moduleBuilder: buildRadioCheck,
    composer: buildRadioCheckComposable,
    category:
      moduleCategoryMap.get(ModuleIds.RADIO_CHECK) ?? ModuleCategory.UNKNOWN
  }
};

function pluckTwoUnique(candidates: ModuleIds[]) {
  const SPACE = 2;

  if (candidates.length < SPACE) {
    throw new Error('Not enough candidates to bookend. Must be at least 2.');
  }

  const randomNumbers = new Set<number>();
  while (randomNumbers.size < SPACE) {
    randomNumbers.add(Math.floor(Math.random() * candidates.length));
  }
  const unique = [];
  for (const random of randomNumbers) {
    const candidate = candidates[random];
    unique.push(candidate);
  }
  return unique;
}

function bookend(shelf: ModuleIds, candidates: ModuleIds[]) {
  const newShelf = pluckTwoUnique(candidates);
  const middle = Math.floor(newShelf.length / 2);
  newShelf.splice(middle, 0, shelf);
  return newShelf;
}

export function getCategoryForModule(moduleId: ModuleIds) {
  return moduleCategoryMap.get(moduleId) ?? ModuleCategory.UNKNOWN;
}

export const scenarioDatabaseMap: Map<ScenarioIds, ModuleIds[]> = new Map([
  [ScenarioIds.SCENARIO_ONE, [ModuleIds.SIGNIFICANT_WX]],
  [ScenarioIds.SCENARIO_TWO, [ModuleIds.ACTIVE_SHOOTER]],
  [
    ScenarioIds.SCENARIO_ONE_RANDOM,
    bookend(ModuleIds.SIGNIFICANT_WX, fillerModules)
  ],
  [
    ScenarioIds.SCENARIO_TWO_RANDOM,
    bookend(ModuleIds.ACTIVE_SHOOTER, fillerModules)
  ]
]);
