import { BuildableDialogItem } from '../../../../models/BuildableDialogItem';
import { CommandType } from './type';

export function redo(
  builtDialogMessage: BuildableDialogItem[],
  commandStack: CommandType[],
  inverseCommandStack: CommandType[]
) {
  const command = inverseCommandStack.pop();
  if (command) {
    switch (command.type) {
      case 'add':
        builtDialogMessage.push(command.items[0]);
        break;
      case 'remove':
        builtDialogMessage.splice(command.index, 1);
        break;
      case 'clear':
        builtDialogMessage = builtDialogMessage.splice(
          0,
          builtDialogMessage.length
        );
        break;
    }
    commandStack.push(command);
  }
}
