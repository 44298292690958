import {
  Node,
  Branch,
  ControlType,
  SlipperyStairNode,
  FALLBACK_DIALOG
} from '@/ecs/dialog/component';

export function build() {
  const thinkingCost = 0;

  const badGoal = {
    dialog: '',
    edges: [],
    goalNode: true
  };

  const badGoalNode = new Node(badGoal.dialog, badGoal.edges, badGoal.goalNode);

  const finalOptions: Branch[] = [
    new Branch('Acknowledged.', 0, badGoalNode, false),
    new Branch('This is TSgt Harris from Command Post.', 0, badGoalNode, false),
    new Branch('Thank you.', 0, badGoalNode, false),
    new Branch('Over and out.', 0, badGoalNode, false)
  ];

  const goal = {
    dialog: '',
    edges: finalOptions,
    goalNode: true
  };

  const goalNode = new Node(goal.dialog, goal.edges, goal.goalNode);
  const secondOptions: Branch[] = [
    new Branch(FALLBACK_DIALOG, -1, goalNode, true, false),
    new Branch('Acknowledged.', 0, goalNode, false),
    new Branch('This is TSgt Harris from Command Post.', 0, goalNode, false),
    new Branch('Thank you.', 0, goalNode, false),
    new Branch('Over and out.', 0, goalNode, false)
  ];

  const firstMessage = {
    dialog: 'Test complete. Out.',
    edges: secondOptions,
    goalNode: false,
    type: <ControlType>'Hushline'
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode,
    firstMessage.type
  );

  const firstOptions: Branch[] = [
    new Branch(FALLBACK_DIALOG, -1, firstMessageNode, false, false),
    new Branch('Acknowledged.', 0, firstMessageNode, false),
    new Branch(
      'This is TSgt Harris from Command Post.',
      0,
      firstMessageNode,
      false
    ),
    new Branch('Thank you.', 0, firstMessageNode, false),
    new Branch('Over and out.', 0, firstMessageNode, false)
  ];

  const root = {
    dialog:
      'This is the weekly test of the secondary crash net. I say again, This is the daily test of the secondary crash net. Report any issues to comm squadron immediately.',
    edges: firstOptions,
    goalNode: false,
    type: <ControlType>'Hushline',
    cost: thinkingCost
  };
  return new SlipperyStairNode(
    root.dialog,
    root.edges,
    root.goalNode,
    root.type,
    root.cost
  );
}
