import {
  Brain,
  Context,
  DelegateFunctions,
  getDialogFromConversation,
  noop
} from './Brain';

export function PlayerBrainFunctions(
  context: Context,
  brain: Brain
): DelegateFunctions {
  const sense = () => {
    const newMessage = context.inbox.stream.pop();
    context.incomingMessage = newMessage;
    return context;
  };
  const evaluate = () => {
    if (!context.incomingMessage) {
      return context;
    }
    const dialog = getDialogFromConversation(context);
    dialog.evaluate(context.incomingMessage);
    context.dialog = dialog;
    return context;
  };
  const act = () => {
    brain.thinking = true;
    brain.options = context.dialog.getRoot().edges;
    brain.controlType = context.dialog.getRoot().type;
    if (context.incomingMessage?.messageType !== 'INVITE') {
      setTimeout(() => {
        brain.history = context.dialog.getHistory();
        brain.thinking = false;
      }, context.dialog.getRoot().cost);
    }
  };
  return { sense, evaluate, act, exit: noop };
}
