/**
 * Schedulers
 *
 * Preemptive: Serving or intended to preempt or forestall something. Run until.
 * Shortest Remaining Time
 * Round Robin
 *
 * Non Preemptive: Not able to be preempted or forestalled. Run to completion.
 *
 * First Come First Serve (FCFS)
 *
 * Shortest Job Next (SJN) aka Shortest Job First
 *
 * Priority: Sort by priority
 *
 */

function addTo(sum: number, b: number) {
  return (sum += b);
}

export abstract class Task {
  abstract start(): Promise<Task>;
  abstract stop: () => void;
}

class TaskStruct<T extends Task> {
  constructor(public sp: T) {}
  state: 'ready' | 'running' | 'blocked' | 'stopped' = 'ready';
  stop() {
    this.state = 'stopped';
    this.sp.stop();
  }
  run() {
    this.state = 'running';
    return this.sp.start();
  }
}

export class OperatingSystem<T extends Task> {
  constructor(public tasks: TaskStruct<T>[], public scheduler: Scheduler<T>) {
    this.tasks = tasks;
    this.scheduler = scheduler;
  }
  static PARENT_INDEX = 0;
  async startTheReactor() {
    await this.scheduler.generate();
  }
  get parent() {
    return this.tasks[OperatingSystem.PARENT_INDEX];
  }
}

type SchedulerType = 'preemptive' | 'non-preemptive';

export class Scheduler<T extends Task> {
  constructor(
    public type: SchedulerType = 'preemptive',
    public tasks: TaskStruct<T>[]
  ) {}
  private onRunListeners: ((t: TaskStruct<T>) => void)[] = [];
  onRun(cb: (t: TaskStruct<T>) => void) {
    this.onRunListeners.push(cb);
  }
  private notifyOnRunListeners(t: TaskStruct<T>) {
    this.onRunListeners.forEach(function notify(cb) {
      cb(t);
    });
  }
  private async *schedule() {
    for await (const task of this.tasks) {
      this.notifyOnRunListeners(task);
      yield task.run();
    }
  }
  public async generate() {
    for await (const task of this.schedule()) {
      task.stop();
    }
  }
}

export function toTaskStruct<T extends Task>(composable: T) {
  return new TaskStruct<T>(composable);
}

export function sum(values: number[]) {
  return values.reduce(addTo, 0);
}
