import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { Class } from '@/models/Class';
import { Course } from '@/models/Course';
import { User } from '@/models/User';
import firebase from 'firebase';
import 'firebase/analytics';
import FirestoreService, { Score } from '@/services/FirestoreService';
import { AuthClient } from '@/services/auth';
import { ApiRetry, ApiError } from '@/api/plugins/ApiRetry';
import { Api } from '@/api/Api';
import { UserRole } from '../models/UserRoles';
import { Invite } from '../models/Invite';
import { FirebaseAnalyticsService } from '../services/FirebaseAnalyticsService';
import { RouteNames } from '../router/Routes';
import Message from '../models/Message';
import { Achievement, AchievementId } from '@/models/Achievement';
import { ModuleCategory } from '../ecs/database';

// TODO: We should be injecting the api and analytics into the store using the pinia plugin.
// https://pinia.vuejs.org/core-concepts/plugins.html#introduction
const api = Api.getInstance(new FirestoreService(), new ApiRetry());
const firebaseAnalytics: FirebaseAnalyticsService =
  new FirebaseAnalyticsService();

export const useGlobalStore = defineStore('global', () => {
  // State
  const firebaseUser = ref<firebase.User | null>(null);
  const currentUser = ref<User | null>(null);
  const instructors = ref<User[]>([]);
  const students = ref<User[]>([]);
  const error = ref<ApiError | null>(null);
  const classes = ref<Class[]>([]);
  const courses = ref<Course[]>([]);
  const currentClass = ref<Class | null>(null);
  const currentCourse = ref<Course | null>(null);
  const messages = ref<Message[]>([]);
  const achievements = ref<Achievement[]>([]);
  const authorizationDetails = ref<string>('');

  // Getters
  const getClasses = computed(() => {
    return classes.value;
  });
  const getCourses = computed(() => {
    return courses.value;
  });
  const getCurrentClass = computed(() => {
    return currentClass.value;
  });
  const getCurrentCourse = computed(() => {
    return currentCourse.value;
  });
  const getCurrentUser = computed(() => {
    return currentUser.value;
  });
  const getFirebaseUser = computed(() => {
    return firebaseUser.value;
  });
  const getInstructors = computed(() => instructors);
  const getError = computed(() => {
    return error.value;
  });
  const getIsLoggedIn = computed(() => {
    return currentUser.value !== null;
  });
  const getIsEmailVerified = computed(() => {
    return firebaseUser.value?.emailVerified ?? false;
  });
  const getAuthorizationDetails = computed(() => {
    return authorizationDetails.value;
  });

  // Actions
  const setAuthorizationDetails = (details: string) => {
    authorizationDetails.value = details;
  };
  const setError = (payload: ApiError) => {
    error.value = payload;
  };
  const fetchClasses = async (organizationId: string) => {
    const query = await api.fetchClasses(organizationId);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      classes.value = query.map((_class) => Class.fromJson(_class));
    }
  };
  const fetchClass = async (organizationId: string, classId: string) => {
    const query = await api.fetchClass(organizationId, classId);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      currentClass.value = Class.fromJson(query);
    }
  };
  const updateClass = async (
    organizationId: string,
    classId: string,
    updatedClass: Class
  ) => {
    const query = await api.updateClass(organizationId, classId, updatedClass);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      currentClass.value = Class.fromJson(query);
    }
  };
  const createClass = async (
    organizationId: string,
    _class: Class
  ): Promise<Class> => {
    const response = await api.createClass(organizationId, _class);
    if (response instanceof ApiError) {
      setError(response);
      return new Class();
    }
    return Class.fromJson(response);
  };
  const fetchCourses = async (organizationId: string) => {
    const query = await api.fetchCourses(organizationId);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      courses.value = query.map((course) => Course.fromJson(course));
    }
  };
  const fetchCourse = async (
    organizationId: string,
    courseId: string
  ): Promise<Course> => {
    const query = await api.fetchCourse(organizationId, courseId);
    if (query instanceof ApiError) {
      setError(query);
      return new Course();
    } else {
      currentCourse.value = Course.fromJson(query);
      return Course.fromJson(query);
    }
  };
  const updateCourse = async (
    organizationId: string,
    updatedCourse: Course
  ) => {
    const query = await api.updateCourse(organizationId, updatedCourse);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      currentCourse.value = Course.fromJson(query);
    }
  };
  const createInvite = async (invite: Invite, role: UserRole) => {
    const query = await api.createInvite(invite, role);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      return Invite.fromJson(query);
    }
  };
  const fetchInvite = async (inviteId: string, role: string) => {
    const query = await api.fetchInvite(inviteId, role);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      return Invite.fromJson(query);
    }
  };
  const updateInvite = async (invite: Invite, role: string) => {
    const query = await api.updateInvite(invite, role);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      return Invite.fromJson(query);
    }
  };
  const fetchUser = async (uid: string) => {
    const query = await api.fetchUser(uid);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      currentUser.value = User.fromJson(query);
    }
  };
  const fetchInstructorUsers = async (organizationId: string) => {
    const query = await api.fetchUsers(organizationId);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      const parsedUsers = query.map((user) => User.fromJson(user));
      instructors.value = parsedUsers.filter(
        (user) => user.role === UserRole.INSTRUCTOR
      );
    }
  };
  const fetchStudentUsers = async (
    organizationId: string,
    courseId: string,
    classId: string
  ) => {
    const query = await api.fetchUsers(organizationId);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      const parsedUsers = query.map((user) => User.fromJson(user));
      const studentUsers = parsedUsers.filter(
        (user) =>
          user.role === UserRole.STUDENT &&
          user.courseId === courseId &&
          user.classId === classId
      );

      const scores = await Promise.all(
        studentUsers.map((user) => {
          return api.userScore(user.uid);
        })
      );

      studentUsers.forEach((user, index) => {
        if (!(scores[index] instanceof ApiError)) {
          user.score = scores[index] as Score;
        }
      });

      students.value = studentUsers;
    }
  };
  const createUser = async (user: User) => {
    const query = await api.createUser(user);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      currentUser.value = User.fromJson(query);
      return User.fromJson(query);
    }
  };
  const updateUser = async (user: User) => {
    const query = await api.updateUser(user);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      currentUser.value = User.fromJson(query);
    }
  };
  const createMessage = async (
    message: Message
  ): Promise<{ resultMessage: string; isError: boolean }> => {
    const response = {
      resultMessage: 'Message sent successfully',
      isError: false
    };
    const query = await api.createMessage(message);
    if (query instanceof ApiError) {
      setError(query);
      response.resultMessage = 'Message failed to send';
      response.isError = true;
    }
    return response;
  };
  const fetchSortedMessages = async (classId: string) => {
    const query = await api.fetchSortedMessages(classId);
    if (query instanceof ApiError) {
      setError(query);
    } else {
      messages.value = query;
    }
  };
  const logout = async () => {
    await AuthClient.getInstance().signOut();
    firebaseUser.value = null;
    currentUser.value = null;
  };
  const login = async (auth: firebase.User) => {
    const authUser = User.fromAuthClient(auth);
    const query = User.fromJson(await api.fetchUser(authUser.uid ?? ''));

    firebaseUser.value = auth;
    if (query instanceof User) {
      currentUser.value = query;
    } else {
      currentUser.value = authUser;
    }
    firebaseAnalytics.logLoginEvent({
      userId: currentUser.value.uid ?? ''
    });
  };
  const createClassFullyQualified = async (
    classData: Class,
    organizationId: string,
    courseId: string
  ) => {
    const _class = await api.createClassFullyQualified(
      classData,
      organizationId,
      courseId
    );
    if (_class instanceof ApiError) {
      setError(_class);
    } else {
      return Class.fromJson(_class);
    }
  };
  const createUserFullyQualified = async (
    userData: User,
    inviteId: string
  ): Promise<User | ApiError> => {
    const response = await api.createUserFullyQualified(userData, inviteId);
    if (response instanceof ApiError) {
      setError(response);
      return response;
    } else {
      return User.fromJson(response);
    }
  };
  const pageViewEvent = (pagePath: RouteNames) => {
    firebaseAnalytics.logPageViewEvent({
      userId: currentUser.value?.uid ?? '',
      page_path: pagePath
    });
  };
  const registerUserEvent = (
    userId: string,
    role: UserRole,
    inviteCode: string,
    type: 'attempt' | 'success' | 'failure'
  ) => {
    firebaseAnalytics.logRegisterUserEvent({
      userId: userId,
      role: role,
      inviteCode: inviteCode,
      type: type
    });
  };
  const expandInstructorFeedbackEvent = (userId: string) => {
    firebaseAnalytics.logExpandInstructorFeedbackEvent({
      userId: userId
    });
  };
  const viewAchievementEvent = (userId: string, achievementId: string) => {
    firebaseAnalytics.logViewAchievementEvent({
      userId: userId,
      achievementId: achievementId
    });
  };

  const rewardPoints = async (
    userId: string,
    points: number,
    category: ModuleCategory
  ) => {
    const result = await api.addPoints(userId, points, category);
    if (result instanceof ApiError) {
      setError(result);
    }
  };

  const addAchievement = async (
    userId: string,
    achievementId: AchievementId
  ) => {
    const result = await api.addAchievement(userId, achievementId);
    if (result instanceof ApiError) {
      setError(result);
    }
  };

  const fetchAchievements = async (userId: string) => {
    const result = await api.fetchAchievements(userId);
    if (result instanceof ApiError) {
      setError(result);
    } else {
      achievements.value = result;
      return result;
    }
  };

  return {
    firebaseUser,
    currentUser,
    error,
    classes,
    courses,
    currentClass,
    currentCourse,
    instructors,
    students,
    messages,
    achievements,
    getClasses,
    getCourses,
    getCurrentClass,
    getCurrentCourse,
    getCurrentUser,
    getFirebaseUser,
    getError,
    getIsLoggedIn,
    getIsEmailVerified,
    getInstructors,
    getAuthorizationDetails,
    createClass,
    fetchClasses,
    fetchClass,
    updateClass,
    fetchCourses,
    fetchCourse,
    updateCourse,
    createInvite,
    fetchInvite,
    updateInvite,
    createUser,
    fetchUser,
    fetchInstructorUsers,
    fetchStudentUsers,
    updateUser,
    createMessage,
    fetchSortedMessages,
    setError,
    logout,
    login,
    createClassFullyQualified,
    createUserFullyQualified,
    pageViewEvent,
    registerUserEvent,
    expandInstructorFeedbackEvent,
    viewAchievementEvent,
    rewardPoints,
    addAchievement,
    fetchAchievements,
    setAuthorizationDetails
  };
});
