import { EReferenceType } from '@IntrinsicSoftware/sim-ecs/dist/serde/referencing.spec';
import { Active, ToggleCommand } from '../../active/main';
import { CompleteCommand } from '../../completable/Completable';
import { Disable } from '../../disable/component';
import { Inbox } from '../../inbox/component';
import { Outbox } from '../../outbox/Outbox';
import { Worth } from '../../point/main';
import { Series } from '../../series/main';
import Timer, { DATE_MAX } from '../../timer/main';
import { Contact } from '../../contact/Contact';
import { Brain, BrainDelegateType } from '../../brains/Brain';
import {
  Conversation,
  ConversationType
} from '../../conversation/Conversation';
import {
  AUTO_HANGUP_ID,
  BDOC_PHONE_LINE_ID,
  TOWER_HOTLINE_TARGET,
  MDG_CC_TARGET,
  WGCC_TARGET,
  MSGCC_TARGET,
  ALERT_FACILITY_TARGET
} from '../../phone/fogPrefab';
import { CTagMarker } from '@IntrinsicSoftware/sim-ecs';

export const TOWER_HOTLINE_TAPPER = '#TowerHotlineTapper';
export const MDG_CC_TAPPER = '#MDGCCTapper';
export const ALERT_FACILITY_TAPPER = '#AlertFacilityTapper';
export const BDOC_TAPPER = '#BdocTapper';
export const WGCC_TAPPER = '#WGCCTapper';
export const MSGCC_TAPPER = '#MSGCCTapper';
const GIANT_VOICE_TARGET = '#GiantVoiceTarget';
const GIANT_VOICE_TAPPER = '#GiantVoiceTapper';
export const GIANT_VOICE = '#GiantVoice';

export const definition = [
  {
    '#RES': 1
  },
  //Group Node
  {
    '#ID': '#GroupNode',
    [CTagMarker]: ['#GroupNode'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: '#ActiveShooterGettingStarted' },
        { type: EReferenceType.Entity, id: '#ActiveShooterQRC' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      root: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Module
    '#ID': '#ActiveShooter',
    [CTagMarker]: ['#ActiveShooter'],
    Description: {
      title: 'Incoming Phonecall',
      description: 'Incoming call on the phoneline.'
    },
    Completable: {
      children: [{ type: EReferenceType.Entity, id: '#GroupNode' }],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active'],
      root: true
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Select A QRC
    '#ID': '#SelectQRC',
    [CTagMarker]: ['#SelectQRC'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Select Checklist',
      description: '2. Select a QRC from your inventory.'
    },
    Worth: <Worth>{
      maximum: 50,
      weight: 1
    },
    Timer: <Timer>{
      limit: 20000,
      expired: false,
      startTime: DATE_MAX
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Base Siren
    '#ID': '#BaseSiren',
    [CTagMarker]: ['#BaseSiren'],
    Active: <Active>{
      active: false
    },
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Activate Base Siren',
      description: 'Activate the base siren'
    },
    Timer: <Timer>{
      limit: 20000,
      expired: false,
      startTime: DATE_MAX
    },
    ToggleCommand: <ToggleCommand>{},
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // GiantVoice
    '#ID': GIANT_VOICE,
    [CTagMarker]: [GIANT_VOICE],
    Description: {
      title: 'Giant Voice',
      description: ''
    },
    Category: {
      column: -1,
      title: ''
    },
    Contact: <Contact>{
      targetEntityId: GIANT_VOICE_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Brain: <Brain>{
      dialogTreeReference: ConversationType.GV_ACTIVE_SHOOTER,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    }
  },
  {
    '#ID': GIANT_VOICE_TARGET,
    [CTagMarker]: [GIANT_VOICE_TARGET],
    Category: {
      column: '',
      title: ''
    },
    Description: {
      title: 'Giant Voice Target',
      description: 'Giant Voice Target'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Brain: <Brain>{
      dialogTreeReference: ConversationType.GV_ACTIVE_SHOOTER,
      delegate: BrainDelegateType.LINEAR,
      controlType: 'Radio'
    }
  },
  {
    '#ID': GIANT_VOICE_TAPPER,
    [CTagMarker]: [GIANT_VOICE_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: '3. Initiate broadcast over the Giant Voice',
      description: '3. Initiate broadcast over the Giant Voice',
      subtitle:
        '"LOCKDOWN. LOCKDOWN. LOCKDOWN. Base is in LOCKDOWN. Please remain in LOCKDOWN MODE until given further guidance. Command post out.” (Repeat 3x) Repeat over giant voice every five minutes.'
    },
    Timer: <Timer>{
      limit: 60000,
      expired: false,
      startTime: DATE_MAX
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: GIANT_VOICE_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: GIANT_VOICE_TARGET
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // Mock Radio
    '#ID': '#FMRadio',
    [CTagMarker]: ['#FMRadio'],
    Description: {
      title: 'Radio',
      description: 'mock radio'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Category: {
      column: '1',
      title: 'FM'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    },
    Disable: <Disable>{}
  },
  {
    //TOWER Phone Line
    '#ID': '#UHFRadio',
    [CTagMarker]: ['#UHFRadio'],
    Description: {
      title: 'UHF',
      description: ''
    },
    Category: {
      column: '2',
      title: 'UHF'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: '#UHFRadioTarget'
    },
    Brain: <Brain>{
      dialogTreeReference: ConversationType.UHF_ACTIVE_SHOOTER,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    }
  },
  {
    '#ID': '#UHFRadioTarget',
    [CTagMarker]: ['#UHFRadioTarget'],
    Category: {
      column: '',
      title: ''
    },
    Description: {
      title: 'UHF Target',
      description: 'UHF Target'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Brain: <Brain>{
      dialogTreeReference: ConversationType.UHF_ACTIVE_SHOOTER,
      delegate: BrainDelegateType.LINEAR,
      controlType: 'Radio'
    }
  },
  {
    '#ID': '#UHFTapper',
    [CTagMarker]: ['#UHFTapper'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Radio',
      description: '5. Initiate over UHF stating:',
      subtitle:
        'All aircraft in the local area, this is Raymond 30 with an active shooter in progress (REPEAT 2x)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: '#UHFRadioTarget'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: '#UHFRadioTarget'
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // Mock Radio
    '#ID': '#VHFRadio',
    [CTagMarker]: ['#VHFRadio'],
    Description: {
      title: 'Radio',
      description: 'mock radio'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Category: {
      column: '3',
      title: 'VHF'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    },
    Disable: <Disable>{}
  },
  {
    // Checklist
    '#ID': '#ActiveShooterGettingStarted',
    [CTagMarker]: ['#ActiveShooterGettingStarted'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: BDOC_TAPPER },
        { type: EReferenceType.Entity, id: '#SelectQRC' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Series: <Series>{ id: Series.UNIDENTIFIED },
    Description: {
      title: 'Getting Started',
      description: 'Getting Started'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    '#ID': BDOC_TAPPER,
    [CTagMarker]: [BDOC_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: { title: 'BDOC', description: '1. Answer the incoming call.' },
    Worth: <Worth>{
      maximum: 50,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: BDOC_PHONE_LINE_ID
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: BDOC_PHONE_LINE_ID
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // Checklist
    '#ID': '#SignificantWXQRC',
    [CTagMarker]: ['#SignificantWXQRC'],
    Completable: {
      children: [
        // { type: EReferenceType.Entity, id: '#BaseCommander' },
        { type: EReferenceType.Entity, id: '#BaseSiren' },
        { type: EReferenceType.Entity, id: '#GiantVoiceTapper' },
        { type: EReferenceType.Entity, id: '#UHFTapper' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Significant WX QRC',
      description: 'Significant WX QRC'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Active Shooter QRC
    '#ID': '#ActiveShooterQRC',
    [CTagMarker]: ['#ActiveShooterQRC'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: '#ConfirmCorrectChecklist' },
        { type: EReferenceType.Entity, id: '#NoteTimeOfNotificationAndSource' },
        { type: EReferenceType.Entity, id: '#GiantVoiceTapper' },
        { type: EReferenceType.Entity, id: TOWER_HOTLINE_TAPPER },
        { type: EReferenceType.Entity, id: '#UHFTapper' },
        { type: EReferenceType.Entity, id: '#Notify' },
        { type: EReferenceType.Entity, id: '#ConfirmChecklistComplete' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Active Shooter QRC',
      description: 'Active Shooter QRC'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Note Time of Notification and Source
    '#ID': '#NoteTimeOfNotificationAndSource',
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Note the Time of Notification and Source',
      description: '2. Note the Time of Notification and Source'
    },
    Worth: <Worth>{
      maximum: 10,
      weight: 1
    },
    Timer: <Timer>{
      limit: 10000,
      expired: false,
      startTime: DATE_MAX
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    '#ID': TOWER_HOTLINE_TAPPER,
    [CTagMarker]: [TOWER_HOTLINE_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Tower',
      description: '4. Contact Tower for Primary Crash Net activation'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: TOWER_HOTLINE_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: TOWER_HOTLINE_TARGET
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // Notify
    '#ID': '#Notify',
    [CTagMarker]: ['#Notify'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: WGCC_TAPPER },
        { type: EReferenceType.Entity, id: MSGCC_TAPPER },
        { type: EReferenceType.Entity, id: MDG_CC_TAPPER },
        { type: EReferenceType.Entity, id: ALERT_FACILITY_TAPPER }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system', 'user'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Notify',
      description: '6. Notify'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    '#ID': MDG_CC_TAPPER,
    [CTagMarker]: [MDG_CC_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Radio',
      description: '6c. MDG/CC (KPD)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: MDG_CC_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: MDG_CC_TARGET
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // Confirm Correct Checklist
    '#ID': '#ConfirmCorrectChecklist',
    [CTagMarker]: ['#ConfirmCorrectChecklist'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Worth: <Worth>{
      maximum: 10,
      weight: 1
    },
    Timer: <Timer>{
      limit: 10000,
      expired: false,
      startTime: DATE_MAX
    },
    Description: {
      title: '1. Confirm correct checklist',
      description: '1. Confirm correct checklist'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Confirm Checklist Complete
    '#ID': '#ConfirmChecklistComplete',
    [CTagMarker]: ['#ConfirmChecklistComplete'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: '6. Confirm checklist complete',
      description: '6. Confirm checklist complete'
    },
    Worth: <Worth>{
      maximum: 10,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Confirm Correct Checklist
    '#ID': '#ContactPrimaryCrashNet',
    [CTagMarker]: ['#ContactPrimaryCrashNet'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['user-active']
    },
    Description: {
      title: 'Confirm correct checklist',
      description: 'Confirm correct checklist'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Secondary Objectives Checklist
    '#ID': '#SecondaryObjectives',
    [CTagMarker]: ['#SecondaryObjectives'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Disable: {},
    Description: {
      title: 'Secondary Objectives',
      description: 'Ad hoc tasks'
    },
    Series: {
      id: '-1',
      title: 'Secondary Objectives'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Mock Checklist 1
    '#ID': '#MockChecklist1',
    [CTagMarker]: ['#MockChecklist1'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Active Air Scramble',
      description: 'Mock Checklist 1 Description'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    Disable: {}
  },
  {
    // Mock Checklist 2
    '#ID': '#MockChecklist2',
    [CTagMarker]: ['#MockChecklist2'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Runway Alert',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    Disable: {}
  },
  {
    // Mock Checklist 3
    '#ID': '#MockChecklist3',
    [CTagMarker]: ['#MockChecklist3'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'FPCON Declaration',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '200',
      title: 'Aircraft Missiles Satellites '
    },
    Disable: {}
  },
  {
    // Mock Checklist 4
    '#ID': '#MockChecklist4',
    [CTagMarker]: ['#MockChecklist4'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Active Shooter/Lockdown',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '200',
      title: 'Aircraft Missiles Satellites '
    },
    Disable: {}
  },
  {
    // Mock Checklist 5
    '#ID': '#MockChecklist5',
    [CTagMarker]: ['#MockChecklist5'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'OPREP-3 Transverse',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '300',
      title: 'Recalls'
    },
    Disable: {}
  },
  {
    // MSG/CC Tapper
    '#ID': MSGCC_TAPPER,
    [CTagMarker]: [MSGCC_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'MSG/CC',
      description: '6b. Notify MSG/CC (KPD)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: MSGCC_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: MSGCC_TARGET
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // WG/CC Tapper
    '#ID': WGCC_TAPPER,
    [CTagMarker]: [WGCC_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'WG/CC',
      description: '6a. WG/CC (KPD)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: WGCC_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: WGCC_TARGET
    },
    Brain: <Brain>{
      delegate: BrainDelegateType.TAPPER
    }
  },
  {
    '#ID': ALERT_FACILITY_TAPPER,
    [CTagMarker]: [ALERT_FACILITY_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Radio',
      description: '6d. Alert facility (HL)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: ALERT_FACILITY_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: ALERT_FACILITY_TARGET
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  }
];
