import { createApp } from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import App from '@/App.vue';
import { createSkylineRouter } from '@/router';
import { AuthClient } from '@/services/auth';
import { AuthenticationRouteHandler } from '@/router/AuthRouteHandler';
import RoleRouteHandler from './router/RoleRouteHandler';
import { FontAwesomeIcon } from '@/plugins/font-awesome';
import pinia from './store/store';

const router = createSkylineRouter();
AuthClient.initialize();

let unregisterAuthenticationRouteHandler =
  AuthenticationRouteHandler.initialize(router);
let unregisterAuthorizationRouteHandler: () => void = () => {
  return;
};

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    unregisterAuthenticationRouteHandler();
    unregisterAuthorizationRouteHandler = RoleRouteHandler.initialize(router);
  } else {
    unregisterAuthenticationRouteHandler =
      AuthenticationRouteHandler.initialize(router);
    unregisterAuthorizationRouteHandler();
  }
  await AuthenticationRouteHandler.onStateChange(router, user);
});

const app = createApp(App);
app.use(pinia);
app.use(router);
// eslint-disable-next-line vue/multi-word-component-names
app.component('fa', FontAwesomeIcon);
app.mount('#app');

// TEST CHANGE - Norman Sharpe 2
