import { RadioBroadcastPayload } from '../../../../models/BuildableDialogItem';
import {
  Node,
  Branch,
  FALLBACK_DIALOG,
  RadioBranch,
  SlipperyStairNode
} from '../../../dialog/component';

export function build() {
  const HIDDEN = false;
  const goalMessage = {
    dialog: '',
    edges: [],
    goalNode: true
  };

  const goalMessageNode = new Node(
    goalMessage.dialog,
    goalMessage.edges,
    goalMessage.goalNode,
    'GiantVoice'
  );

  const correctGiantVoiceBroadcast: RadioBroadcastPayload = {
    message:
      'TORNADO   WARNING   TAKE   COVER   TORNADO   WARNING   TAKE   COVER   TORNADO   WARNING   TAKE   COVER',
    repeats: 3,
    frequency: '5 Minutes'
  };

  const firstOptions: Branch[] = [
    new Branch(FALLBACK_DIALOG, 0, goalMessageNode, false, HIDDEN),
    new RadioBranch(
      JSON.stringify(correctGiantVoiceBroadcast),
      100,
      goalMessageNode,
      false,
      HIDDEN
    ),
    new Branch(' ', 0, goalMessageNode, false),
    new Branch('TORNADO', 0, goalMessageNode, false),
    new Branch('has', 0, goalMessageNode, false),
    new Branch('until', 0, goalMessageNode, false),
    new Branch('WARNING', 0, goalMessageNode, false),
    new Branch('been', 0, goalMessageNode, false),
    new Branch('TAKE', 0, goalMessageNode, false),
    new Branch('BASE', 0, goalMessageNode, false),
    new Branch('CLEAR', 0, goalMessageNode, false),
    new Branch('COVER', 0, goalMessageNode, false),
    new Branch('Please', 0, goalMessageNode, false),
    new Branch('guidance', 0, goalMessageNode, false),
    new Branch('in', 0, goalMessageNode, false),
    new Branch('released', 0, goalMessageNode, false),
    new Branch('remain', 0, goalMessageNode, false),
    new Branch('given', 0, goalMessageNode, false),
    new Branch('MODE', 0, goalMessageNode, false)
  ];

  const root = {
    dialog: '',
    edges: firstOptions,
    goalNode: false,
    cost: 0
  };
  return new SlipperyStairNode(
    root.dialog,
    root.edges,
    root.goalNode,
    'GiantVoice',
    root.cost
  );
}
