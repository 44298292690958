
import { defineComponent, ref, onMounted } from 'vue';
import LoginComponent from '@/components/login/LoginComponent.vue';
import { AuthClient } from '@/services/auth';
import { LoginFormFields } from '@/components/login/FormFields';
import { useRoute } from 'vue-router';
import { useGlobalStore } from '../store/globalStore';

export default defineComponent({
  components: { LoginComponent },
  setup() {
    const error = ref();
    const store = useGlobalStore();
    const isLoading = ref(false);
    const route = useRoute();

    onMounted(async () => {
      if (route.query.refresh === 'true') {
        await AuthClient.getInstance().signOut();
        store.logout();
      }
    });

    async function login(loginFormFields: LoginFormFields) {
      isLoading.value = true;
      error.value = '';
      try {
        await AuthClient.getInstance().signOut();
        await AuthClient.getInstance().signIn(
          loginFormFields.email,
          loginFormFields.password
        );
      } catch (err) {
        if (err instanceof Error) {
          error.value = err.message;
        } else {
          error.value = 'There was a login error. Please try again.';
        }
      }
      isLoading.value = false;
    }
    return {
      error,
      isLoading,
      login
    };
  }
});
