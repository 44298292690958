<template>
  <div class="control-body">
    <div class="control-body-inner-slot">
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
<style scoped>
.control-body {
  @apply flex flex-col items-center h-full w-full bg-night-swamp-200 p-4;
}

.control-body-inner-slot {
  @apply flex flex-col h-full w-full mt-4;
}
</style>
