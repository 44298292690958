import {
  MessageBroadcast,
  RadioBroadcastPayload
} from '../../models/BuildableDialogItem';

export type sender = 'player' | 'npc';
export const FALLBACK_DIALOG = "Sorry, I don't think that's correct";

export interface HistoryItem {
  message: string;
  sender: sender;
  isVisible: boolean;
}

export type ControlType =
  | 'OptionSelect'
  | 'Terminal'
  | 'OrderedCheckbox'
  | 'Radio'
  | 'GiantVoice'
  | 'Hushline';

export class Node {
  constructor(
    public dialog: string = '',
    public edges: Branch[] = [],
    public goalNode = false,
    public type: ControlType = 'OptionSelect',
    public cost: number = 0
  ) {}
  tick(message: MessageBroadcast<string>) {
    let found = false;
    this.edges.forEach((branch) => {
      if (branch._dialog == message.payload) {
        branch.isSelected = true;
        found = true;
      }
    });
    return found;
  }
}

export class SlipperyStairNode extends Node {
  constructor(
    public dialog: string = '',
    public edges: Branch[] = [],
    public goalNode = false,
    public type: ControlType = 'OptionSelect',
    public cost: number = 0
  ) {
    super();
  }
  tick(message: MessageBroadcast<string>) {
    let found = super.tick(message);
    if (!found) {
      const fallbackEdge = this.edges.find((edge) => {
        return edge.dialog === FALLBACK_DIALOG;
      });
      if (fallbackEdge) {
        fallbackEdge.isSelected = true;
        found = true;
      }
    }
    return found;
  }
}

export interface Edge {
  node: Node;
}

export class Branch implements Edge {
  constructor(
    public _dialog: string,
    public correctness: number,
    public node: Node,
    public isSelected: boolean,
    public isVisible: boolean = true
  ) {}
  public get dialog() {
    return this._dialog;
  }
  eval() {
    return this.isSelected;
  }
}

export class RadioBranch extends Branch {
  constructor(
    _dialog: string,
    public correctness: number,
    public node: Node,
    public isSelected: boolean,
    public isVisible: boolean = true
  ) {
    super(_dialog, correctness, node, isSelected, isVisible);
  }
  public get dialog() {
    const radioBroadcast = JSON.parse(this._dialog) as RadioBroadcastPayload;
    return radioBroadcast.message;
  }
}
