import Icon from '../components/icon/Icon';

export enum Qualifier {
  POSITIVE = 'positive',
  URGENT = 'urgent',
  GENERAL = 'general',
  NONE = ''
}

export const qualifierIconMapping = new Map<Qualifier, Icon>([
  [Qualifier.POSITIVE, Icon.BADGE_STAR],
  [Qualifier.GENERAL, Icon.PAGE],
  [Qualifier.URGENT, Icon.URGENT_MESSAGE],
  [Qualifier.NONE, Icon.NONE]
]);

export const qualifierTextMapping = new Map<Qualifier, string>([
  [Qualifier.POSITIVE, 'Positive Feedback'],
  [Qualifier.GENERAL, 'General Notice'],
  [Qualifier.URGENT, 'Urgent Message'],
  [Qualifier.NONE, '']
]);

export const getIconOrDefault = (
  qualifier: Qualifier,
  defaultIcon = Icon.NONE
): Icon => {
  defaultIcon = qualifierIconMapping.get(qualifier) ?? defaultIcon;
  return defaultIcon;
};

export const getTextOrDefault = (
  qualifier: Qualifier,
  defaultText = ''
): string => {
  defaultText = qualifierTextMapping.get(qualifier) ?? defaultText;
  return defaultText;
};

export const instructorFeedbackQualifiers = [
  Qualifier.POSITIVE,
  Qualifier.GENERAL,
  Qualifier.URGENT
];
