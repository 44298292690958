export type DropdownOptions<T = FormOptionValue> = {
  label: string;
  value?: T;
};

export type FormOptionValue = number | string | boolean;

export const yesNoOptions: DropdownOptions[] = [
  { label: 'yes', value: true },
  { label: 'no', value: false }
];
