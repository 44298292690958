import { Ref, ShallowRef, computed, ref, shallowRef, watch } from 'vue';
import {
  Millisecond,
  PointAward,
  ScenarioComposable,
  ScenarioSerializedThing
} from '../types';
import { ChecklistCount, ModuleComposable } from '../module/useModule';
import { toTaskStruct, OperatingSystem, Scheduler } from './OperatingSystem';
import { sum } from '../scenario/OperatingSystem';
import { secondsToMinutesObject } from '../../utils/utils';

function computeTotalChecklistCount(
  modules: ModuleComposable[]
): ChecklistCount {
  return modules.reduce(
    (sum: ChecklistCount, current) => {
      sum.total += current.totalTasks.value;
      sum.compromisedCount += current.totalCompromised.value;
      return sum;
    },
    {
      total: 0,
      compromisedCount: 0
    }
  );
}

export function useScenario(modules: ModuleComposable[]): ScenarioComposable {
  const onCompleteListeners: (() => void)[] = [];

  const totalChecklistCount = computed(() => {
    return computeTotalChecklistCount(modules);
  });
  const totalPointsPossible = sum(
    modules.map((mod) => mod.totalPointsPossible)
  );
  const tasks = modules.map((module) => toTaskStruct(module));
  const os = new OperatingSystem(tasks, new Scheduler('non-preemptive', tasks));
  os.startTheReactor();

  const activeModule = shallowRef<ModuleComposable>(os.parent.sp);
  os.scheduler.onRun((task) => {
    activeModule.value = task.sp;
  });

  const moduleCompletionStates = ref(modules.map(() => false));

  modules.forEach((module, index) => {
    module.onComplete(() => {
      moduleCompletionStates.value[index] = true;
    });
  });

  const complete = computed(() => {
    return moduleCompletionStates.value.every((value) => value);
  });

  const timeStarted = Date.now();

  const timeOnScenario = ref(
    secondsToMinutesObject((Date.now() - timeStarted) / 1000)
  );

  const timerInterval = setInterval(() => {
    timeOnScenario.value = secondsToMinutesObject(
      (Date.now() - timeStarted) / 1000
    );
  }, 50);

  watch(complete, function clearTimerIntervalWhenComplete(newValue, oldValue) {
    if (oldValue === false && newValue === true) {
      clearInterval(timerInterval);
    }
  });

  const history: ShallowRef<ScenarioSerializedThing> = shallowRef(
    ScenarioSerializedThing.Default()
  );

  const pointsEarned: Ref<PointAward> = ref(0);
  const unwatch = watch(complete, async (newValue, oldValue) => {
    if (oldValue === false && newValue === true) {
      const serializedModules = modules.map((mod) => mod.serialize());
      const timeSpent: Millisecond =
        timeOnScenario.value.minutesRaw * 60 * 1000 +
        timeOnScenario.value.secondsRaw * 1000; // convert to milliseconds
      const computedModules = computeTotalChecklistCount(modules);
      history.value = new ScenarioSerializedThing(
        serializedModules,
        timeSpent,
        pointsEarned.value,
        computedModules,
        totalPointsPossible
      );
      unwatch();
      onCompleteListeners.forEach((fn) => fn());
    }
  });

  function addPoint(point: PointAward) {
    pointsEarned.value += point;
  }

  return {
    activeModule,
    complete,
    totalChecklistCount,
    history,
    totalPointsPossible,
    pointsEarned,
    addPoint,
    onComplete: (fn: () => void) => {
      onCompleteListeners.push(fn);
    },
    elapsedTime: timeOnScenario
  };
}
