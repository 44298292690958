import {
  SerialFormat,
  Entity,
  IEntity,
  buildWorld,
  createSystem,
  queryEntities,
  With,
  removeWorld,
  World,
  WithTag,
  IWorld,
  getWorld
} from '@IntrinsicSoftware/sim-ecs';
import { ActivePhoneCallSynchronizationComponent } from '../phone/ActivePhoneCall';
import { CollectiblePoint, Worth } from '../point/main';
import {
  alertPhoneLinesPrefab,
  autoHangupLine,
  commandPhoneLinesPrefab,
  dsnPhoneLinesPrefab,
  emergencyPhoneLinesPrefab,
  flyingOpsPhoneLinesPrefab,
  hqPhoneLinesPrefab,
  specialPhoneLinesPrefab
} from '../phone/fogPrefab';
import executionSchedule from '@/ecs/module/ExecutionSchedule';
import components from './components';
import {
  ChecklistSynchronizationComponent,
  Completable,
  CompletableSynchronizationComponent
} from '../completable/Completable';
import { TimerSynchronizationComponent } from '../timer/main';
import { CategorySynchronizationComponent } from '../category/component';
import { CompletableSerde } from '../completable/serialization';
import { OnCollectiblePointCreated } from '../point/main';
import { TEntity } from '@IntrinsicSoftware/sim-ecs/dist/serde/_';
import { ModuleCategory } from '../database';

export interface DefinitionMapper {
  module: string;
  checklists: string[];
  questChecklist: string;
  secondaryObjectiveChecklist: string;
  baseCommander: string;
  baseSiren: string;
  giantVoice: string;
  radios: string[];
}

export class OnCollectibleCategorizedPointCreated {
  constructor(
    public payload: CollectiblePoint,
    public category: ModuleCategory
  ) {}
}

export class WorldProxy {
  private _world: IWorld;
  constructor(private worldName: string) {
    const world = getWorld(worldName);
    if (!world) {
      throw new Error('World not found');
    }
    this._world = world;
  }
  public getEntity(id: string): IEntity {
    const entities = this._world.getEntities(queryEntities(WithTag(id)));
    return entities.next().value;
  }
  public getEntitiesWithWorth() {
    return this._world.getEntities(queryEntities(With(Worth)));
  }
}

export async function build(
  definition: TEntity[],
  mapper: DefinitionMapper,
  category: ModuleCategory
) {
  let tickTimeout: number;
  const worldName = Math.random().toString();
  const ticker = createSystem({
    interval: 100
  })
    .withName(worldName)
    .withRunFunction(({ interval }) => {
      tickTimeout = setTimeout(() => {
        world.dispatch();
      }, interval);
    })
    .build();

  const schedule = await executionSchedule(ticker);

  const world = buildWorld()
    .withName(worldName)
    .withDefaultScheduling(async (root) => root.fromPrefab(schedule))
    .withComponent(ActivePhoneCallSynchronizationComponent)
    .withComponents(...components)
    .withComponent(CompletableSynchronizationComponent)
    .withComponent(TimerSynchronizationComponent)
    .withComponent(Completable, { serDe: CompletableSerde })
    .withComponent(ChecklistSynchronizationComponent)
    .withComponent(CategorySynchronizationComponent)
    .build();

  const fogPhoneLines = [
    ...dsnPhoneLinesPrefab(),
    ...commandPhoneLinesPrefab(),
    ...flyingOpsPhoneLinesPrefab(),
    ...hqPhoneLinesPrefab(),
    ...emergencyPhoneLinesPrefab(),
    ...alertPhoneLinesPrefab(),
    ...specialPhoneLinesPrefab(),
    autoHangupLine()
  ];
  world.commands.load(SerialFormat.fromArray(fogPhoneLines), {
    useDefaultHandler: true
  });
  world.commands.load(SerialFormat.fromArray(definition), {
    useDefaultHandler: true
  });
  await world.flushCommands();

  const listeners: ((event: OnCollectibleCategorizedPointCreated) => void)[] =
    [];

  function onCollectiblePointCreated(event: OnCollectiblePointCreated) {
    listeners.forEach(function notifyPointCollectors(collector) {
      collector(
        new OnCollectibleCategorizedPointCreated(event.payload, category)
      );
    });
  }

  (world as World).eventBus.subscribe(
    OnCollectiblePointCreated,
    onCollectiblePointCreated
  );

  const proxy = new WorldProxy(worldName);

  function mapAndFilter(ids: string[]): IEntity[] {
    const entities = ids
      .map((item) => proxy.getEntity(item))
      .filter((item) => item !== undefined) as IEntity[];
    return entities;
  }

  const module: Module = {
    entity: proxy.getEntity(mapper.module) || new Entity(),
    checklists: mapAndFilter(mapper.checklists),
    questChecklist: proxy.getEntity(mapper.questChecklist) || new Entity(),
    secondaryObjectiveChecklist:
      proxy.getEntity(mapper.secondaryObjectiveChecklist) || new Entity(),
    baseSiren: proxy.getEntity(mapper.baseSiren) || new Entity(),
    fogPhoneLines: mapAndFilter(
      fogPhoneLines.map((prefab) => {
        return prefab['#ID'];
      })
    ),
    giantVoice: proxy.getEntity(mapper.giantVoice) || new Entity(),
    radios: mapAndFilter(mapper.radios),
    get totalPointsPossible() {
      const entitiesWithWorth = world.getEntities(queryEntities(With(Worth)));
      return Array.from(entitiesWithWorth).reduce((acc, entity) => {
        const point = entity.getComponent(Worth) || new Worth(0);
        return acc + point.maximum;
      }, 0);
    },
    worldProxy: proxy,
    cleanup() {
      clearTimeout(tickTimeout);
      world.commands.stopRun();
      world.clearEntities();
      world.clearResources();
      removeWorld(world);
    },
    start() {
      world.dispatch();
    },
    stop() {
      this.cleanup();
    },
    onCollectiblePointCreated(
      callback: (event: OnCollectibleCategorizedPointCreated) => void
    ) {
      listeners.push(callback);
    }
  };
  return module;
}

export interface Module {
  entity: IEntity;
  worldProxy: WorldProxy;
  checklists: IEntity[];
  questChecklist: IEntity;
  secondaryObjectiveChecklist: IEntity;
  baseSiren: IEntity;
  fogPhoneLines: IEntity[];
  giantVoice: IEntity;
  radios: IEntity[];
  totalPointsPossible: number;
  cleanup: () => void;
  start: () => void;
  stop: () => void;
  onCollectiblePointCreated: (
    // callback: (event: OnCollectiblePointCreated) => void
    callback: (event: OnCollectibleCategorizedPointCreated) => void
  ) => void;
}
