import { BDOC_TAPPER, definition, GIANT_VOICE } from './definition';
import { useModule } from '../../module/useModule';
import { ref } from 'vue';
import { SelectableChecklist } from '../../types';
import { build as builder } from '@/ecs/module/Module';
import { Entity, IEntity } from '@IntrinsicSoftware/sim-ecs';
import {
  useChecklist,
  useCompletableChild
} from '../../completable/Completable';
import { PhoneLineComposable } from '../../phone/PhoneLine';
import {
  BDOC_TARGET_ID,
  MSGCC,
  MSGCC_TARGET,
  WGCC,
  WGCC_TARGET
} from '../../phone/fogPrefab';
import { ConversationType } from '../../conversation/Conversation';
import { Brain } from '../../brains/Brain';
import { ModuleCategory } from '../../database';

const entityIds = {
  module: '#ActiveShooter',
  checklists: [
    '#SignificantWXQRC',
    '#ActiveShooterQRC',
    '#MockChecklist1',
    '#MockChecklist2',
    '#MockChecklist3',
    '#MockChecklist4',
    '#MockChecklist5'
  ],
  questChecklist: '#ActiveShooterGettingStarted',
  secondaryObjectiveChecklist: '#SecondaryObjectives',
  baseCommander: '#BaseCommander',
  baseSiren: '#BaseSiren',
  giantVoice: '#GiantVoice',
  radios: ['#FMRadio', '#UHFRadio', '#VHFRadio']
};

export async function build(category: ModuleCategory) {
  return builder(definition, entityIds, category);
}

const createSelectableChecklist = (
  checkList: IEntity,
  setSelectedIndex = 0
) => {
  const checklistComposable = useChecklist(checkList);
  const selectableChecklist: SelectableChecklist = {
    composable: checklistComposable,
    selected: ref(false),
    setSelected: (value = true) => {
      checklistComposable.children[setSelectedIndex].setComplete(value);
    }
  };
  return selectableChecklist;
};

function isBrain(component: Brain | undefined): component is Brain {
  return !!component;
}

function updateEntityConversation(
  e: Awaited<ReturnType<typeof build>>,
  entityId: string,
  conversationType: ConversationType
) {
  e.fogPhoneLines
    .filter((e) => e.id === entityId)
    .map((e) => e.getComponent(Brain))
    .filter(isBrain)
    .forEach((brain) => (brain.dialogTreeReference = conversationType));
}

function callLine(lines: PhoneLineComposable[], target: string) {
  lines
    .filter((e) => e.entityId === target)
    .forEach((line) => {
      line.call();
    });
}

export function buildComposable(e: Awaited<ReturnType<typeof build>>) {
  const result = useModule(
    e.entity as Entity,
    e.worldProxy,
    createSelectableChecklist(e.questChecklist, 1),
    createSelectableChecklist(e.secondaryObjectiveChecklist),
    e.checklists,
    e.baseSiren,
    e.giantVoice,
    e.fogPhoneLines,
    e.radios
  );
  result.onAcknowledge(() => {
    const incomingCallEntity = e.worldProxy.getEntity(BDOC_TAPPER);
    if (!incomingCallEntity) {
      throw 'Incoming Call entity is not found.';
    }
    const incomingCallChecklistItem = useCompletableChild(incomingCallEntity);
    incomingCallChecklistItem.onComplete(() => {
      result.updateDescription(
        'Active Shooter',
        'We have an active shooter situation at the base exchange. As of now, two masked gunmen have barricaded themselves in Green Beans Coffee Shop and are holding the three employees hostage. All other personnel have been evacuated. The cordon is set at 1000 meters, and BPD SWAT is en route. No other information is available at this time.'
      );
    });
  });
  result.acknowledge();
  result.onStart(() => {
    callLine(result.phoneLines, BDOC_TARGET_ID);
    callLine([result.giantVoice], GIANT_VOICE);
    callLine(result.radioLines, '#UHFRadio');
    updateEntityConversation(e, WGCC, ConversationType.WG_CC_ACTIVE_SHOOTER);
    updateEntityConversation(
      e,
      WGCC_TARGET,
      ConversationType.WG_CC_ACTIVE_SHOOTER
    );
    updateEntityConversation(e, MSGCC, ConversationType.MSG_CC_ACTIVE_SHOOTER);
    updateEntityConversation(
      e,
      MSGCC_TARGET,
      ConversationType.MSG_CC_ACTIVE_SHOOTER
    );
  });
  result.secondaryObjectiveChecklist.selected.value = false;

  return result;
}
