import { Entity, IEntity } from '@IntrinsicSoftware/sim-ecs';
import { Ref, ref, ShallowRef, watch } from 'vue';
import useBaseSiren from '@/ecs/baseSiren/useBaseSiren';
import {
  ChecklistComposable,
  ChecklistViewModel,
  CompletableViewModel,
  useChecklist,
  useCompletable,
  useCompletableChild
} from '../completable/Completable';
import { DescriptionViewModel, useDescription } from '../description/main';
import { PhoneLineComposable, usePhoneLine } from '../phone/PhoneLine';
import { Task } from '@/ecs/scenario/OperatingSystem';
import {
  BaseSirenComposable,
  ModuleSerializedThing,
  PointAward,
  SelectableChecklist
} from '../types';
import { WorldProxy } from './Module';
import { Worth } from '../point/main';

type ModuleState = 'none' | 'started' | 'running' | 'stopped' | 'complete';

export interface ModuleComposable extends Task {
  id: string;
  state: ShallowRef<ModuleState>;
  acknowledged: Ref<boolean>;
  entity: IEntity;
  description: ShallowRef<DescriptionViewModel>;
  completable: ShallowRef<CompletableViewModel>;
  questChecklist: SelectableChecklist;
  secondaryObjectiveChecklist: SelectableChecklist;
  checklists: SelectableChecklist[];
  start: () => Promise<ModuleComposable>;
  onStart: (fn: () => void) => void;
  onStop: (fn: () => void) => void;
  onComplete: (fn: () => void) => ModuleComposable;
  onAcknowledge: (fn: () => void) => void;
  complete: () => void;
  updateDescription: (title: string, description: string) => void;
  stop: () => void;
  setComplete: () => void;
  acknowledge: () => void;
  baseSiren: BaseSirenComposable;
  giantVoice: PhoneLineComposable;
  phoneLines: PhoneLineComposable[];
  radioLines: PhoneLineComposable[];
  totalTasks: Ref<number>;
  totalCompromised: Ref<number>;
  totalPointsPossible: PointAward;
  worldProxy: WorldProxy;
  serialize: () => ModuleSerializedThing;
}

export interface ChecklistCount {
  total: number;
  compromisedCount: number;
}

function getMetaData(checklist: ChecklistViewModel): ChecklistCount {
  return checklist.children.reduce(
    function traverse(accumulator: ChecklistCount, current): ChecklistCount {
      accumulator.total++;
      if (current.compromised) {
        accumulator.compromisedCount++;
      }
      return current.children.reduce(
        (accumulator, curr) => traverse(accumulator, curr),
        accumulator
      );
    },
    {
      total: 0,
      compromisedCount: 0
    }
  );
}

export function checklistToSelectableChecklist(
  checklist: ChecklistComposable
): SelectableChecklist {
  return {
    composable: checklist,
    selected: ref(false),
    setSelected: (value = true) => {
      checklist.children[0].setComplete(value);
    }
  };
}

export const useModule = (
  entity: Entity,
  worldProxy: WorldProxy,
  questChecklist: SelectableChecklist,
  secondaryObjectiveChecklist: SelectableChecklist,
  checklists: IEntity[],
  baseSiren: IEntity,
  giantVoice: IEntity,
  fogPhoneLines: IEntity[],
  fogRadioLines: IEntity[]
): ModuleComposable => {
  const phoneLines = fogPhoneLines.map((phoneLine) => {
    return usePhoneLine(phoneLine, worldProxy);
  });

  const radioLines = fogRadioLines.map((entity) =>
    usePhoneLine(entity, worldProxy)
  );
  const description = useDescription(entity);
  const completable = useCompletable(entity);
  const completableChild = useCompletableChild(entity);
  const baseSirenState = useBaseSiren(baseSiren);
  const giantVoiceState = usePhoneLine(giantVoice, worldProxy);

  const primary = questChecklist;
  const secondary = secondaryObjectiveChecklist;

  const selectableChecklists = checklists
    .map(useChecklist)
    .map(function checklistComposableToSelectableChecklist(
      checklist
    ): SelectableChecklist {
      return {
        composable: checklist,
        selected: ref(false),
        setSelected: function markQuestAsSelected(value = true) {
          primary.setSelected(value);
        }
      };
    });

  function getTotalPointsPossible(
    entitiesWithWorth: IterableIterator<IEntity>
  ) {
    return Array.from(entitiesWithWorth).reduce((acc, entity) => {
      const point = entity.getComponent(Worth) || new Worth(0);
      return acc + point.maximum;
    }, 0);
  }

  const onStartListeners: (() => void)[] = [];
  const onStopListeners: (() => void)[] = [];
  const onCompleteListeners: (() => void)[] = [];
  const onAcknowledgeListeners: (() => void)[] = [];
  const state: Ref<ModuleState> = ref('none');
  const acknowledged: Ref<boolean> = ref(false);
  const totalTasks = ref(0);
  const totalCompromised = ref(0);
  const entitiesWithWorth = worldProxy.getEntitiesWithWorth();
  const totalPointsPossible = getTotalPointsPossible(entitiesWithWorth);

  const result: ModuleComposable = {
    get entity() {
      return entity;
    },
    id: entity.id,
    state,
    acknowledged,
    description,
    completable,
    questChecklist: primary,
    secondaryObjectiveChecklist: secondary,
    checklists: [primary, ...selectableChecklists, secondary],
    totalTasks,
    totalCompromised,
    totalPointsPossible,
    worldProxy,
    onStart: (fn: () => void) => {
      onStartListeners.push(fn);
    },
    onStop: (fn: () => void) => {
      onStopListeners.push(fn);
    },
    onComplete: (fn: () => void) => {
      onCompleteListeners.push(fn);
      return result;
    },
    onAcknowledge: (fn: () => void) => {
      onAcknowledgeListeners.push(fn);
    },
    updateDescription: (title: string, description: string) => {
      result.description.value.description = description;
      result.description.value.title = title;
    },
    start() {
      state.value = 'started';
      onStartListeners.forEach((fn) => fn());
      return new Promise<ModuleComposable>((resolve, reject) => {
        this.onComplete(resolve.bind(this, this));
        this.onStop(reject);
      });
    },
    acknowledge() {
      primary.selected.value = true;
      secondary.selected.value = true;
      acknowledged.value = true;
      onAcknowledgeListeners.forEach((fn) => fn());
    },
    stop() {
      state.value = 'stopped';
      onStartListeners.forEach((fn) => fn());
    },
    complete() {
      state.value = 'complete';
      onCompleteListeners.forEach((fn) => fn());
    },
    setComplete() {
      completableChild.setComplete();
      this.complete();
    },
    baseSiren: baseSirenState,
    giantVoice: giantVoiceState,
    phoneLines,
    radioLines,
    serialize() {
      return new ModuleSerializedThing(this);
    }
  };

  function onlySelected(selectableChecklist: SelectableChecklist) {
    return selectableChecklist.selected.value;
  }
  function getChecklistCount(): ChecklistCount {
    const initialChecklistCount: ChecklistCount = {
      total: 0,
      compromisedCount: 0
    };
    return result.checklists.filter(onlySelected).reduce((acc, currValue) => {
      const { total, compromisedCount } = getMetaData(
        currValue.composable.checklist.value
      );
      acc.total += total;
      acc.compromisedCount += compromisedCount;
      return acc;
    }, initialChecklistCount);
  }

  const cancelWatchComplete = watch(completable, (completable) => {
    if (completable.value === true) {
      const { total, compromisedCount } = getChecklistCount();
      totalTasks.value = total;
      totalCompromised.value = compromisedCount;
      result.complete();
      cancelWatchComplete();
    }
  });

  return result;
};
