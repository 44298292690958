import { ConversationState } from '../conversation/Conversation';
import { Context, Brain, DelegateFunctions, noop } from './Brain';

export function EchoBrain(context: Context, brain: Brain): DelegateFunctions {
  const sense = () => {
    const messageBroadcast = context.inbox.stream.pop();
    context.incomingMessage = messageBroadcast;
    return context;
  };
  const evaluate = () => {
    context.conversation.state = ConversationState.ACTIVE;
    return context;
  };
  const act = () => {
    setTimeout(() => {
      if (context.incomingMessage) {
        context.conversation.state = ConversationState.WAITING;
        context.outbox.stream.push(context.incomingMessage);
      }
    }, brain.delayTime);
  };
  return { sense, evaluate, act, exit: noop };
}
