import { IEntity } from '@IntrinsicSoftware/sim-ecs';
import { ShallowRef, shallowRef } from 'vue';
import {
  MessageBroadcast,
  RadioBroadcastPayload
} from '../../models/BuildableDialogItem';
import { ComponentException } from '../types';

export class Outbox {
  constructor(
    public stream: MessageBroadcast<string>[] = [],
    public expectedMessage: RadioBroadcastPayload = {
      frequency: '',
      message: '',
      repeats: 0
    }
  ) {}
}

export interface OutboxViewModel {
  stream: MessageBroadcast<string>[];
  expectedMessage: RadioBroadcastPayload;
}

export class OutboxSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<OutboxViewModel> = shallowRef({
      stream: [],
      expectedMessage: { frequency: '', message: '', repeats: 0 }
    })
  ) {}
}

export const useOutbox = (entity: IEntity) => {
  const outboxComponent = entity.getComponent(Outbox);
  if (!outboxComponent) {
    throw new ComponentException(entity.id, 'Outbox component not found');
  }
  const ref = shallowRef<OutboxViewModel>({
    stream: outboxComponent.stream,
    expectedMessage: outboxComponent.expectedMessage
  });
  const synchronizationComponent = new OutboxSynchronizationComponent(ref);
  entity.addComponent(synchronizationComponent);
  return {
    outbox: ref,
    clear: () => {
      outboxComponent.stream.pop();
    }
  };
};
