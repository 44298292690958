import { BuildableDialogItem } from '../../../../models/BuildableDialogItem';
import { CommandType } from './type';

export function undo(
  builtDialogMessage: BuildableDialogItem[],
  commandStack: CommandType[],
  inverseCommandStack: CommandType[]
) {
  const command = commandStack.pop();
  if (command) {
    switch (command.type) {
      case 'add':
        builtDialogMessage.pop();
        break;
      case 'remove':
        builtDialogMessage.splice(command.index, 0, ...command.items);
        break;
      case 'clear':
        command.items.forEach((dialogItem) =>
          builtDialogMessage.push(dialogItem)
        );
        break;
    }
    inverseCommandStack.push(command);
  }
}
