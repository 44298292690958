import { EReferenceType } from '@IntrinsicSoftware/sim-ecs/dist/serde/referencing.spec';
import { correctRadioBroadcast } from '../../../models/RadioDialogItem';
import { Active, ToggleCommand } from '../../active/main';
import { CompleteCommand } from '../../completable/Completable';
import { Disable } from '../../disable/component';
import { Outbox } from '../../outbox/Outbox';
import { Worth } from '../../point/main';
import { Series } from '../../series/main';
import Timer, { DATE_MAX } from '../../timer/main';
import { Contact } from '../../contact/Contact';
import {
  Conversation,
  ConversationType
} from '../../conversation/Conversation';
import { Inbox } from '../../inbox/component';
import { Brain, BrainDelegateType } from '../../brains/Brain';
import {
  AUTO_HANGUP_ID,
  MSGCC_TARGET,
  WGCC_TARGET
} from '../../phone/fogPrefab';
import { CTagMarker } from '@IntrinsicSoftware/sim-ecs';

export const WGCC_TAPPER = '#WGCCTapper';

const GIANT_VOICE_TARGET = '#GiantVoiceTarget';
const GIANT_VOICE_TAPPER = '#GiantVoiceTapper';
export const GIANT_VOICE = '#GiantVoice';

export const definition = [
  {
    '#RES': 1
  },
  //Group Node
  {
    '#ID': '#GroupNode',
    [CTagMarker]: ['#GroupNode'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: '1011' },
        { type: EReferenceType.Entity, id: '1001' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      root: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Module
    '#ID': '#SignificantWX',
    [CTagMarker]: ['#SignificantWX'],
    Description: {
      title: 'Tornado Warning',
      description:
        'This is Base Weather with a Tornado Warning. Weather Warning #: 12-01. Description: Tornado observed 3 NM North of Keesler AFB. Valid Time: Now - Until Further Notice. Please Acknowledge Receipt.'
    },
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: '#GroupNode' }
        // { type: EReferenceType.Entity, id: '#SecondaryObjectives' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'parallel children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active'],
      root: true
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Select WX QRC Checklist Quest
    '#ID': '1012',
    [CTagMarker]: ['1012'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Select Checklist',
      description: 'Select a QRC from your inventory'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },

    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Base Siren
    '#ID': '1009',
    [CTagMarker]: ['1009'],
    Active: <Active>{
      active: false
    },
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Activate Base Siren',
      description:
        '4a. Activate Base Siren (Steady Tone). For Tornado Warning ONLY.'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    Timer: <Timer>{
      limit: 20000,
      expired: false,
      startTime: DATE_MAX
    },
    ToggleCommand: <ToggleCommand>{},
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // GiantVoice
    '#ID': GIANT_VOICE,
    [CTagMarker]: [GIANT_VOICE],
    Description: {
      title: 'Giant Voice',
      description: ''
    },
    Category: {
      column: -1,
      title: ''
    },
    Contact: <Contact>{
      targetEntityId: GIANT_VOICE_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Brain: <Brain>{
      dialogTreeReference: ConversationType.GV_SIGNIFICANT_WEATHER,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'GiantVoice'
    }
  },
  {
    '#ID': GIANT_VOICE_TARGET,
    [CTagMarker]: [GIANT_VOICE_TARGET],
    Category: {
      column: '',
      title: ''
    },
    Description: {
      title: 'Giant Voice Target',
      description: 'Giant Voice Target'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Brain: <Brain>{
      dialogTreeReference: ConversationType.GV_SIGNIFICANT_WEATHER,
      delegate: BrainDelegateType.LINEAR,
      controlType: 'GiantVoice'
    }
  },
  {
    '#ID': GIANT_VOICE_TAPPER,
    [CTagMarker]: [GIANT_VOICE_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Initiate broadcast over the Giant Voice',
      description: '4b. Announce the following statement over the Giant Voice',
      subtitle:
        '"This is the Command Post with a TORNADO WARNING, TAKE COVER, TORNADO WARNING, TAKE COVER, TORNADO WARNING, TAKE COVER. Command Post Out” (Repeat 3x) Repeat over giant voice every five minutes.'
    },
    Timer: <Timer>{
      limit: 60000,
      expired: false,
      startTime: DATE_MAX
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: GIANT_VOICE_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: GIANT_VOICE_TARGET
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // Mock Radio
    '#ID': '1045',
    [CTagMarker]: ['1045'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Radio',
      description: 'mock radio'
    },
    Outbox: <Outbox>{},
    Inbox: <Inbox>{},
    Category: {
      column: '1',
      title: 'FM'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    },
    Disable: <Disable>{}
  },
  {
    // Radio
    '#ID': '1046',
    [CTagMarker]: ['1046'],
    Description: {
      title: 'Radio',
      description: '5b. Notify Aircraft over UHF stating:',
      subtitle:
        'All aircraft in the local area, this is Raymond 30 with a tornado warning, contact tower or ATC for further instructions (REPEAT x2)…Raymond 30 Out.'
    },
    Outbox: <Outbox>{ expectedMessage: correctRadioBroadcast.payload },
    Inbox: <Inbox>{},
    Category: {
      column: '2',
      title: 'UHF'
    },
    Contact: <Contact>{
      targetEntityId: '#UHFRadioTarget'
    },
    Brain: <Brain>{
      dialogTreeReference: ConversationType.UHF_SIGNIFICANT_WEATHER,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    }
  },
  {
    '#ID': '#UHFRadioTarget',
    [CTagMarker]: ['#UHFRadioTarget'],
    Category: {
      column: '',
      title: ''
    },
    Description: {
      title: 'UHF Target',
      description: 'UHF Target'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Brain: <Brain>{
      dialogTreeReference: ConversationType.UHF_SIGNIFICANT_WEATHER,
      delegate: BrainDelegateType.LINEAR,
      controlType: 'Radio'
    }
  },
  {
    '#ID': '#UHFTapper',
    [CTagMarker]: ['#UHFTapper'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['system', 'user'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Radio',
      description: '5b. Notify Aircraft over UHF stating:',
      subtitle:
        'All aircraft in the local area, this is Raymond 30 with a tornado warning, contact tower or ATC for further instructions (REPEAT x2)…Raymond 30 Out.'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: '#UHFRadioTarget'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: '#UHFRadioTarget'
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // Mock Radio
    '#ID': '1047',
    [CTagMarker]: ['1047'],
    Description: {
      title: 'Radio',
      description: 'mock radio'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Category: {
      column: '3',
      title: 'VHF'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    },
    Disable: <Disable>{}
  },
  {
    // Getting Started Checklist
    '#ID': '1011',
    [CTagMarker]: ['1011'],
    Completable: {
      children: [{ type: EReferenceType.Entity, id: '1012' }],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Getting Started',
      description: 'Getting Started'
    },
    CompleteCommand: <CompleteCommand>{},
    Series: <Series>{ id: Series.UNIDENTIFIED }
  },
  {
    '#ID': '#InitiateTornadoWarningChecklist',
    [CTagMarker]: ['#InitiateTornadoWarningChecklist'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: '1009' },
        { type: EReferenceType.Entity, id: GIANT_VOICE_TAPPER }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system', 'user'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Initiate Tornado Warning',
      description: '4. Initiate Tornado Warning'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    '#ID': '#Notify',
    [CTagMarker]: ['#Notify'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: WGCC_TAPPER },
        { type: EReferenceType.Entity, id: '#UHFTapper' },
        { type: EReferenceType.Entity, id: '#TATapper' },
        { type: EReferenceType.Entity, id: '#MOCTapper' },
        { type: EReferenceType.Entity, id: '#CETapper' },
        { type: EReferenceType.Entity, id: '#OGCCTapper' },
        { type: EReferenceType.Entity, id: '#MSGCCTapper' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system', 'user'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Notify',
      description: '5. Notify'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Confirm Checklist
    '#ID': '#ConfirmChecklist',
    [CTagMarker]: ['#ConfirmChecklist'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Confirm correct checklist',
      description: '1. Confirm correct checklist'
    },
    Worth: <Worth>{
      maximum: 10,
      weight: 1
    },
    Timer: <Timer>{
      limit: 10000,
      expired: false,
      startTime: DATE_MAX
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Confirm Checklist
    '#ID': '#ConfirmChecklistComplete',
    [CTagMarker]: ['#ConfirmChecklistComplete'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Confirm checklist complete',
      description: '6. Confirm checklist complete'
    },
    Worth: <Worth>{
      maximum: 10,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Note Time of Notification and Source
    '#ID': '#NoteTimeOfNotificationAndSource',
    [CTagMarker]: ['#NoteTimeOfNotificationAndSource'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Note the Time of Notification and Source',
      description: '2. Note the Time of Notification and Source'
    },
    Worth: <Worth>{
      maximum: 10,
      weight: 1
    },
    Timer: <Timer>{
      limit: 10000,
      expired: false,
      startTime: DATE_MAX
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Note Significant Weather Information
    '#ID': '#NoteSignificantWeatherInformation',
    [CTagMarker]: ['#NoteSignificantWeatherInformation'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Note Significant Weather Information',
      description: '3. Note Significant Weather Information'
    },
    Worth: <Worth>{
      maximum: 10,
      weight: 1
    },
    Timer: <Timer>{
      limit: 10000,
      expired: false,
      startTime: DATE_MAX
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Significant WX QRC Checklist
    '#ID': '1001',
    [CTagMarker]: ['1001'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: '#ConfirmChecklist' },
        { type: EReferenceType.Entity, id: '#NoteTimeOfNotificationAndSource' },
        {
          type: EReferenceType.Entity,
          id: '#NoteSignificantWeatherInformation'
        },
        { type: EReferenceType.Entity, id: '#InitiateTornadoWarningChecklist' },
        { type: EReferenceType.Entity, id: '#Notify' },
        { type: EReferenceType.Entity, id: '#ConfirmChecklistComplete' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Significant WX QRC',
      description: 'Significant WX QRC'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Secondary Objectives Checklist
    '#ID': '#SecondaryObjectives',
    [CTagMarker]: ['#SecondaryObjectives'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Secondary Objectives',
      description: 'Ad hoc tasks'
    },
    Series: <Series>{
      id: Series.UNIDENTIFIED,
      title: 'Secondary Objectives'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // WG/CC Tapper
    '#ID': WGCC_TAPPER,
    [CTagMarker]: [WGCC_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'WG/CC',
      description: '5a. WG/CC (KPD)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: WGCC_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: WGCC_TARGET
    },
    Brain: <Brain>{
      delegate: BrainDelegateType.TAPPER
    }
  },
  {
    // TA Tapper
    '#ID': '#TATapper',
    [CTagMarker]: ['#TATapper'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'TA',
      description: '5c. Notify TA (HL)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: '#TATarget'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: '#TATarget'
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // MOC Tapper
    '#ID': '#MOCTapper',
    [CTagMarker]: ['#MOCTapper'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'MOC',
      description: '5d. Notify MOC (HL)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: '#MOCTarget'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: '#MOCTarget'
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // CE Tapper
    '#ID': '#CETapper',
    [CTagMarker]: ['#CETapper'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'CE',
      description: '5e. Notify CE (536-1340)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: '#CETarget'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: '#CETarget'
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // OG/CC Tapper
    '#ID': '#OGCCTapper',
    [CTagMarker]: ['#OGCCTapper'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'OG/CC',
      description: '5f. Notify OG/CC (KPD)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: '#OGCCTarget'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: '#OGCCTarget'
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // MSG/CC Tapper
    '#ID': '#MSGCCTapper',
    [CTagMarker]: ['#MSGCCTapper'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'MSG/CC',
      description: '5g. Notify MSG/CC (KPD)'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: MSGCC_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: MSGCC_TARGET
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // Mock Checklist 1
    '#ID': '1004',
    [CTagMarker]: ['1004'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Active Air Scramble',
      description: 'Mock Checklist 1 Description'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    Disable: {}
  },
  {
    // Mock Checklist 2
    '#ID': '1005',
    [CTagMarker]: ['1005'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Runway Alert',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    Disable: {}
  },
  {
    // Mock Checklist 3
    '#ID': '1006',
    [CTagMarker]: ['1006'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'FPCON Declaration',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '200',
      title: 'Aircraft Missiles Satellites '
    },
    Disable: {}
  },
  {
    // Mock Checklist 4
    '#ID': '1007',
    [CTagMarker]: ['1007'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Active Shooter/Lockdown',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '200',
      title: 'Aircraft Missiles Satellites '
    },
    Disable: {}
  },
  {
    // Mock Checklist 5
    '#ID': '1008',
    [CTagMarker]: ['1008'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'OPREP-3 Transverse',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '300',
      title: 'Recalls'
    },
    Disable: {}
  }
];
