import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goalMessage = {
    dialog: 'Message Received, Out.',
    edges: [],
    goalNode: true
  };

  const thirdMessageNode = new Node(
    goalMessage.dialog,
    goalMessage.edges,
    goalMessage.goalNode
  );

  const thirdOptions: Branch[] = [
    new Branch(
      'All other personnel have been evacuated. The cordon is set at 1000 meters, and BPD SWAT is en route. No other information is available at this time.',
      100,
      thirdMessageNode,
      false
    ),
    new Branch(
      'All personnel have been evacuated. Cordon is set. BPD en route. No other information is available at this time.',
      75,
      thirdMessageNode,
      false
    ),
    new Branch(
      'Everyone else is out, the cordon is set up, and Cops are en route!',
      50,
      thirdMessageNode,
      false
    ),
    new Branch('Take Cover, Sir, save yourself.', 0, thirdMessageNode, false)
  ];

  const secondMessage = {
    dialog: 'Understood, Continue.',
    edges: thirdOptions,
    goalNode: false
  };

  const secondMessageNode = new Node(
    secondMessage.dialog,
    secondMessage.edges,
    secondMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch(
      'Sir, two masked gunmen have barricaded themselves in Green Beans Coffee Shop, and are holding the three employees hostage.',
      100,
      secondMessageNode,
      false
    ),
    new Branch(
      'Report of Active Shooter in Green Beans Coffee Shop; there are three hostages.',
      75,
      secondMessageNode,
      false
    ),
    new Branch('Active Shooter at Green Beans!', 50, secondMessageNode, false),
    new Branch(
      'Masked gunmen have taken over the Coffee Shop, with innocents trapped inside!',
      0,
      secondMessageNode,
      false
    )
  ];

  const firstMessage = {
    dialog: 'Go ahead.',
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch(
      'Sir, this is Command Post with an Active Shooter Situation.',
      100,
      firstMessageNode,
      false
    ),
    new Branch(
      'Command Post here reporting an Active Shooter Situation.',
      75,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, Command Post here, Active Shooter at BX.',
      50,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, there are gunman at the Green Beans, Sir.',
      0,
      firstMessageNode,
      false
    )
  ];

  const root = {
    dialog: 'This is Colonel Western',
    edges: firstOptions,
    goalNode: false
  };
  return new Node(root.dialog, root.edges, root.goalNode);
}
