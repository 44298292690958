import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goalMessage = {
    dialog: '',
    branches: [],
    goalNode: true
  };

  const goalMessageNode = new Node(
    goalMessage.dialog,
    goalMessage.branches,
    goalMessage.goalNode
  );

  const secondMessage = {
    dialog: 'Out.',
    edges: [new Branch('', -1, goalMessageNode, true)],
    goalNode: false
  };

  const secondMessageNode = new Node(
    secondMessage.dialog,
    secondMessage.edges,
    secondMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch('Copy all', 100, secondMessageNode, false),
    new Branch('Copy', 100, secondMessageNode, false),
    new Branch('Understood', 100, secondMessageNode, false),
    new Branch('Continue', 100, secondMessageNode, false)
  ];

  const firstMessage = {
    dialog:
      'We have an active shooter situation at the base exchange. As of now, two masked gunmen have barricaded themselves in Green Beans Coffee Shop and are holding the three employees hostage. All other personnel have been evacuated. The cordon is set at 1000 meters, and BPD SWAT is en route. No other information is available at this time.',
    branches: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.branches,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch('Go ahead', 100, firstMessageNode, false),
    new Branch('Proceed', 100, firstMessageNode, false),
    new Branch('Copy, ready for details', 100, firstMessageNode, false),
    new Branch('Continue', 100, firstMessageNode, false)
  ];

  const root = {
    dialog: 'This is SSgt Metzmaker at BDOC, with an emergency notification',
    branches: firstOptions,
    goalNode: false
  };
  const rootNode = new Node(root.dialog, root.branches, root.goalNode);

  const initialOptions: Branch[] = [
    new Branch('Command Post, how can I help you?', 100, rootNode, false),
    new Branch('Command Post', 100, rootNode, false),
    new Branch('Base Ops', 100, rootNode, false),
    new Branch('Send Traffic', 100, rootNode, false)
  ];
  return new Node('', initialOptions, false);
}
