import {
  createSystem,
  IEntity,
  queryComponents,
  Read,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { shallowRef, ShallowRef } from 'vue';
import { ComponentException } from '../types';

export class ToggleCommand {
  entries: ToggleCommand[] = [];
}

export interface ActiveViewmodel {
  active: boolean;
}

export class Active {
  constructor(public active = false) {}
}

export class ActiveSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<ActiveViewmodel> = shallowRef({
      active: false
    })
  ) {}
}

export const useActive = (entity: IEntity) => {
  const active = entity.getComponent(Active);
  if (!active) {
    throw new ComponentException(entity.id, 'Active component not found');
  }
  const ref = shallowRef<ActiveViewmodel>({
    active: false
  });
  const synchronizationComponent = new ActiveSynchronizationComponent(ref);
  entity.addComponent(synchronizationComponent);
  return ref;
};

export const ActiveViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      active: Read(Active),
      viewModel: Write(ActiveSynchronizationComponent)
    })
  })
    .withName('ActiveViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { active, viewModel } of query.iter()) {
        viewModel.shallowReference.value = {
          active: active.active
        };
      }
    })
    .build();
};

export const ActiveToggleCommandSystem = () => {
  return createSystem({
    query: queryComponents({
      active: Write(Active),
      toggleCommand: Read(ToggleCommand)
    })
  })
    .withName('ActiveToggleSystem')
    .withRunFunction(({ query }) => {
      for (const { active, toggleCommand } of query.iter()) {
        toggleCommand.entries.forEach(() => {
          active.active = !active.active;
        });
      }
    })
    .build();
};

export const ClearActiveToggleSystem = () => {
  return createSystem({
    query: queryComponents({
      toggleCommand: Write(ToggleCommand)
    })
  })
    .withName('ClearActiveToggleSystem')
    .withRunFunction(({ query }) => {
      for (const { toggleCommand } of query.iter()) {
        toggleCommand.entries = [];
      }
    })
    .build();
};
