export enum Icon {
  EMAIL_ORANGE,
  GIANT_VOICE_ORANGE,
  PHONE_ORANGE,
  RADIO_ORANGE,
  NONE,
  AIRPLANE,
  ACHIEVEMENT_TROPHY,
  BADGE_STAR,
  BADGE_BLANK,
  BADGE_DEEP_DIVE,
  BADGE_LASER_PRECISION,
  BADGE_LIFT_OFF,
  BADGE_SEVEN_DAY_STREAK,
  BADGE_FIVE_DAY_STREAK,
  BADGE_SPEEDY,
  BULLSEYE,
  BOOK_INFO,
  CALENDAR,
  CHART,
  CHART_BAR,
  CHECK,
  CHEVRON_DOWN,
  CHEVRON_RIGHT,
  CHEVRON_UP_LIGHT,
  CLOSE_SHRINK,
  CLOCK_EMPHASIZED,
  DOUBLE_CHEVRON_DOWN,
  DOUBLE_CHEVRON_UP,
  DOUBLE_CHEVRON_LEFT,
  DOUBLE_CHEVRON_RIGHT,
  DOUBLE_TRIANGLE_WARNING,
  DOCUMENT,
  EXPAND_RUST,
  EMERGENCY,
  FUNCTION,
  GRADE_E,
  GRADE_F,
  GRADE_G,
  GRADE_NG,
  GRADE_U,
  PAUSE_DARK,
  PAUSE_LIGHT,
  PLAN,
  PLAY,
  PLUS,
  PLUS_CIRCLE,
  PEOPLE,
  PEOPLE_STAR,
  PHONE_BADGE,
  COHORTS,
  LOADING,
  LOGO,
  MINUS,
  MINUS_CIRCLE,
  NAVIGATE_LEFT_BUTTON,
  NAVIGATE_RIGHT_BUTTON,
  RED_CROSS,
  RED_X,
  SEARCH,
  SELECT_O,
  SECURITY,
  SHRINK_RUST,
  STAR,
  TABLE,
  TEXT_DETAILS_DECORATION,
  TRASH,
  TREND_UP,
  TREND_DOWN,
  TREND_NONE,
  TRIPPLE_CHEVRON_UP,
  TRIPPLE_CHEVRON_DOWN,
  RECOMMENDATION,
  WINGS,
  WHITE_CHECK,
  WEATHER,
  RED_CRITICAL,
  BLUE_CHECK,
  HALF_RED_CHECK,
  ICON_FIELD_RIGHT,
  UNKNOWN,
  URGENT_MESSAGE,
  EMPTY,
  CARDINALITY,
  CAUTION,
  GREEN_CHECK,
  MAIL,
  LOCK,
  SAGE_ARROW_DOWN,
  CLOSE_ICON,
  BASE_SIREN,
  CAMERA,
  EMAIL,
  GIANT_VOICE,
  PHONE,
  RADIO,
  CHECKLIST,
  PHONE_HANGUP,
  PHONE_ANSWER,
  PHONE_HANGUP_SMALL,
  PAGE,
  SEND_ARROW,
  VISIBILITY,
  VISIBILITY_LIGHT,
  ALERT_ORANGE,
  REDO,
  UNDO,
  GREEN_CHECKBOX,
  WARNING_CHECKBOX,
  RUST_CHECKBOX,
  FOLDER_DARK,
  FOLDER_LIGHT,
  HOME
}

export const IconMap: Record<Icon, string> = {
  [Icon.NONE]: require('../../assets/icons/bullseye.svg'),
  [Icon.PHONE_ORANGE]: require('../../assets/icons/phone-orange.svg'),
  [Icon.EMAIL_ORANGE]: require('../../assets/icons/email-orange.svg'),
  [Icon.RADIO_ORANGE]: require('../../assets/icons/radio-orange.svg'),
  [Icon.GIANT_VOICE_ORANGE]: require('../../assets/icons/giant-voice-orange.svg'),
  [Icon.AIRPLANE]: require('../../assets/icons/airplane.svg'),
  [Icon.ACHIEVEMENT_TROPHY]: require('../../assets/icons/achievement_trophy.svg'),
  [Icon.BADGE_STAR]: require('../../assets/icons/badge_star.svg'),
  [Icon.BADGE_BLANK]: require('../../assets/icons/badge_blank.svg'),
  [Icon.BADGE_DEEP_DIVE]: require('../../assets/icons/badge_deep_dive.svg'),
  [Icon.BADGE_LASER_PRECISION]: require('../../assets/icons/badge_laser_precision.svg'),
  [Icon.BADGE_LIFT_OFF]: require('../../assets/icons/badge_lift_off.svg'),
  [Icon.BADGE_SEVEN_DAY_STREAK]: require('../../assets/icons/badge_seven_day_streak.svg'),
  [Icon.BADGE_FIVE_DAY_STREAK]: require('../../assets/icons/badge_five_day_streak.svg'),
  [Icon.BADGE_SPEEDY]: require('../../assets/icons/badge_speedy.svg'),
  [Icon.BULLSEYE]: require('../../assets/icons/bullseye.svg'),
  [Icon.BOOK_INFO]: require('../../assets/icons/book_info.svg'),
  [Icon.CALENDAR]: require('../../assets/icons/calendar.svg'),
  [Icon.CHART]: require('../../assets/icons/chart.svg'),
  [Icon.CHART_BAR]: require('../../assets/icons/chart_bar.svg'),
  [Icon.CHECK]: require('../../assets/icons/check.svg'),
  [Icon.CHEVRON_DOWN]: require('../../assets/icons/chevron_down.svg'),
  [Icon.CHEVRON_RIGHT]: require('../../assets/icons/chevron_right.svg'),
  [Icon.CHEVRON_UP_LIGHT]: require('../../assets/icons/chevron_up_light.svg'),
  [Icon.CLOSE_SHRINK]: require('../../assets/icons/close_shrink.svg'),
  [Icon.CLOCK_EMPHASIZED]: require('../../assets/icons/clock_emphasized.svg'),
  [Icon.DOUBLE_CHEVRON_DOWN]: require('../../assets/icons/double_chevron_down.svg'),
  [Icon.DOUBLE_CHEVRON_UP]: require('../../assets/icons/double_chevron_up.svg'),
  [Icon.DOUBLE_CHEVRON_LEFT]: require('../../assets/icons/double_chevron_left.svg'),
  [Icon.DOUBLE_CHEVRON_RIGHT]: require('../../assets/icons/double_chevron_right.svg'),
  [Icon.DOUBLE_TRIANGLE_WARNING]: require('../../assets/icons/double_triangle_warning.svg'),
  [Icon.DOCUMENT]: require('../../assets/icons/document.svg'),
  [Icon.EXPAND_RUST]: require('../../assets/icons/expand_rust.svg'),
  [Icon.EMERGENCY]: require('../../assets/icons/emergency.svg'),
  [Icon.FUNCTION]: require('../../assets/icons/function.svg'),
  [Icon.PAUSE_DARK]: require('../../assets/icons/pause_dark.svg'),
  [Icon.PAUSE_LIGHT]: require('../../assets/icons/pause_light.svg'),
  [Icon.PLAN]: require('../../assets/icons/plan.svg'),
  [Icon.PLAY]: require('../../assets/icons/play.svg'),
  [Icon.PLUS]: require('../../assets/icons/plus.svg'),
  [Icon.PLUS_CIRCLE]: require('../../assets/icons/plus_circle.svg'),
  [Icon.PEOPLE]: require('../../assets/icons/people.svg'),
  [Icon.PEOPLE_STAR]: require('../../assets/icons/people_star.svg'),
  [Icon.PHONE_BADGE]: require('../../assets/icons/phone_badge.svg'),
  [Icon.COHORTS]: require('../../assets/icons/cohorts.svg'),
  [Icon.LOADING]: require('../../assets/icons/loading.svg'),
  [Icon.LOGO]: require('../../assets/icons/logo.svg'),
  [Icon.MINUS]: require('../../assets/icons/minus.svg'),
  [Icon.MINUS_CIRCLE]: require('../../assets/icons/minus_circle.svg'),
  [Icon.NAVIGATE_LEFT_BUTTON]: require('../../assets/icons/navigate_left_button.svg'),
  [Icon.NAVIGATE_RIGHT_BUTTON]: require('../../assets/icons/navigate_right_button.svg'),
  [Icon.SEARCH]: require('../../assets/icons/search.svg'),
  [Icon.SELECT_O]: require('../../assets/icons/select_o.svg'),
  [Icon.SECURITY]: require('../../assets/icons/security.svg'),
  [Icon.SHRINK_RUST]: require('../../assets/icons/shrink_rust.svg'),
  [Icon.STAR]: require('../../assets/icons/star.svg'),
  [Icon.TEXT_DETAILS_DECORATION]: require('../../assets/icons/text_details_decoration.svg'),
  [Icon.TRASH]: require('../../assets/icons/trash-can-regular.svg'),
  [Icon.TREND_UP]: require('../../assets/icons/trend-up.svg'),
  [Icon.TREND_DOWN]: require('../../assets/icons/trend-down.svg'),
  [Icon.TREND_NONE]: require('../../assets/icons/trend-none.svg'),
  [Icon.TRIPPLE_CHEVRON_DOWN]: require('../../assets/icons/tripple_chevron_down.svg'),
  [Icon.TRIPPLE_CHEVRON_UP]: require('../../assets/icons/tripple_chevron_up.svg'),
  [Icon.WEATHER]: require('../../assets/icons/weather.svg'),
  [Icon.GRADE_E]: require('../../assets/icons/grade-E.svg'),
  [Icon.GRADE_F]: require('../../assets/icons/grade-F.svg'),
  [Icon.GRADE_G]: require('../../assets/icons/grade-G.svg'),
  [Icon.GRADE_NG]: require('../../assets/icons/grade-NG.svg'),
  [Icon.GRADE_U]: require('../../assets/icons/grade-U.svg'),
  [Icon.WINGS]: require('../../assets/icons/wings.svg'),
  [Icon.WHITE_CHECK]: require('../../assets/icons/white_check.svg'),
  [Icon.TABLE]: require('../../assets/icons/table.svg'),
  [Icon.RECOMMENDATION]: require('../../assets/icons/recommendation.svg'),
  [Icon.CARDINALITY]: require('../../assets/icons/bullseye.svg'),
  [Icon.RED_CROSS]: require('../../assets/icons/red-cross.svg'),
  [Icon.RED_X]: require('../../assets/icons/red-x.svg'),
  [Icon.RED_CRITICAL]: require('../../assets/icons/full_red_critical.svg'),
  [Icon.BLUE_CHECK]: require('../../assets/icons/blue_check.svg'),
  [Icon.HALF_RED_CHECK]: require('../../assets/icons/half_red_check.svg'),
  [Icon.ICON_FIELD_RIGHT]: require('../../assets/icons/icon_field_right.svg'),
  [Icon.UNKNOWN]: require('../../assets/icons/unknown.svg'),
  [Icon.URGENT_MESSAGE]: require('../../assets/icons/urgent_message.svg'),
  [Icon.EMPTY]: require('../../assets/icons/empty.svg'),
  [Icon.CAUTION]: require('../../assets/icons/caution.svg'),
  [Icon.GREEN_CHECK]: require('../../assets/icons/green_check.svg'),
  [Icon.MAIL]: require('../../assets/icons/mail.svg'),
  [Icon.LOCK]: require('../../assets/icons/lock.svg'),
  [Icon.SAGE_ARROW_DOWN]: require('../../assets/icons/sage_arrow_down.svg'),
  [Icon.CLOSE_ICON]: require('../../assets/icons/close_icon.svg'),
  [Icon.BASE_SIREN]: require('../../assets/icons/inventory/base_siren.svg'),
  [Icon.CAMERA]: require('../../assets/icons/inventory/camera.svg'),
  [Icon.EMAIL]: require('../../assets/icons/inventory/email.svg'),
  [Icon.GIANT_VOICE]: require('../../assets/icons/inventory/giant_voice.svg'),
  [Icon.PHONE]: require('../../assets/icons/inventory/phone.svg'),
  [Icon.SEND_ARROW]: require('../../assets/icons/send_arrow.svg'),
  [Icon.RADIO]: require('../../assets/icons/inventory/radio.svg'),
  [Icon.CHECKLIST]: require('../../assets/icons/inventory/checklist.svg'),
  [Icon.PHONE_HANGUP]: require('../../assets/icons/phone_hangup.svg'),
  [Icon.PHONE_ANSWER]: require('../../assets/icons/phone_answer.svg'),
  [Icon.PHONE_HANGUP_SMALL]: require('../../assets/icons/phone_hangup_small.svg'),
  [Icon.PAGE]: require('../../assets/icons/page.svg'),
  [Icon.VISIBILITY]: require('../../assets/icons/visibility.svg'),
  [Icon.VISIBILITY_LIGHT]: require('../../assets/icons/visibility_light.svg'),
  [Icon.ALERT_ORANGE]: require('../../assets/icons/alert_orange.svg'),
  [Icon.UNDO]: require('../../assets/icons/undo.svg'),
  [Icon.REDO]: require('../../assets/icons/redo.svg'),
  [Icon.GREEN_CHECKBOX]: require('../../assets/icons/checkbox/green_checkbox.svg'),
  [Icon.RUST_CHECKBOX]: require('../../assets/icons/checkbox/rust_checkbox.svg'),
  [Icon.WARNING_CHECKBOX]: require('../../assets/icons/checkbox/warning_checkbox.svg'),
  [Icon.FOLDER_DARK]: require('../../assets/icons/folder_dark.svg'),
  [Icon.FOLDER_LIGHT]: require('../../assets/icons/folder_light.svg'),
  [Icon.HOME]: require('../../assets/icons/home.svg')
};

export default Icon;
