import {
  createSystem,
  queryComponents,
  Read,
  ReadEntity,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { Conversation, Dialog } from '../conversation/Conversation';
import { Inbox } from '../inbox/component';
import { Outbox } from '../outbox/Outbox';
import { ConversationSingleton } from '../conversation/ConversationSingleton';
import { Brain, Context } from '../brains/Brain';
import { Node } from './component';
import { Completable, CompleteCommand } from '../completable/Completable';

export const LinearAiDialogSystem = (conversations: ConversationSingleton) => {
  return createSystem({
    query: queryComponents({
      inbox: Read(Inbox),
      outbox: Write(Outbox),
      brain: Write(Brain),
      conversation: Read(Conversation),
      entity: ReadEntity()
    })
  })
    .withName('LinearAiDialogSystem')
    .withRunFunction(({ query }) => {
      for (const {
        inbox,
        brain,
        outbox,
        conversation,
        entity
      } of query.iter()) {
        const context: Context = {
          inbox,
          outbox,
          conversations,
          conversation,
          isGoalNode: false,
          accumulatedWeightAverage: brain.acceptableWeightAverage,
          completable: new Completable(),
          completeCommand: new CompleteCommand(),
          dialog: new Dialog(new Node()),
          incomingMessage: undefined
        };
        const incomingMessage = inbox.stream.pop();
        if (incomingMessage) {
          inbox.stream.unshift(incomingMessage);
          switch (incomingMessage.messageType) {
            case 'Media Session':
              brain.sense(context);
              if (context.incomingMessage) {
                brain.act(brain.evaluate(context));
              }
              break;
            case 'INVITE':
              context.incomingMessage = incomingMessage;
              brain.act(brain.evaluate(context));
              break;
            case '200 OK':
              outbox.stream.push({ messageType: 'ACK', payload: 'Ack' });
              break;
            case 'ACK':
              context.incomingMessage = incomingMessage;
              brain.act(brain.evaluate(context));
              break;
            case 'BYE':
              entity.removeComponent(Conversation);
              break;
            case '100 trying':
            case '180 Ringing':
          }
        }
        if (incomingMessage?.messageType !== 'INVITE') {
          context.inbox.stream.length = 0;
        }
      }
    })
    .build();
};
