import {
  createSystem,
  IEntity,
  queryComponents,
  Read,
  ReadEntity,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { ShallowRef, shallowRef, watch } from 'vue';
import { Description, DescriptionViewModel } from '../description/main';
import { Disable } from '../disable/component';
import { pointConstraintEvaluation } from '../point/main';
import { Series } from '../series/main';
import Timer, { DATE_MAX } from '../timer/main';
import { ComponentException } from '../types';

export const COMPLETE = true;
export const INCOMPLETE = !COMPLETE;

type CompleteConstraints = 'on children' | 'on self';
type ActiveConstraints = 'order children' | 'parallel children' | 'none';
type CompleteEvaluationConstraint = 'system' | 'user';
type PointConstraint = 'user-active' | 'system-active';
export enum CompletionQuality {
  Pristine = 0,
  IncorrectResponse = 1 << 0,
  TimeExpired = 1 << 1
}
export const CompletionQualityMessages: Map<CompletionQuality, string> =
  new Map([
    [CompletionQuality.Pristine, 'This object is pristine, no problems here'],
    [CompletionQuality.IncorrectResponse, 'The response was incorrect'],
    [CompletionQuality.TimeExpired, 'Time Expired!']
  ]);

export interface CompletableViewModel {
  children: CompletableViewModel[];
  value: boolean;
  active: boolean;
  userSelectedValue: boolean;
  userSelectedActive: boolean;
}

export function checklistViewModelToJson(
  checklistViewModel: ChecklistViewModel
): SerializedChecklist {
  return {
    children: checklistViewModel.children.map((child) => {
      return checklistViewModelToJson(child);
    }),
    complete: checklistViewModel.complete,
    active: checklistViewModel.active,
    userSelectedComplete: checklistViewModel.userSelectedComplete,
    userSelectedActive: checklistViewModel.userSelectedActive,
    description: checklistViewModel.description,
    series: checklistViewModel.series,
    compromised: checklistViewModel.compromised
  };
}

export interface SerializedChecklist {
  children: SerializedChecklist[];
  complete: boolean;
  active: boolean;
  userSelectedComplete: boolean;
  userSelectedActive: boolean;
  description: DescriptionViewModel;
  series: Series;
  compromised: boolean;
}

export interface ChecklistViewModel {
  children: ChecklistViewModel[];
  complete: boolean;
  active: boolean;
  userSelectedComplete: boolean;
  userSelectedActive: boolean;
  description: DescriptionViewModel;
  series: Series;
  timer?: { remaining: number; limit: number };
  compromised: boolean;
}

export interface ChecklistComposable {
  checklist: ShallowRef<ChecklistViewModel>;
  children: ChecklistItemComposable[];
  enabled: boolean;
}

export interface ChecklistItemComposable {
  checklist: ShallowRef<ChecklistViewModel>;
  children: ChecklistItemComposable[];
  enabled: boolean;
  setComplete: (value: boolean) => void;
  setActive: (value: boolean) => void;
}

export class OnComplete {
  constructor(public entity: IEntity) {}
}

export class Completable {
  constructor(
    public children: IEntity[] = [],
    public completeConstraints: CompleteConstraints = 'on children',
    public activeConstraints: ActiveConstraints = 'none',
    public root = false,
    public completeEvaluationConstraints: CompleteEvaluationConstraint[] = [],
    public pointConstraint: PointConstraint[] = []
  ) {}
  outputValue = false;
  aiValue = false;
  aiActive = false;
  userValue = false;
  userActive = false;
  completionQuality: { state: CompletionQuality } = {
    state: CompletionQuality.Pristine
  };
  snapshot: { previous: { active: boolean; complete: boolean } } = {
    previous: { active: false, complete: false }
  };
}

const completionConstraintMap = new Map<
  CompleteEvaluationConstraint,
  (completable: Completable) => boolean
>([
  [
    'user',
    (completable) => {
      return completable.userValue;
    }
  ],
  [
    'system',
    (completable) => {
      return completable.aiValue;
    }
  ]
]);

export const CompleteEvaluationSystem = () => {
  return createSystem({
    query: queryComponents({
      completable: Write(Completable),
      completableCommand: Write(CompleteCommand),
      entity: ReadEntity()
    })
  })
    .withName('CompleteEvaluationSystem')
    .withRunFunction(({ query }) => {
      function defaultHandler(completable: Completable) {
        return completable.aiValue;
      }
      for (const { completableCommand, completable } of query.iter()) {
        const previous = completable.outputValue;
        completable.outputValue = completable.completeEvaluationConstraints
          .map(function isConstraintMet(constraint) {
            return (
              completionConstraintMap.get(constraint) || defaultHandler
            )(completable);
          })
          .every((constraintMet) => constraintMet);
        if (
          completable.outputValue !== previous &&
          completable.outputValue === COMPLETE
        ) {
          completableCommand.entries.push(new CompleteCommand());
        }
      }
    })
    .build();
};

export class CompleteCommand {
  entries: CompleteCommand[] = [];
}

export class CompletableSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<CompletableViewModel> = shallowRef<CompletableViewModel>(
      {
        children: [],
        value: INCOMPLETE,
        active: false,
        userSelectedValue: INCOMPLETE,
        userSelectedActive: false
      }
    )
  ) {}
}

export class ChecklistSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<ChecklistViewModel> = shallowRef<ChecklistViewModel>(
      {
        children: [],
        complete: INCOMPLETE,
        active: false,
        userSelectedComplete: INCOMPLETE,
        userSelectedActive: false,
        description: { description: '', title: '', subtitle: '' },
        series: { id: '', title: '' },
        compromised: false
      }
    )
  ) {}
}

export const serializeCompletable = (
  completable: Completable
): CompletableViewModel => {
  return {
    active: completable.aiActive,
    value: completable.aiValue,
    userSelectedValue: completable.userValue,
    userSelectedActive: completable.userActive,
    children: completable.children.map((child) => {
      const completable = child.getComponent(Completable);
      if (!completable) {
        throw new ComponentException(child.id, 'Child was not a completable');
      }
      return serializeCompletable(completable);
    })
  };
};

function serializeTimer(
  timer: Timer | undefined
): { remaining: number; limit: number } | undefined {
  if (timer && timer.startTime <= Date.now()) {
    const timeRemaining = Math.max(
      0,
      timer.limit - (Date.now() - timer.startTime)
    );
    return { remaining: timeRemaining, limit: timer.limit };
  }
  return;
}

function isCompromised(completable: Completable) {
  return !(
    completable.completionQuality.state === CompletionQuality.Pristine &&
    pointConstraintEvaluation(completable)
  );
}

export const serializeChecklist = (
  completable: Completable,
  description: Description,
  timer: Timer | undefined,
  series: Series | undefined
): ChecklistViewModel => {
  return {
    description: {
      description: description.description,
      title: description.title,
      subtitle: description.subtitle
    },
    series: {
      id: series?.id || '',
      title: series?.title || ''
    },
    compromised: isCompromised(completable),
    active: completable.aiActive,
    complete: completable.outputValue,
    userSelectedComplete: completable.userValue,
    userSelectedActive: completable.userActive,
    timer: serializeTimer(timer),
    children: completable.children.map((child) => {
      const completable = child.getComponent(Completable);
      if (!completable) {
        throw new ComponentException(
          child.id,
          'Child was not a checklist. Missing Completable component'
        );
      }
      const description = child.getComponent(Description);
      if (!description) {
        throw new ComponentException(
          child.id,
          'Child was not a checklist. Missing Description component'
        );
      }
      const series = child.getComponent(Series);
      return serializeChecklist(
        completable,
        description,
        child.getComponent(Timer),
        series
      );
    })
  };
};

export const CompletableViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      completable: Write(Completable),
      viewModel: Write(CompletableSynchronizationComponent)
    })
  })
    .withName('CompletableViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { completable, viewModel } of query.iter()) {
        viewModel.shallowReference.value = serializeCompletable(completable);
      }
    })
    .build();
};

export const ChecklistViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      completable: Write(Completable),
      description: Read(Description),
      viewModel: Write(ChecklistSynchronizationComponent),
      entity: ReadEntity()
    })
  })
    .withName('ChecklistViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const {
        completable,
        viewModel,
        description,
        entity
      } of query.iter()) {
        const series = entity.getComponent(Series);
        const timer = entity.getComponent(Timer);
        viewModel.shallowReference.value = serializeChecklist(
          completable,
          description,
          timer,
          series
        );
      }
    })
    .build();
};

function setActivityOnActiveConstraint(completable: Completable) {
  function isComponent(
    component: Completable | undefined
  ): component is Completable {
    return !!component;
  }
  function setLevel(level: Completable[], state: boolean) {
    level.forEach((child) => {
      child.aiActive = state;
    });
  }
  const INACTIVE = false;
  const ACTIVE = true;
  const completablesFromChildren = completable.children
    .map((child) => child.getComponent(Completable))
    .filter(isComponent);

  if (completable.children.length === 0) {
    completable.aiActive = ACTIVE;
  } else {
    switch (completable.activeConstraints) {
      case 'order children': {
        const firstIncompleteChildIndex = completablesFromChildren.findIndex(
          (child) => {
            return (
              child.aiValue === INCOMPLETE || child.outputValue === INCOMPLETE
            );
          }
        );
        const foundIncompleteChild = firstIncompleteChildIndex !== -1;
        setLevel(completablesFromChildren, INACTIVE);
        if (foundIncompleteChild) {
          const incompleteCompletable =
            completablesFromChildren[firstIncompleteChildIndex];
          incompleteCompletable.aiActive = ACTIVE;
          setActivityOnActiveConstraint(incompleteCompletable);
        }
        break;
      }
      case 'parallel children':
      case 'none': {
        completablesFromChildren.forEach((child) => {
          child.aiActive = !child.aiValue;
          setActivityOnActiveConstraint(child);
        });
        break;
      }
    }
  }
}

export const SetCompletableActiveStateSystem = () => {
  return createSystem({
    query: queryComponents({
      completable: Write(Completable)
    })
  })
    .withName('SetCompletableActiveStateSystem')
    .withRunFunction(({ query }) => {
      for (const { completable } of query.iter()) {
        if (completable.root) {
          if (completable.outputValue === true) {
            completable.aiActive = false;
          } else {
            completable.aiActive = true;
          }
          setActivityOnActiveConstraint(completable);
        }
      }
    })
    .build();
};

export const AutoStartTimedCompletable = () => {
  return createSystem({
    query: queryComponents({
      completable: Read(Completable),
      timer: Write(Timer)
    })
  })
    .withName('AutoStartTimedCompletable')
    .withRunFunction(({ query }) => {
      const now = Date.now();
      for (const { completable, timer } of query.iter()) {
        if (completable.aiActive && timer.startTime === DATE_MAX) {
          timer.startTime = now;
        }
      }
    })
    .build();
};

export const AutoStopTimedCompletable = () => {
  return createSystem({
    query: queryComponents({
      completable: Read(Completable),
      timer: Read(Timer),
      entity: ReadEntity()
    })
  })
    .withName('AutoStartTimedCompletable')
    .withRunFunction(({ query }) => {
      for (const { completable, entity } of query.iter()) {
        if (completable.outputValue) {
          entity.removeComponent(Timer);
        }
      }
    })
    .build();
};

export function useChecklist(entity: IEntity): ChecklistComposable {
  const completable = entity.getComponent(Completable);
  if (!completable) {
    throw new ComponentException(
      entity.id,
      'Entity is not a checklist. Missing Completable component'
    );
  }

  const description = entity.getComponent(Description);
  if (!description) {
    throw new ComponentException(
      entity.id,
      'Entity is not a checklist. Missing Description component'
    );
  }

  const series = entity.getComponent(Series);

  let synchronizationComponent = entity.getComponent(
    ChecklistSynchronizationComponent
  );

  if (!synchronizationComponent) {
    const ref = shallowRef<ChecklistViewModel>(
      serializeChecklist(
        completable,
        description,
        entity.getComponent(Timer),
        series
      )
    );
    synchronizationComponent = new ChecklistSynchronizationComponent(ref);
    entity.addComponent(synchronizationComponent);
  }

  const didFindDisabledComponent = entity.getComponent(Disable);
  const enabled = !didFindDisabledComponent;

  return {
    checklist: synchronizationComponent.shallowReference,
    children: completable.children.map((child) => useChecklistItem(child)),
    enabled
  };
}

export function useChecklistItem(entity: IEntity): ChecklistItemComposable {
  const completable = entity.getComponent(Completable);
  if (!completable) {
    throw new ComponentException(
      entity.id,
      'Entity is not a ChecklistItem. Missing Completable component'
    );
  }
  const checklist = useChecklist(entity);
  return {
    checklist: checklist.checklist,
    children: completable.children.map((child) => useChecklistItem(child)),
    enabled: checklist.enabled,
    setComplete: (value: boolean) => {
      const completable = entity.getComponent(Completable);
      if (completable) {
        completable.userValue = value;
      }
    },
    setActive: (value: boolean) => {
      const completable = entity.getComponent(Completable);
      if (completable) {
        completable.userActive = value;
      }
    }
  };
}

export function useCompletable(entity: IEntity) {
  const completableComponent = entity.getComponent(Completable);
  if (!completableComponent) {
    throw new ComponentException(
      entity.id,
      'Entity is not a completable. Missing Completable component'
    );
  }
  const completableSynchronizationComponent = entity.getComponent(
    CompletableSynchronizationComponent
  );
  if (completableSynchronizationComponent) {
    return completableSynchronizationComponent.shallowReference;
  }
  const ref = shallowRef<CompletableViewModel>(
    serializeCompletable(completableComponent)
  );
  const completableViewModel = new CompletableSynchronizationComponent(ref);
  entity.addComponent(completableViewModel);

  return ref;
}

export function useCompletableChild(entity: IEntity) {
  const onCompleteListeners: (() => void)[] = [];
  const completable = useCompletable(entity);
  const stop = watch(completable, (newValue, oldValue) => {
    if (oldValue.value === false && newValue.value === true) {
      onCompleteListeners.forEach((fn) => fn());
      stop();
    }
  });
  return {
    completable: useCompletable(entity),
    onComplete: (fn: () => void) => {
      onCompleteListeners.push(fn);
    },
    setComplete: () => {
      const commands = entity.getComponent(CompleteCommand);
      if (commands) {
        commands.entries.push(new CompleteCommand());
      }
    }
  };
}

export const CompletableSystem = () => {
  return createSystem({
    query: queryComponents({
      completable: Write(Completable),
      entity: ReadEntity()
    })
  })
    .withName('CompletableSystem')
    .withRunFunction(({ query }) => {
      for (const { completable } of query.iter()) {
        const hasChildren = completable.children.length;
        if (completable.completeConstraints === 'on children' && hasChildren) {
          const completablesFromChildren = completable.children.map((child) =>
            child.getComponent(Completable)
          );
          const firstIncompleteChild = completablesFromChildren.findIndex(
            (child) => {
              return child && child.outputValue === INCOMPLETE;
            }
          );
          const foundIncompleteChild = firstIncompleteChild !== -1;
          const complete = !foundIncompleteChild;
          completable.aiValue = complete;
        } else if (completable.completeConstraints === 'on self') {
          const complete = completable.aiValue;
          completable.aiValue = complete;
        }
      }
    })
    .build();
};

export const CompleteCommandSystem = () => {
  return createSystem({
    query: queryComponents({
      completable: Write(Completable),
      completeCommand: Read(CompleteCommand)
    })
  })
    .withName('CompleteCommandSystem')
    .withRunFunction(({ query }) => {
      for (const { completable, completeCommand } of query.iter()) {
        completeCommand.entries.forEach(() => {
          completable.snapshot.previous = {
            active: completable.aiActive,
            complete: completable.aiValue
          };
          completable.aiValue = true;
        });
      }
    })
    .build();
};

export const ClearCompleteCommandSystem = () => {
  return createSystem({
    query: queryComponents({
      completable: Write(Completable),
      completeCommand: Write(CompleteCommand)
    })
  })
    .withName('ClearCompleteCommandSystem')
    .withRunFunction(({ query }) => {
      for (const { completeCommand } of query.iter()) {
        completeCommand.entries = [];
      }
    })
    .build();
};
