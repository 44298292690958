import firebase from 'firebase/app';
import 'firebase/auth';
import config from '@/config';
import { IAuthClient } from '@/services/auth';

export default class FirebaseAuthClient implements IAuthClient {
  private auth: firebase.auth.Auth;

  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config.firebase);
    }
    this.auth = firebase.auth();
    if (config.firebase.auth.local) {
      this.auth.useEmulator(config.firebase.auth.host);
    }
  }

  async signIn(email: string, pass: string): Promise<void> {
    await this.auth.signInWithEmailAndPassword(email, pass);
  }

  async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  async isEmailVerified(): Promise<boolean> {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      return currentUser.emailVerified;
    }
    return false;
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    await firebase.auth().sendPasswordResetEmail(email);
  }

  async sendEmailVerification(): Promise<void | Error> {
    if (this.auth.currentUser) {
      try {
        await this.auth.currentUser.sendEmailVerification({
          url: config.firebase.emailVerificationURL
        });
      } catch (error) {
        return error as Error;
      }
    }
  }

  async createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.User | null> {
    const { user } = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    return user;
  }
}
