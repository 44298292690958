import { Conversation, Dialog } from '../conversation/Conversation';
import { ConversationSingleton } from '../conversation/ConversationSingleton';
import { Node } from '../dialog/component';
import {
  createSystem,
  queryComponents,
  Read,
  ReadEntity,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import {
  Completable,
  CompleteCommand,
  CompletionQuality
} from '../completable/Completable';
import {
  Brain,
  Context,
  DelegateFunctions,
  getDialogFromConversation
} from './Brain';
import { Inbox } from '../inbox/component';
import { Outbox } from '../outbox/Outbox';

export function TapperBrain(context: Context, brain: Brain): DelegateFunctions {
  const sense = () => {
    return context;
  };
  const evaluate = () => {
    context.dialog = getDialogFromConversation(context);
    context.isGoalNode = context.dialog.getRoot().goalNode;
    context.accumulatedWeightAverage =
      context.dialog.getAccumulatedWeightAverage();
    return context;
  };
  const act = () => {
    const accumulatedWeightsPresent = !Number.isNaN(
      context.accumulatedWeightAverage
    );
    if (
      context.isGoalNode &&
      !context.completable.aiValue &&
      accumulatedWeightsPresent
    ) {
      if (context.accumulatedWeightAverage < brain.acceptableWeightAverage) {
        context.completable.completionQuality.state =
          CompletionQuality.IncorrectResponse;
      }
      context.completeCommand.entries.push(new CompleteCommand());
    }
  };
  const exit = () => {
    if (
      !context.isGoalNode ||
      context.accumulatedWeightAverage < brain.acceptableWeightAverage
    ) {
      context.completable.completionQuality.state =
        CompletionQuality.IncorrectResponse;
    }
    context.completeCommand.entries.push(new CompleteCommand());
  };
  return { sense, evaluate, act, exit };
}

export const TapperCompletionSystem = (
  conversations: ConversationSingleton
) => {
  return createSystem({
    query: queryComponents({
      brain: Read(Brain),
      conversation: Read(Conversation),
      completable: Read(Completable),
      completeCommand: Write(CompleteCommand),
      inbox: Read(Inbox),
      outbox: Read(Outbox),
      entity: ReadEntity()
    })
  })
    .withName('TapperCompletionSystem')
    .withRunFunction(({ query }) => {
      for (const {
        conversation,
        completable,
        completeCommand,
        brain,
        inbox,
        outbox
      } of query.iter()) {
        const context: Context = {
          inbox,
          outbox,
          conversations,
          conversation,
          isGoalNode: false,
          accumulatedWeightAverage: brain.acceptableWeightAverage,
          completable,
          completeCommand: completeCommand,
          dialog: new Dialog(new Node()),
          incomingMessage: undefined
        };
        const incomingMessage = inbox.stream.pop();
        brain.sense(context);
        if (context.conversation.conversationId) {
          switch (incomingMessage?.messageType) {
            case 'BYE':
              brain.exit(brain.evaluate(context));
              break;
            case 'Media Session':
              brain.act(brain.evaluate(context));
              break;
            default:
              break;
          }
        }
      }
    })
    .build();
};
