import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goal = {
    dialog: '',
    edges: [],
    goalNode: true
  };

  const goalNode = new Node(goal.dialog, goal.edges, goal.goalNode);

  const secondMessage = {
    dialog: 'Copy Raymond 30, thx for the assist, Flightline Ops, out.',
    edges: [new Branch('', -1, goalNode, true)],
    goalNode: false
  };

  const secondMessageNode = new Node(
    secondMessage.dialog,
    secondMessage.edges,
    secondMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch(
      'Flightline Ops, Raymond 30, I read you loud and clear, over.',
      100,
      secondMessageNode,
      false
    )
  ];

  const firstMessage = {
    dialog: 'This is Flightline Ops with a UHF radio check, how copy, over.',
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch(
      'Flightline Ops, this is Raymond 30, say again, you are broken and unreadable, over.',
      100,
      firstMessageNode,
      false
    )
  ];

  const root = {
    dialog: '…crackle…crackle…Flightline Ops…UHF…static…static…',
    edges: firstOptions,
    goalNode: false
  };
  return new Node(root.dialog, root.edges, root.goalNode);
}
