import {
  createSystem,
  IEntity,
  queryComponents,
  Read,
  ReadEntity,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { Outbox } from '../outbox/Outbox';
import { Subject } from 'rxjs';
import { Inbox } from '../inbox/component';
import { ShallowRef, shallowRef } from 'vue';
import { Branch, HistoryItem, Node } from '../dialog/component';
import { ConversationSingleton } from './ConversationSingleton';
import { MessageBroadcast } from '../../models/BuildableDialogItem';
import { Brain } from '../brains/Brain';
import { ComponentException } from '../types';

export enum ConversationState {
  OPEN,
  WAITING,
  ACTIVE,
  CLOSED
}

export class Conversation {
  constructor(
    public transport: Transport,
    public target: string,
    public conversationId = '',
    public state: ConversationState = ConversationState.OPEN
  ) {}
}

type TransportType = 'entity' | 'ws' | 'HTTP';
interface Transport {
  type: TransportType;
}
export interface ConversationViewModel {
  transport: Transport;
  target: string;
  conversationId: string;
  state: ConversationState;
}
export class ConversationSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<ConversationViewModel> = shallowRef({
      transport: { id: '', type: shallowReference.value.transport.type },
      conversationId: '',
      target: '',
      state: ConversationState.OPEN
    })
  ) {}
}

export function useConversation(entity: IEntity) {
  const conversationComponent = entity.getComponent(Conversation);
  if (!conversationComponent) {
    throw new ComponentException(entity.id, 'Conversation component not found');
  }
  const ref = shallowRef<ConversationViewModel>({
    transport: {
      type: conversationComponent.transport.type
    },
    target: conversationComponent.target,
    conversationId: conversationComponent.conversationId,
    state: ConversationState.OPEN
  });
  const synchronizationComponent = new ConversationSynchronizationComponent(
    ref
  );
  entity.addComponent(synchronizationComponent);
  return ref;
}

export type ConversationId = string;

export class Dialog {
  constructor(private root: Node) {
    this.history.push({
      message: this.root.dialog,
      sender: 'npc',
      isVisible: true
    });
  }
  private accumulatedWeights: number[] = [];
  private history: HistoryItem[] = [];
  public evaluate(message: MessageBroadcast<string>) {
    if (message.messageType === 'Media Session' && message.payload) {
      this.root.tick(message);
      this.root.edges
        .filter((branch) => branch.eval())
        .forEach((branch) => {
          this.accumulatedWeights.push(branch.correctness);
          this.root = branch.node;
          this.pushToHistory(branch);
        });
    }
  }
  private pushToHistory(branch: Branch) {
    if (branch.correctness >= 0 && branch.dialog) {
      this.history.push({
        message: branch.dialog,
        sender: 'player',
        isVisible: branch.isVisible
      });
    }
    if (this.root.dialog) {
      this.history.push({
        message: this.root.dialog,
        sender: 'npc',
        isVisible: true
      });
    }
  }
  public getRoot() {
    return this.root;
  }
  public getHistory() {
    return this.history;
  }
  public getAccumulatedWeightAverage() {
    const filteredWeights = this.accumulatedWeights.filter(
      (value) => value >= 0
    );
    return (
      filteredWeights.reduce((acc, curr) => curr + acc, 0) /
      filteredWeights.length
    );
  }
}

export enum ConversationType {
  NONE = 'none',
  WG_CC = 'WG/CC',
  MOC = 'MOC',
  TA = 'TA',
  CE = 'CE',
  OG_CC = 'OG/CC',
  MSG_CC = 'MSG/CC',
  PRIMARY_CRASH_NET = 'Primary Crash Net',
  BDOC = 'BDOC',
  TOWER_HOTLINE = 'Tower Hotline',
  UHF_ACTIVE_SHOOTER = 'UHF-Active-Shooter',
  UHF_SIGNIFICANT_WEATHER = 'UHF-Significant-Weather',
  NOISE_COMPLAINT = 'Noise Complaint',
  RADIO_CHECK = 'Radio Check',
  WG_CC_ACTIVE_SHOOTER = 'WG/CC-ActiveShooter',
  MSG_CC_ACTIVE_SHOOTER = 'MSG/CC-Active-Shooter',
  MGD_CC = 'MGD/CC',
  ALERT_FACILITY = 'Alert Facility',
  GV_ACTIVE_SHOOTER = 'Giant-Voice-Active-Shooter',
  GV_SIGNIFICANT_WEATHER = 'Giant-Voice-Significant-Weather'
}

export interface ConversationTuple {
  sender: Subject<MessageBroadcast<string>>;
  target: Subject<MessageBroadcast<string>>;
}

export interface EstablishedConversation {
  targetId: string;
  conversationId: ConversationId;
}

export const SubjectMapSystem = (conversations: ConversationSingleton) => {
  return createSystem({
    query: queryComponents({
      conversation: Write(Conversation),
      inbox: Write(Inbox),
      entity: ReadEntity()
    })
  })
    .withName('SubjectCreationAndConversationMapSystem')
    .withRunFunction(({ query }) => {
      for (const { conversation, inbox, entity } of query.iter()) {
        const targetSubject = conversations.targetIdSubjectMap.get(
          conversation.target
        );
        if (!targetSubject) {
          conversations.create(conversation);
        }
        switch (conversation.state) {
          case ConversationState.OPEN:
            conversations.subscribeToTargetSubject(conversation.target, inbox);
            conversation.state = ConversationState.ACTIVE;
            break;
          case ConversationState.ACTIVE:
            break;
          case ConversationState.WAITING:
            break;
          case ConversationState.CLOSED:
            break;
        }
        const senderSubjectExists = !!conversations.targetIdSubjectMap.get(
          entity.id
        );
        const existingConversation = conversations.entityConversationsMap
          .get(conversation.target)
          ?.find((entry) => entry.conversationId);
        conversations.entityTargetMap.set(entity.id, conversation.target);
        const senderIsAlsoATarget =
          conversations.entityTargetMap.get(conversation.target) === entity.id;
        const conversationMappingCriteriaMet =
          senderIsAlsoATarget && senderSubjectExists && !existingConversation;
        if (conversationMappingCriteriaMet) {
          const generatedConversationId =
            conversations.createConversationMapping(entity.id, conversation);
          conversation.conversationId = generatedConversationId;
        } else if (existingConversation && !conversation.conversationId) {
          conversation.conversationId = existingConversation.conversationId;
        }
      }
    })
    .build();
};

export const DialogMapSystem = (conversations: ConversationSingleton) => {
  return createSystem({
    query: queryComponents({
      conversation: Write(Conversation),
      brain: Read(Brain)
    })
  })
    .withName('DialogMapSystem')
    .withRunFunction(({ query }) => {
      for (const { conversation, brain } of query.iter()) {
        const conversationHasDialogTree =
          conversations.conversationIdDialogMap.has(
            conversation.conversationId
          );
        if (!conversationHasDialogTree && conversation.conversationId) {
          conversations.createDialogTreeMapping(
            conversation.conversationId,
            brain.dialogTreeReference
          );
        }
      }
    })
    .build();
};

export const ProcessOutboxSystem = (conversations: ConversationSingleton) => {
  return createSystem({
    query: queryComponents({
      conversation: Read(Conversation),
      outbox: Write(Outbox),
      entity: ReadEntity()
    })
  })
    .withName('ProcessOutboxSystem')
    .withRunFunction(({ query }) => {
      for (const { outbox, entity } of query.iter()) {
        const message = outbox.stream.pop();
        if (message) {
          conversations.update(entity.id, message);
          switch (message.messageType) {
            case 'BYE':
              entity.removeComponent(Conversation);
              break;
            default:
              break;
          }
        }
      }
    })
    .build();
};

export const ConversationViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      conversation: Read(Conversation),
      viewModel: Write(ConversationSynchronizationComponent)
    })
  })
    .withName('ConversationViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { conversation, viewModel } of query.iter()) {
        viewModel.shallowReference.value = {
          conversationId: conversation.conversationId,
          target: conversation.target,
          transport: conversation.transport,
          state: conversation.state
        };
      }
    })
    .build();
};
