import firebase from 'firebase';
import FirebaseAuthClient from './firebaseAuthClient';

interface IAuthClient {
  signIn(email?: string, pass?: string): Promise<void>;
  signOut(): Promise<void>;
  isEmailVerified(): Promise<boolean>;
  sendPasswordResetEmail(email?: string): Promise<void>;
  sendEmailVerification(): Promise<void | Error>;
  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.User | null>;
}

class AuthClient {
  private static _instance: IAuthClient;

  public static initialize(): void {
    AuthClient._instance = new FirebaseAuthClient();
  }

  public static getInstance(): IAuthClient {
    if (!AuthClient._instance) {
      throw new Error('AuthClient not initialized');
    }
    return AuthClient._instance;
  }
}

export { AuthClient, IAuthClient };
