import { Reference } from '@IntrinsicSoftware/sim-ecs';
import { serializeObjectReplacer } from '../util/util';
import { Completable } from './Completable';

export const CompletableSerde = {
  serializer: (component: unknown) => {
    return JSON.stringify(component, serializeObjectReplacer);
  },
  deserializer: (data: unknown) => {
    const dataObject = typeof data === 'string' ? JSON.parse(data) : data;
    const children = dataObject.children
      .map((child: unknown) => {
        if (typeof child === 'string') {
          // Allows for defining Reference with "*****|ENTITY|1"
          return Reference.fromString(child);
        } else if (child && typeof child === 'object') {
          // Allows for defining Reference with { type: EReferenceType.Entity, id: '2' }
          const childReference = child as Reference;
          return new Reference(childReference.type, childReference.id);
        } else {
          return;
        }
      })
      .filter((child: unknown) => child !== undefined);
    return {
      containsRefs: true,
      data: new Completable(
        children,
        dataObject.completeConstraints,
        dataObject.activeConstraints,
        dataObject.root,
        dataObject.completeEvaluationConstraints,
        dataObject.pointConstraint
      )
    };
  }
};
