import { Outbox } from '../outbox/Outbox';
import { Inbox } from '../inbox/component';
import { Contact } from '../contact/Contact';
import { ConversationType } from '../conversation/Conversation';
import { Brain, BrainDelegateType } from '../brains/Brain';
import { CTagMarker } from '@IntrinsicSoftware/sim-ecs';

export const AUTO_HANGUP_ID = '#AutoHangup';
export const WX_SPECIAL_ID = '1041';
export const CRASH_NET_PHONE_ID = '1037';
export const BDOC_PHONE_LINE_ID = '#Bdoc';
export const BDOC_TARGET_ID = '#BdocTarget';
export const TOWER_HOTLINE = '#TowerHotline';
export const TOWER_HOTLINE_TARGET = '#TowerHotlineTarget';
export const WGCC_TARGET = '#WG/CCTarget';
export const WGCC = '#WG/CC';
export const MSGCC_TARGET = '#MSG/CCTarget';
export const MSGCC = '#MSG/CC';
export const MDG_CC = '#MdgCc';
export const MDG_CC_TARGET = '#MdgCcTarget';
export const ALERT_FACILITY = '#AlertFacility';
export const ALERT_FACILITY_TARGET = '#AlertFacilityTarget';
export const COMMERCIAL = '#Commercial';
export const COMMERCIAL_TARGET = '#CommercialTarget';
export function autoHangupLine() {
  return {
    '#ID': AUTO_HANGUP_ID,
    [CTagMarker]: [AUTO_HANGUP_ID],
    Category: {
      column: '',
      title: ''
    },
    Description: {
      title: 'Auto Hangup Target',
      description: 'This entity is for auto hanging up'
    },
    Contact: <Contact>{
      targetEntityId: ''
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE
    }
  };
}

export const commandPhoneLinesPrefab = () => {
  return [
    {
      //WG/CC Phone Line
      '#ID': WGCC,
      [CTagMarker]: [WGCC],
      Description: {
        title: 'WG/CC',
        description: 'WG/CC'
      },
      Category: {
        column: '1',
        title: 'Command'
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: WGCC_TARGET
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.WG_CC,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      '#ID': WGCC_TARGET,
      [CTagMarker]: [WGCC_TARGET],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'WG/CC Target',
        description: 'WG/CC'
      },
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Brain: <Brain>{
        dialogTreeReference: ConversationType.WG_CC,
        delegate: BrainDelegateType.LINEAR
      }
    },
    {
      '#ID': '#PrimaryCrashNetTarget',
      [CTagMarker]: ['#PrimaryCrashNetTarget'],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'Primary Crash Net Target',
        description: 'Primary Crash Net'
      },
      Contact: <Contact>{
        targetEntityId: CRASH_NET_PHONE_ID
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Brain: <Brain>{
        dialogTreeReference: ConversationType.PRIMARY_CRASH_NET,
        delegate: BrainDelegateType.LINEAR
      }
    },
    {
      //WG/CV Phone Line
      '#ID': '1013',
      [CTagMarker]: ['1013'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'WG/CV',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Command'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //OG/CC Phone Line
      '#ID': '1014',
      [CTagMarker]: ['1014'],
      Description: {
        title: 'OG/CC',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Command'
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: '#OGCCTarget'
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.OG_CC,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      '#ID': '#OGCCTarget',
      [CTagMarker]: ['#OGCCTarget'],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'OG/CC Target',
        description: "This entity is the contact's target entity"
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.OG_CC,
        delegate: BrainDelegateType.LINEAR
      }
    },
    {
      //MSG/CC Phone Line
      '#ID': MSGCC,
      [CTagMarker]: [MSGCC],
      Description: {
        title: 'MSG/CC',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Command'
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: MSGCC_TARGET
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.MSG_CC,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      '#ID': MSGCC_TARGET,
      [CTagMarker]: [MSGCC_TARGET],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'MSG/CC Target',
        description: "This entity is the contact's target entity"
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.MSG_CC,
        delegate: BrainDelegateType.LINEAR
      }
    },
    {
      //MXG/CC Phone Line
      '#ID': '1016',
      [CTagMarker]: ['1016'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'MXG/CC',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Command'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //TOWER Phone Line
      '#ID': MDG_CC,
      [CTagMarker]: [MDG_CC],
      Description: {
        title: 'MDG/CC',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Command'
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: MDG_CC_TARGET
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.MGD_CC,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      '#ID': MDG_CC_TARGET,
      [CTagMarker]: [MDG_CC_TARGET],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'MGD/CC Target',
        description: 'MGD/CC Target'
      },
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Brain: <Brain>{
        dialogTreeReference: ConversationType.MGD_CC,
        delegate: BrainDelegateType.LINEAR
      }
    }
  ];
};

export const dsnPhoneLinesPrefab = () => {
  return [
    {
      //CE Phone Line
      '#ID': '1018',
      [CTagMarker]: ['1018'],
      Description: {
        title: 'CE',
        description: ''
      },
      Category: {
        column: '1',
        title: 'DSN'
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: '#CETarget'
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.CE,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      '#ID': '#CETarget',
      [CTagMarker]: ['#CETarget'],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'CE Target',
        description: "This entity is the contact's target entity"
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.CE,
        delegate: BrainDelegateType.LINEAR
      }
    },
    {
      //DSN #2 Phone Line
      '#ID': '1019',
      [CTagMarker]: ['1019'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'DSN #2',
        description: ''
      },
      Category: {
        column: '1',
        title: 'DSN'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //DSN #3 Phone Line
      '#ID': '1020',
      [CTagMarker]: ['1020'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'DSN #3',
        description: ''
      },
      Category: {
        column: '1',
        title: 'DSN'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //DSN #4 Phone Line
      '#ID': '1021',
      [CTagMarker]: ['1021'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'DSN #4',
        description: ''
      },
      Category: {
        column: '1',
        title: 'DSN'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //Commercial Phone Line
      '#ID': COMMERCIAL,
      [CTagMarker]: [COMMERCIAL],
      Description: {
        title: 'Commercial',
        description: 'Incoming phone call'
      },
      Category: {
        column: '2',
        title: 'DSN'
      },
      Disable: {},
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.NOISE_COMPLAINT,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      // This entity is the contacts target entity
      '#ID': COMMERCIAL_TARGET,
      [CTagMarker]: [COMMERCIAL_TARGET],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'Commercial Target',
        description: "This entity is the contact's target entity"
      },
      Contact: <Contact>{
        targetEntityId: COMMERCIAL
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Brain: <Brain>{
        dialogTreeReference: ConversationType.NOISE_COMPLAINT,
        delegate: BrainDelegateType.LINEAR
      }
    }
  ];
};

export const flyingOpsPhoneLinesPrefab = () => {
  return [
    {
      //MOC Phone Line
      '#ID': '1023',
      [CTagMarker]: ['1023'],
      Description: {
        title: 'MOC',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Flying Ops'
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: '#MOCTarget'
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.MOC,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      '#ID': '#MOCTarget',
      [CTagMarker]: ['#MOCTarget'],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'MOC Target',
        description: "This entity is the contact's target entity"
      },
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Brain: <Brain>{
        dialogTreeReference: ConversationType.MOC,
        delegate: BrainDelegateType.LINEAR
      }
    },
    {
      //ATOC Phone Line
      '#ID': '1024',
      [CTagMarker]: ['1024'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'ATOC',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Flying Ops'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //AM OPS Phone Line
      '#ID': '1025',
      [CTagMarker]: ['1025'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'AM OPS',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Flying Ops'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //SOF Phone Line
      '#ID': '1026',
      [CTagMarker]: ['1026'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'SOF',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Flying Ops'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //TA Phone Line
      '#ID': '1027',
      [CTagMarker]: ['1027'],
      Description: {
        title: 'TA',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Flying Ops'
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: '#TATarget'
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.TA,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      // This entity is the contacts target entity
      '#ID': '#TATarget',
      [CTagMarker]: ['#TATarget'],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'TA Target',
        description: "This entity is the contact's target entity"
      },
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Brain: <Brain>{
        dialogTreeReference: ConversationType.TA,
        delegate: BrainDelegateType.LINEAR
      }
    },
    {
      //51st SQ Phone Line
      '#ID': '1028',
      [CTagMarker]: ['1028'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: '51st SQ',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Flying Ops'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //52nd SQ Phone Line
      '#ID': '1029',
      [CTagMarker]: ['1029'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: '52nd SQ',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Flying Ops'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //TOWER Phone Line
      '#ID': TOWER_HOTLINE,
      [CTagMarker]: [TOWER_HOTLINE],
      Description: {
        title: 'TOWER',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Flying Ops'
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: TOWER_HOTLINE_TARGET
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.TOWER_HOTLINE,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      '#ID': TOWER_HOTLINE_TARGET,
      [CTagMarker]: [TOWER_HOTLINE_TARGET],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'Tower Target',
        description: 'Tower Hotline Target'
      },
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Brain: <Brain>{
        dialogTreeReference: ConversationType.TOWER_HOTLINE,
        delegate: BrainDelegateType.LINEAR
      }
    },
    {
      //RAPCON Phone Line
      '#ID': '1031',
      [CTagMarker]: ['1031'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'RAPCON',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Flying Ops'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    }
  ];
};

export const hqPhoneLinesPrefab = () => {
  return [
    {
      //ACC Phone Line
      '#ID': '1032',
      [CTagMarker]: ['1032'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'ACC',
        description: ''
      },
      Category: {
        column: '1',
        title: 'HQ'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //618th AOC Phone Line
      '#ID': '1033',
      [CTagMarker]: ['1033'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: '618th AOC',
        description: ''
      },
      Category: {
        column: '2',
        title: 'HQ'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    }
  ];
};

export const emergencyPhoneLinesPrefab = () => {
  return [
    {
      //BDOC Phone Line
      '#ID': BDOC_PHONE_LINE_ID,
      [CTagMarker]: [BDOC_PHONE_LINE_ID],
      Description: {
        title: 'BDOC',
        description: 'BDOC'
      },
      Category: {
        column: '1',
        title: 'Emergency'
      },
      Disable: {},
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: BDOC_TARGET_ID
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.BDOC,
        delegate: BrainDelegateType.PLAYER,
        history: [
          {
            isVisible: false,
            message: 'initial message to hide thinking',
            sender: 'npc'
          }
        ]
      }
    },
    {
      '#ID': BDOC_TARGET_ID,
      [CTagMarker]: [BDOC_TARGET_ID],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'BDOC',
        description: "This entity is the contact's target entity"
      },
      Contact: <Contact>{
        targetEntityId: BDOC_PHONE_LINE_ID
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Brain: <Brain>{
        dialogTreeReference: ConversationType.BDOC,
        delegate: BrainDelegateType.LINEAR
      }
    },
    {
      //FD Phone Line
      '#ID': '1035',
      [CTagMarker]: ['1035'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'FD',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Emergency'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //ER Phone Line
      '#ID': '1036',
      [CTagMarker]: ['1036'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'ER',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Emergency'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //CRASH NET Phone Line
      '#ID': CRASH_NET_PHONE_ID,
      [CTagMarker]: [CRASH_NET_PHONE_ID],
      Description: {
        title: 'CRASH NET',
        description: 'Incoming phone call'
      },
      Category: {
        column: '2',
        title: 'Emergency'
      },
      Disable: {},
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.PRIMARY_CRASH_NET,
        delegate: BrainDelegateType.PLAYER
      }
    }
  ];
};

export const alertPhoneLinesPrefab = () => {
  return [
    {
      //EADS Phone Line
      '#ID': '1038',
      [CTagMarker]: ['1038'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'EADS',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Alert'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //SCRAMBLE Phone Line
      '#ID': '1039',
      [CTagMarker]: ['1039'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'SCRAMBLE',
        description: ''
      },
      Category: {
        column: '1',
        title: 'Alert'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //ALERT Phone Line
      '#ID': ALERT_FACILITY,
      [CTagMarker]: [ALERT_FACILITY],
      Description: {
        title: 'ALERT',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Alert'
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Contact: <Contact>{
        targetEntityId: ALERT_FACILITY_TARGET
      },
      Brain: <Brain>{
        dialogTreeReference: ConversationType.ALERT_FACILITY,
        delegate: BrainDelegateType.PLAYER
      }
    },
    {
      '#ID': ALERT_FACILITY_TARGET,
      [CTagMarker]: [ALERT_FACILITY_TARGET],
      Category: {
        column: '',
        title: ''
      },
      Description: {
        title: 'Alert Facility Target',
        description: 'Alert Facility Target'
      },
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Inbox: <Inbox>{},
      Outbox: <Outbox>{},
      Brain: <Brain>{
        dialogTreeReference: ConversationType.ALERT_FACILITY,
        delegate: BrainDelegateType.LINEAR
      }
    }
  ];
};

export const specialPhoneLinesPrefab = () => {
  return [
    {
      //WX Special
      '#ID': WX_SPECIAL_ID,
      [CTagMarker]: [WX_SPECIAL_ID],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'WX',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Special'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //DOOR 1 Phone Line
      '#ID': '1042',
      [CTagMarker]: ['1042'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'DOOR 1',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Special'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    },
    {
      //DOOR 2 Phone Line
      '#ID': '1043',
      [CTagMarker]: ['1043'],
      DialogFlow: {
        url: 'https://dialogflow.com',
        context: {},
        dialogTree: {}
      },
      ActivePhoneCall: {
        sent: {},
        received: {},
        history: []
      },
      Description: {
        title: 'DOOR 2',
        description: ''
      },
      Category: {
        column: '2',
        title: 'Special'
      },
      Inbox: <Inbox>{},
      Disable: {},
      Contact: <Contact>{
        targetEntityId: AUTO_HANGUP_ID
      },
      Brain: <Brain>{}
    }
  ];
};
