import { CTagMarker } from '@IntrinsicSoftware/sim-ecs';
import { EReferenceType } from '@IntrinsicSoftware/sim-ecs/dist/serde/referencing.spec';
import { correctRadioBroadcast } from '../../../models/RadioDialogItem';
import { Active, ToggleCommand } from '../../active/main';
import { Brain, BrainDelegateType } from '../../brains/Brain';
import { CompleteCommand } from '../../completable/Completable';
import { Contact } from '../../contact/Contact';
import {
  Conversation,
  ConversationType
} from '../../conversation/Conversation';
import { Disable } from '../../disable/component';
import { Inbox } from '../../inbox/component';
import { Outbox } from '../../outbox/Outbox';
import { AUTO_HANGUP_ID, COMMERCIAL, WGCC } from '../../phone/fogPrefab';
import { Worth } from '../../point/main';
import { Series } from '../../series/main';
import Timer, { DATE_MAX } from '../../timer/main';

const GIANT_VOICE_TARGET = '#GiantVoiceTarget';
const GIANT_VOICE_TAPPER = '#GiantVoiceTapper';
const COMMERCIAL_TAPPER = '#CommercialTapper';
export const GIANT_VOICE = '#GiantVoice';

export const definition = [
  {
    '#RES': 1
  },
  //Group Node
  {
    '#ID': '#GroupNode',
    [CTagMarker]: ['#GroupNode'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: '1011' },
        { type: EReferenceType.Entity, id: '1001' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      root: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Module
    '#ID': '#NoiseComplaint',
    [CTagMarker]: ['#NoiseComplaint'],
    Description: {
      title: 'Noise Complaint',
      description: 'Answer the incoming phone call.'
    },
    Completable: {
      children: [{ type: EReferenceType.Entity, id: '1011' }],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'parallel children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active'],
      root: true
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Getting Started Checklist
    '#ID': '1011',
    [CTagMarker]: ['1011'],
    Completable: {
      children: [{ type: EReferenceType.Entity, id: COMMERCIAL_TAPPER }],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Answer incoming call',
      description: 'Answer the incoming phone call and follow instructions'
    },
    CompleteCommand: <CompleteCommand>{},
    Series: <Series>{ id: Series.UNIDENTIFIED }
  },
  {
    // WX Tapper
    '#ID': COMMERCIAL_TAPPER,
    [CTagMarker]: [COMMERCIAL_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'WX',
      description: 'Incoming phone call'
    },
    Worth: <Worth>{
      maximum: 100,
      weight: 1
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: COMMERCIAL
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: COMMERCIAL
    },
    Brain: <Brain>{
      delegate: BrainDelegateType.TAPPER
    }
  },
  {
    // Base Siren
    '#ID': '1009',
    [CTagMarker]: ['1009'],
    Active: <Active>{
      active: false
    },
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Activate Base Siren',
      description:
        '4a. Activate Base Siren (Steady Tone). For Tornado Warning ONLY.'
    },
    Timer: <Timer>{
      limit: 20000,
      expired: false,
      startTime: DATE_MAX
    },
    ToggleCommand: <ToggleCommand>{},
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // GiantVoice
    '#ID': GIANT_VOICE,
    [CTagMarker]: [GIANT_VOICE],
    Description: {
      title: 'Giant Voice',
      description: ''
    },
    Category: {
      column: -1,
      title: ''
    },
    Contact: <Contact>{
      targetEntityId: GIANT_VOICE_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    }
  },
  {
    '#ID': GIANT_VOICE_TARGET,
    [CTagMarker]: [GIANT_VOICE_TARGET],
    Category: {
      column: '',
      title: ''
    },
    Description: {
      title: 'Giant Voice Target',
      description: 'Giant Voice Target'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE,
      delegate: BrainDelegateType.LINEAR,
      controlType: 'Radio'
    }
  },
  {
    '#ID': GIANT_VOICE_TAPPER,
    [CTagMarker]: [GIANT_VOICE_TAPPER],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Initiate broadcast over the Giant Voice',
      description: '4b. Announce the following statement over the Giant Voice',
      subtitle:
        '"LOCKDOWN. LOCKDOWN. LOCKDOWN. Base is in LOCKDOWN. Please remain in LOCKDOWN MODE until given further guidance. Command post out.” (Repeat 3x) Repeat over giant voice every five minutes.'
    },
    Timer: <Timer>{
      limit: 60000,
      expired: false,
      startTime: DATE_MAX
    },
    CompleteCommand: <CompleteCommand>{},
    Conversation: <Conversation>{
      transport: { type: 'entity' },
      target: GIANT_VOICE_TARGET
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Contact: <Contact>{
      targetEntityId: GIANT_VOICE_TARGET
    },
    Brain: <Brain>{ delegate: BrainDelegateType.TAPPER }
  },
  {
    // Mock Radio
    '#ID': '1045',
    [CTagMarker]: ['1045'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Radio',
      description: 'mock radio'
    },
    Outbox: <Outbox>{},
    Inbox: <Inbox>{},
    Category: {
      column: '1',
      title: 'FM'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    },
    Disable: <Disable>{}
  },
  {
    // Radio
    '#ID': '1046',
    [CTagMarker]: ['1046'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user', 'system'],
      pointConstraint: ['system-active', 'user-active']
    },
    Description: {
      title: 'Radio',
      description: '5b. Notify Aircraft over UHF stating:',
      subtitle:
        'ATTENTION A TORNADO HAS BEEN SPOTTED 3NM NORTH OF KEESLER AFB FLIGHTLINE OPERATIONS SHOULD CEASE TAKE SHELTER IMMEDIATELY'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{ expectedMessage: correctRadioBroadcast.payload },
    Category: {
      column: '2',
      title: 'UHF'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    },
    CompleteCommand: <CompleteCommand>{}
  },

  {
    // Mock Radio
    '#ID': '1047',
    [CTagMarker]: ['1047'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Radio',
      description: 'mock radio'
    },
    Inbox: <Inbox>{},
    Outbox: <Outbox>{},
    Category: {
      column: '3',
      title: 'VHF'
    },
    Contact: <Contact>{
      targetEntityId: AUTO_HANGUP_ID
    },
    Brain: <Brain>{
      dialogTreeReference: ConversationType.NONE,
      delegate: BrainDelegateType.PLAYER,
      controlType: 'Radio'
    },
    Disable: <Disable>{}
  },
  {
    '#ID': '#Notify',
    [CTagMarker]: ['#Notify'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: WGCC },
        { type: EReferenceType.Entity, id: '1046' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Notify',
      description: '5. Notify:'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Confirm Checklist
    '#ID': '#ConfirmChecklist',
    [CTagMarker]: ['#ConfirmChecklist'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Confirm correct checklist',
      description: '1. Confirm correct checklist'
    },
    Timer: <Timer>{
      limit: 10000,
      expired: false,
      startTime: DATE_MAX
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Confirm Checklist
    '#ID': '#ConfirmChecklistComplete',
    [CTagMarker]: ['#ConfirmChecklistComplete'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Confirm checklist complete',
      description: '6. Confirm checklist complete'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Note Time of Notification and Source
    '#ID': '#NoteTimeOfNotificationAndSource',
    [CTagMarker]: ['#NoteTimeOfNotificationAndSource'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Note the Time of Notification and Source',
      description: '2. Note the Time of Notification and Source'
    },
    Timer: <Timer>{
      limit: 10000,
      expired: false,
      startTime: DATE_MAX
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Note Significant Weather Information
    '#ID': '#NoteSignificantWeatherInformation',
    [CTagMarker]: ['#NoteSignificantWeatherInformation'],
    Completable: {
      children: [],
      complete: false,
      completeConstraints: 'on self',
      completeEvaluationConstraints: ['user'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Note Significant Weather Information',
      description: '3. Note Significant Weather Information'
    },
    Timer: <Timer>{
      limit: 10000,
      expired: false,
      startTime: DATE_MAX
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Significant WX QRC Checklist
    '#ID': '1001',
    [CTagMarker]: ['1001'],
    Completable: {
      children: [
        { type: EReferenceType.Entity, id: '#ConfirmChecklist' },
        { type: EReferenceType.Entity, id: '#NoteTimeOfNotificationAndSource' },
        {
          type: EReferenceType.Entity,
          id: '#NoteSignificantWeatherInformation'
        },
        { type: EReferenceType.Entity, id: '#InitiateTornadoWarningChecklist' },
        { type: EReferenceType.Entity, id: '#Notify' },
        { type: EReferenceType.Entity, id: '#ConfirmChecklistComplete' }
      ],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Significant WX QRC',
      description: 'Significant WX QRC'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Secondary Objectives Checklist
    '#ID': '#SecondaryObjectives',
    [CTagMarker]: ['#SecondaryObjectives'],
    Completable: {
      children: [{ type: EReferenceType.Entity, id: '#EnjoyTheSun' }],
      complete: false,
      completeConstraints: 'on children',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Secondary Objectives',
      description: 'Ad hoc tasks'
    },
    Series: {
      id: '-1',
      title: 'Secondary Objectives'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // #EnjoyTheSun
    '#ID': '#EnjoyTheSun',
    [CTagMarker]: ['#EnjoyTheSun'],
    Completable: {
      children: [],
      complete: true,
      completeConstraints: 'on self',
      activeConstraints: 'order children',
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Do Enjoy The Sun',
      description: 'Enjoy the Sun 🌞🌞🌞🌞🌞'
    },
    Series: {
      id: '-1',
      title: 'None'
    },
    CompleteCommand: <CompleteCommand>{}
  },
  {
    // Mock Checklist 1
    '#ID': '1004',
    [CTagMarker]: ['1004'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Active Air Scramble',
      description: 'Mock Checklist 1 Description'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    Disable: {}
  },
  {
    // Mock Checklist 2
    '#ID': '1005',
    [CTagMarker]: ['1005'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Runway Alert',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '100',
      title: 'Emergency Mgmt'
    },
    Disable: {}
  },
  {
    // Mock Checklist 3
    '#ID': '1006',
    [CTagMarker]: ['1006'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'FPCON Declaration',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '200',
      title: 'Aircraft Missiles Satellites '
    },
    Disable: {}
  },
  {
    // Mock Checklist 4
    '#ID': '1007',
    [CTagMarker]: ['1007'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'Active Shooter/Lockdown',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '200',
      title: 'Aircraft Missiles Satellites '
    },
    Disable: {}
  },
  {
    // Mock Checklist 5
    '#ID': '1008',
    [CTagMarker]: ['1008'],
    Completable: {
      children: [],
      complete: false,
      completeEvaluationConstraints: ['system'],
      pointConstraint: ['system-active']
    },
    Description: {
      title: 'OPREP-3 Transverse',
      description: 'Mock Checklist 2 Description'
    },
    Series: {
      id: '300',
      title: 'Recalls'
    },
    Disable: {}
  }
];
