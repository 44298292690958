import {
  createSystem,
  IEntity,
  queryComponents,
  Write
} from '@IntrinsicSoftware/sim-ecs';
import { ShallowRef, shallowRef } from 'vue';
import { ComponentException } from '../types';

export class Series {
  constructor(public id = Series.UNIDENTIFIED, public title = '') {}
  static UNIDENTIFIED = '-1';
}

export interface SeriesViewModel {
  id: string;
  title: string;
}

export class SeriesSynchronizationComponent {
  constructor(
    public shallowReference: ShallowRef<Series> = shallowRef<Series>({
      id: '',
      title: ''
    })
  ) {}
}

export const serializeSeries = (series: Series): SeriesViewModel => {
  return { id: series.id, title: series.title };
};

export const useSeries = (entity: IEntity) => {
  const series = entity.getComponent(Series);
  if (!series) {
    throw new ComponentException(entity.id, 'Series component not found');
  }
  const ref = shallowRef<SeriesViewModel>(serializeSeries(series));
  return ref;
};

export const SeriesViewModelSystem = () => {
  return createSystem({
    query: queryComponents({
      series: Write(Series),
      viewModel: Write(SeriesSynchronizationComponent)
    })
  })
    .withName('SeriesViewModelSystem')
    .withRunFunction(({ query }) => {
      for (const { series, viewModel } of query.iter()) {
        viewModel.shallowReference.value = {
          id: series.id,
          title: series.title
        };
      }
    })
    .build();
};
