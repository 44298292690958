import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw
} from 'vue-router';
import Routes from './Routes';
import { RouteNames } from './Routes';
import Home from '../views/HomePage.vue';
import SignUp from '../views/SignUpPage.vue';
import Verification from '../views/VerificationPage.vue';
import PasswordReset from '../views/PasswordResetPage.vue';
import Authorization from '../views/AuthorizationPage.vue';
import Admin from '../views/AdminPage.vue';
import Login from '../views/LoginPage.vue';
import ProfileCreation from '../views/ProfileCreationPage.vue';
import Student from '../views/StudentPage.vue';
import Instructor from '../views/InstructorPage.vue';
import DebugPage from '../views/DebugPage.vue';
import Scenario from '../views/ScenarioPage.vue';
import EcsPage from '../views/EcsPage.vue';
import TrainingHistory from '../views/TrainingHistoryPage.vue';
import AvailableChecklistBodyWidget from '../components/system/components/checklist/AvailableChecklistBodyWidget.vue';
import PhoneBodyWidget from '../components/system/components/phone/PhoneLineBodyWidget.vue';
import HushLineBodyWidget from '../components/system/components/phone/HushLineBodyWidget.vue';
import PhoneLineSelectionBodyWidget from '../components/system/components/phone/PhoneLineSelectionBodyWidget.vue';
import BaseSirenBodyWidget from '../components/system/components/BaseSirenBodyWidget.vue';
import RadioLineSelectionBodyWidget from '../components/system/components/radio/RadioLineSelectionBodyWidget.vue';
import RadioBodyWidget from '../components/system/components/radio/RadioBodyWidget.vue';
import InventoryItemZeroComponent from '../components/system/components/inventory/InventoryItemZeroComponent.vue';
import { useScenarioStore } from '../store/scenarioStore';
import GiantVoiceBodyWidgetVue from '../components/system/components/phone/GiantVoiceBodyWidget.vue';

export function createSkylineRouter(): Router {
  const routes: Array<RouteRecordRaw> = [
    {
      path: Routes.HOME,
      name: RouteNames.HOME,
      component: Home
    },
    {
      path: Routes.LOGIN,
      name: RouteNames.LOGIN,
      component: Login
    },
    {
      path: Routes.SIGNUP,
      name: RouteNames.SIGNUP,
      component: SignUp
    },
    {
      path: Routes.PASSWORD_RESET,
      name: RouteNames.PASSWORD_RESET,
      component: PasswordReset
    },
    {
      path: Routes.VERIFICATION,
      name: RouteNames.VERIFICATION,
      component: Verification
    },
    {
      path: Routes.ADMIN,
      name: RouteNames.ADMIN,
      component: Admin
    },
    {
      path: Routes.STUDENT,
      name: RouteNames.STUDENT,
      component: Student
    },
    {
      path: Routes.INSTRUCTOR,
      name: RouteNames.INSTRUCTOR,
      component: Instructor
    },
    {
      path: Routes.PROFILE_CREATION,
      name: RouteNames.PROFILE_CREATION,
      component: ProfileCreation
    },
    {
      path: Routes.DEBUG,
      name: RouteNames.DEBUG,
      component: DebugPage
    },
    {
      path: Routes.ECS,
      name: RouteNames.ECS,
      component: EcsPage
    },
    {
      path: Routes.AUTHORIZATION,
      name: RouteNames.AUTHORIZATION,
      component: Authorization
    },
    {
      path: Routes.TRAINING_HISTORY,
      name: RouteNames.TRAINING_HISTORY,
      component: TrainingHistory
    },
    {
      path: Routes.SCENARIO,
      name: RouteNames.SCENARIO,
      component: Scenario,
      props: () => {
        return {
          scenario: useScenarioStore().scenario
        };
      },
      children: [
        {
          path: 'inventory',
          name: RouteNames.INVENTORY_NONE,
          component: InventoryItemZeroComponent
        },
        {
          path: 'inventory/checklist',
          name: RouteNames.INVENTORY_CHECKLIST,
          component: AvailableChecklistBodyWidget,
          props: () => {
            return {
              checklists: useScenarioStore().getChecklists
            };
          }
        },
        {
          path: 'inventory/phone',
          name: RouteNames.INVENTORY_PHONE,
          component: PhoneLineSelectionBodyWidget,
          props: () => {
            return {
              phoneLines: useScenarioStore().getPhoneLines
            };
          }
        },
        {
          path: 'inventory/phone/active',
          name: RouteNames.INVENTORY_PHONE_ACTIVE,
          component: PhoneBodyWidget,
          props: () => {
            return {
              phoneLine:
                useScenarioStore().activePhoneLine ||
                useScenarioStore().activeRadioLine
            };
          }
        },
        {
          path: 'inventory/baseSiren',
          name: RouteNames.INVENTORY_BASE_SIREN,
          component: BaseSirenBodyWidget,
          props: () => {
            return {
              baseSiren: useScenarioStore().getBaseSiren
            };
          }
        },
        {
          path: 'inventory/giantVoice',
          name: RouteNames.INVENTORY_GIANT_VOICE,
          component: GiantVoiceBodyWidgetVue,
          props: () => {
            return {
              phoneLine: useScenarioStore().getGiantVoice
            };
          }
        },
        {
          path: 'inventory/radio',
          name: RouteNames.INVENTORY_RADIO,
          component: RadioLineSelectionBodyWidget,
          props: () => {
            const store = useScenarioStore();
            return {
              radioLines: store.getRadioLines
            };
          }
        },
        {
          path: 'inventory/radio/active',
          name: RouteNames.INVENTORY_RADIO_ACTIVE,
          component: RadioBodyWidget,
          props: () => {
            return {
              radio: useScenarioStore().activeRadioLine
            };
          }
        },
        {
          path: 'inventory/hushline',
          name: RouteNames.INVENTORY_HUSHLINE,
          component: HushLineBodyWidget,
          props: () => {
            return {
              phoneLine: useScenarioStore().activePhoneLine
            };
          }
        }
      ]
    }
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });

  return router;
}

// export default router;
