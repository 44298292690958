import { Transform } from 'class-transformer';
import firebase from 'firebase/app';

const timestampToDate = Transform(({ value }) => value.toDate(), {
  toClassOnly: true
});
const dateToTimestamp = Transform(
  ({ value }) => firebase.firestore.Timestamp.fromDate(value),
  {
    toPlainOnly: true
  }
);

export const TimestampTransform: PropertyDecorator = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  target: Object,
  propertyKey: string | symbol
) => {
  timestampToDate(target, propertyKey);
  dateToTimestamp(target, propertyKey);
};
