import firebase from 'firebase/app';

const generateRandomNumbers = (length: number): string => {
  const code = Array.from({ length }, () =>
    Math.floor(Math.random() * 10)
  ).join('');
  return code;
};

const generateRandomLetters = (length: number): string => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const code = Array.from(
    { length },
    () => letters[Math.floor(Math.random() * letters.length)]
  ).join('');
  return code;
};

export const generateCode = (): string => {
  const numbersCode = generateRandomNumbers(3);
  const lettersCode = generateRandomLetters(3);
  const code = `${lettersCode}-${numbersCode}`;
  return code;
};

export const dateFormatter = (date: string) => {
  const dateArray = date.split('-');
  return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
};

export const ordinalSuffixOf = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const formatDate_DD_MMM_YYYY = (date: Date) => {
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${day} ${monthNames[monthIndex]}. ${year}`;
};

export const formatDate_DD_MMM_YYYY_NO_DOT = (date: Date) => {
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${day} ${monthNames[monthIndex]} ${year}`;
};

export const formatDate_DD_MMM_YY_DASHES = (date: Date) => {
  // example output: 01-Jan-20
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${day}-${monthNames[monthIndex]}-${year.toString().slice(2)}`;
};

export const formatDate_DD_MM_YY_SLASHES = (date: Date) => {
  // example output: 01/04/23
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${day}/${month}/${year.toString().slice(2)}`;
};

export const formatTimestamp_DD_MM_YY_SLASHES = (
  timestamp: firebase.firestore.Timestamp
) => {
  return formatDate_DD_MMM_YYYY(timestamp.toDate());
};

export const formatTime = (date: Date, utc = true) => {
  if (utc) {
    return date.toISOString().slice(11, 19) + 'Z';
  } else {
    const timeString = date.toLocaleTimeString('en', {
      timeZoneName: 'short',
      hour12: false
    });
    return timeString.split(' ').join('');
  }
};

export const secondsToMinutes = (seconds: number) => {
  // output should be in the format of 00:00 or 10:04 or 01:04
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${Math.ceil(secondsLeft)
    .toString()
    .padStart(2, '0')}`;
};

export const secondsToMinutesObject = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = Math.ceil(seconds % 60);

  if (secondsLeft === 60) {
    return {
      minutes: (minutes + 1).toString().padStart(2, '0'),
      seconds: '00',
      secondsRaw: 0,
      minutesRaw: minutes + 1
    };
  }

  return {
    minutes: minutes.toString().padStart(2, '0'),
    seconds: secondsLeft.toString().padStart(2, '0'),
    minutesRaw: minutes,
    secondsRaw: secondsLeft
  };
};
