import {
  BuildableDialogItem,
  MessageBroadcast,
  RadioBroadcastPayload
} from './BuildableDialogItem';

export const correctRadioBroadcast: MessageBroadcast<RadioBroadcastPayload> = {
  payload: {
    message:
      'All aircraft in the local area this is Raymond 30 with a tornado warning contact tower or ATC for further instructions (REPEAT) ...Raymond 30 Out.',
    repeats: 2,
    frequency: 'Flightline'
  },
  messageType: 'Media Session'
};

export const radioAvailableDialog: BuildableDialogItem[] = [
  {
    textValue: 'for further instructions'
  },
  {
    textValue: 'contact tower or ATC'
  },
  {
    textValue: 'for more information'
  },
  {
    textValue: 'a tornado warning'
  },
  {
    textValue: 'All aircraft in the local area'
  },
  {
    textValue: '(REPEAT)'
  },
  {
    textValue: 'this is Raymond 30 with'
  },
  {
    textValue: '...Raymond 30 Out.'
  },
  {
    textValue: '...that is all.'
  }
];
