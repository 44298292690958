import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goalMessage = {
    dialog: 'Tower, Out.',
    edges: [],
    goalNode: true
  };

  const goalMessageNode = new Node(
    goalMessage.dialog,
    goalMessage.edges,
    goalMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch('Command Post, Out', 100, goalMessageNode, false),
    new Branch('Thx, Out', 75, goalMessageNode, false),
    new Branch('Good stuff, talk later', 50, goalMessageNode, false),
    new Branch(
      'Oh yes, right, Primary Crash Net, thx',
      0,
      goalMessageNode,
      false
    )
  ];

  const firstMessage = {
    dialog: 'Copy, Activating Primary Crash Net now.',
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch(
      'There is an active shooter situation at the base exchange. Activate the Primary Crash Net.',
      100,
      firstMessageNode,
      false
    ),
    new Branch(
      'Active shooter at the base exchange. Activate Crash Net.',
      75,
      firstMessageNode,
      false
    ),
    new Branch(
      'Activate the Crash Net. Gunman reported at the coffee shop',
      50,
      firstMessageNode,
      false
    ),
    new Branch(
      'There are armed gunmen holding hostages at the commisary. Activate the Secondary Crash Net.',
      0,
      firstMessageNode,
      false
    )
  ];

  const root = {
    dialog: 'Tower. Mr. Meyers, go ahead',
    edges: firstOptions,
    goalNode: false
  };
  return new Node(root.dialog, root.edges, root.goalNode);
}
