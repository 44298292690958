import { Node, Branch } from '@/ecs/dialog/component';

export function build() {
  const goal = {
    dialog: 'Thank you, out.',
    edges: [],
    goalNode: true
  };

  const goalNode = new Node(goal.dialog, goal.edges, goal.goalNode);

  const thirdOptions: Branch[] = [
    new Branch('Will do, sir.', 100, goalNode, false),
    new Branch('On it sir.', 100, goalNode, false),
    new Branch('Yes, I will figure out how to do that.', 75, goalNode, false),
    new Branch('Ummm, ok?', 50, goalNode, false)
  ];

  const secondMessage = {
    dialog:
      'Initiate weather diversion procedures and notify me when all aircraft are safely accounted for.',
    edges: thirdOptions,
    goalNode: false
  };

  const secondMessageNode = new Node(
    secondMessage.dialog,
    secondMessage.edges,
    secondMessage.goalNode
  );

  const secondOptions: Branch[] = [
    new Branch(
      'Sir, a Tornado was observed 3 NM North of Keesler AFB. Valid Time: Now - Until Further Notice.',
      100,
      secondMessageNode,
      false
    ),
    new Branch(
      'Command Post observed a tornado North of Keesler AFB. Valid Time: Now - Until Canceled.',
      75,
      secondMessageNode,
      false
    ),
    new Branch(
      'Sir, a Tornado watch is in effect for Keesler AFB. Valid Time: Now - Until Canceled.',
      50,
      secondMessageNode,
      false
    ),
    new Branch(
      'Tornado observed 3 NM South and bearing down on Keesler AFB. Valid Time: Now, Take cover!',
      0,
      secondMessageNode,
      false
    )
  ];

  const firstMessage = {
    dialog: 'Understood, proceed.',
    edges: secondOptions,
    goalNode: false
  };

  const firstMessageNode = new Node(
    firstMessage.dialog,
    firstMessage.edges,
    firstMessage.goalNode
  );

  const firstOptions: Branch[] = [
    new Branch(
      'Sir, this is Command Post with a Severe WX Warning.',
      100,
      firstMessageNode,
      false
    ),
    new Branch(
      'This is Command Post with a Tornado Warning.',
      75,
      firstMessageNode,
      false
    ),
    new Branch(
      'Sir, this is Command Post with a Severe WX Watch.',
      50,
      firstMessageNode,
      false
    ),
    new Branch(
      'There is a Tornado bearing down on the base, sir.',
      0,
      firstMessageNode,
      false
    )
  ];
  return new Node('This is Colonel Griffin.', firstOptions, false);
}
