import {
  createSystem,
  Write,
  queryComponents,
  WriteEvents,
  ReadEntity
} from '@IntrinsicSoftware/sim-ecs';
import {
  Completable,
  CompletionQualityMessages,
  CompletionQuality
} from '../completable/Completable';

export class CollectiblePoint {
  constructor(public worth: number) {}
}

export class OnCollectiblePointCreated {
  constructor(public payload: CollectiblePoint) {}
}

export class Worth {
  constructor(public maximum: number, public weight: number = 1) {}
}

const COMPLETABLE_ACTIVE_WEIGHT = 1;
const COMPLETABLE_INACTIVE_WEIGHT = 0.5;
const FULL_WEIGHT = 1;
const HALF_WEIGHT = 0.5;

export function pointConstraintEvaluation(completable: Completable) {
  return completable.pointConstraint
    .map(function isConstraintMet(constraint) {
      const systemActiveConstraintMet =
        completable.snapshot.previous.active || completable.aiActive;
      const userActiveConstraintMet = completable.userActive;

      switch (constraint) {
        case 'system-active':
          if (!systemActiveConstraintMet && completable.outputValue) {
            completable.completionQuality.state =
              CompletionQuality.IncorrectResponse;
          }
          return completable.snapshot.previous.active || completable.aiActive;
        case 'user-active':
          if (!userActiveConstraintMet && completable.outputValue) {
            completable.completionQuality.state =
              CompletionQuality.IncorrectResponse;
          }
          return completable.userActive;
      }
    })
    .every((constraintMet) => constraintMet);
}

export const CollectCompletablePointSystem = () => {
  return createSystem({
    myEvents: WriteEvents(OnCollectiblePointCreated),
    query: queryComponents({
      worth: Write(Worth),
      completable: Write(Completable),
      entity: ReadEntity()
    })
  })
    .withName('CollectCompletablePointSystem')
    .withRunFunction(({ myEvents, query }) => {
      const rules = [
        function active(_: Worth, completable: Completable) {
          const weightForState = pointConstraintEvaluation(completable)
            ? COMPLETABLE_ACTIVE_WEIGHT
            : COMPLETABLE_INACTIVE_WEIGHT;
          let reason = `Full points ${weightForState}: Completable was active`;
          if (weightForState < FULL_WEIGHT) {
            reason = `Completable was NOT active (${weightForState})`;
          }
          return { value: weightForState, reason };
        },
        function point(_: Worth, completable: Completable) {
          const weightForState =
            completable.completionQuality.state === CompletionQuality.Pristine
              ? COMPLETABLE_ACTIVE_WEIGHT
              : COMPLETABLE_INACTIVE_WEIGHT;
          return {
            value: weightForState,
            reason:
              CompletionQualityMessages.get(
                completable.completionQuality.state
              ) || 'Completable has been compromised'
          };
        }
      ];
      for (const { worth, completable, entity } of query.iter()) {
        const complete = completable.outputValue;
        if (complete) {
          const weight = rules.reduce(
            (accumulator, rule) => {
              const result = rule(worth, completable);
              const accumulatedWeight = accumulator.value * result.value;
              const clamp = Math.min(
                Math.max(accumulatedWeight, HALF_WEIGHT),
                FULL_WEIGHT
              );
              accumulator.value = clamp;
              accumulator.reasons.push(result.reason);
              return accumulator;
            },
            { value: FULL_WEIGHT, reasons: [''] }
          );
          const rewardablePoints = worth.maximum * weight.value;
          const point = new CollectiblePoint(rewardablePoints);
          myEvents.publish(new OnCollectiblePointCreated(point));
          entity.removeComponent(Worth);
        }
      }
    })
    .build();
};
